/**
 * Created by amine on 26/08/2016.
 */
(function () {
    const DEFAULT_OPTIONS = {
        "no_config_key": true,
        "minimal": false,
        "patient": false,
        "has_patient_menu": false,
        "has_physician_menu": false,
        "has_measurement_menu": false,
        "has_misc_menu": false,
        "auto_compile": false
    };

    const DEFAULT_KEY_HANDLERS = {'start': "%", 'end': "%"};

    module.exports = {
        DEFAULT_OPTIONS,
        DEFAULT_KEY_HANDLERS
    };
})();
