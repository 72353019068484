/**
 * Created by amine on 15/11/2021.
 */
(function () {

    'use strict';

    class TechnicalFileCtrl {
        constructor(technicalFileService, patientService, configService, $q, moment, system) {
            this.technicalFileService = technicalFileService;
            this.patientService = patientService;
            this.configService = configService;
            this.q = $q;
            this.moment = moment;

            this.promise = null;
            this.patient = null;
            this.model = {};
            this.fields = [];
            this.versions = [];
            this.currentVersion = -1;

            this.dateFormat = system.date_format.js;
        }

        static get $inject() {
            return ["technicalFileService", "patientService", "configService", "$q", "moment", "system"];
        }

        $onInit() {
            this.handleAutoSave = _.mnDelay(() => this._handleAutoSave(), 750);

            this.maxDate = this.maxDate || null;
            this.patientId = this.patientId || null;

            this.getData();
        }

        $onChanges(obj) {
            if (_.has(obj, 'maxDate.currentValue')) {
                this.maxDate = _.get(obj, 'maxDate.currentValue');
                this.getData();
            }
        }

        getCategoryQuery() {
            if (!this.patient || !this.model.sport) return {};

            return {
                gender: {
                    $in: [this.patient.gender, "UNDEFINED", null]
                },
                sport: {
                    $in: [this.model.sport.id, null]
                }
            }
        }

        getPatient() {
            if (this.patient) {
                return this.q.resolve(this.patient);
            } else {
                return this.patientService.getMinimalPatient(this.patientId);
            }
        }

        getData() {
            const promises = [
                this.getPatient(),
                this.technicalFileService.getFileHistory(this.patientId),
                this.technicalFileService.getActiveFile(this.patientId, this.maxDate),
                this.configService.getByHttp('technical_file_fields'),
            ];

            this.promise = this.q.all(promises)
                .then(data => {
                    this.patient = data[0]
                    this.versions = data[1];
                    this.model = data[2];
                    this.fields = data[3];
                    // console.log(this.fields)

                    if (_.isEmpty(this.model) && this.versions.length > 0) {
                        this.currentVersion = this.versions.length - 1;
                        this._choose();
                    } else {
                        this.currentVersion = _.findIndex(this.versions, _.pick(this.model, 'id'));
                    }
                });
        }

        _handleAutoSave() {
            if (this.currentVersion < 1 && this.model['created_at'] !== this.moment().format(this.dateFormat)) {
                this.model = _.chain(this.model)
                    .omit('id')
                    .assign({patient: {id: this.patientId}})
                    .value();
            }

            this.technicalFileService.handleFile(this.model)
                .then(data => {
                    const file = _.pick(data, ['id', 'created_at']);
                    this.model = _.assign(this.model, file);

                    this.versions = _.chain(this.versions)
                        .pushOrUpdate(file)
                        .orderBy(item => {
                            return this.moment(item.created_at, this.dateFormat).valueOf();
                        }, 'desc')
                        .value();

                    this.currentVersion = _.findIndex(this.versions, ['id', file.id]);
                });
        }

        _choose() {
            const version = _.get(this.versions, `[${this.currentVersion}].id`);

            this.promise = this.technicalFileService.getFile(version)
                .then(data => this.model = data);
        }

    }

    module.exports = {
        bindings: {
            patientId: "<patient",
            maxDate: "<?",
            ngDisabled: "<?",
            readonly: "<?"
        },
        template: require('stand-alone/technical-file/components/technical-file/technical-file.tpl.html'), // or template
        controllerAs: "vm",
        controller: TechnicalFileCtrl,
    };

})();
