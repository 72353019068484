(function () {
    "use strict";

    var dialog = {
        controller: MergeDocumentsDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/merge-documents-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: false,
        focusOnOpen:false
    };

    module.exports = dialog;

    MergeDocumentsDialogCtrl.$inject = ["$mdDialog", "system", "$translate"];

    function MergeDocumentsDialogCtrl($mdDialog, system, $translate) {
        let vm = this;
        vm.$onInit = init;
        vm.submit = submit;
        vm.cancel = cancel;

        var dateFormat = system['date_format'].js;

        function init() {
            vm.baseDocument=null;
            // vm.documents=
        }


        function submit() {
            $mdDialog.hide(vm.baseDocument);
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }


})();
