/**
 * Created by amine on 26/07/2017.
 */
(function () {
    'use strict';

    module.exports = {
        SportDataSetupCtrl: require('./controllers/sport-data-setup/SportDataSetupCtrl'),
        sportDataService: require('./services/sportDataService'),
        mnSportFile: require('./components/sport-file/sport-file'),
        mnSportFileDetails: require('./components/sport-file-details/sport-file-details')
    };
})();
