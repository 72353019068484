/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = MovementFormCtrl;

    MovementFormCtrl.$inject = ["patientService", "$transition$", "$scope", "stockService", "system", "$state", "$q", "configService"];

    function MovementFormCtrl(patientService, $transition$, $scope, stockService, system, $state, $q, configService) {
        var vm = this;
        var currentMovement = $transition$.params('to')["movementId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.submit = submit;
        vm.invalidateMovement = invalidateMovement;
        vm.validateMovement = validateMovement;
        vm.isAllValid = isAllValid;
        vm.removeErrorLines = removeErrorLines;
        vm.save = save;
        vm.contactCallback = contactCallback;


        function init() {
            vm.is_new = !_.isNil(currentMovement) ? false : true;
            $scope.$watch('vm.movement.is_valid', readOnlyMovement);
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.modalFilter = {is_deleted: {$ne: true}, is_valid: {$ne: false}};
            vm.modelName = 'StockMovement';
            var promises = [configService.get('store_config'), configService.get(["price_mode"], true)
            ];

            if (!_.isNil(currentMovement)) promises.push(stockService.getStockDocument(currentMovement, 'movement'));
            else vm.movement = {
                doc_date: moment().format(dateFormat),
                lines: []
            };

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                if (!_.isNull(currentMovement)) vm.movement = data[2];
                else {
                    vm.store_config = data[0];
                    vm.movement.type = vm.store_config['movement_default_type'];
                    vm.movement.price_mode = data[1];
                }

            }
        }

        function removePatient() {
            vm.movement.patient = null;
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.movement.patient = data;
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.movement = data;
            if (_.isNil(currentMovement)) $state.go('app.stock.movement-form', {movementId: data.id}, {location: 'replace'});
        }


        function invalidateMovement() {
            return stockService.invalidateMovement(_.get(vm.movement, 'id'), 'StockMovement', vm.movement.type);
        }

        function validateMovement(data) {
            return stockService.validateMovement(_.get(data, 'id'), 'StockMovement', vm.movement.type);
        }

        function readOnlyMovement() {
            vm.readOnly = (vm.movement && vm.movement.is_valid) ? vm.movement.is_valid : false;
        }

        function isAllValid() {
            return vm.movement ? stockService.isAllValid(vm.movement, vm.movement.type, vm.modelName) : true;
        }

        function removeErrorLines() {
            if (vm.movement.type == 'OUT') {
                _.remove(vm.movement.lines, function (line) {
                    return !line.is_comment && line.article['remaining_quantity'] <= 0;
                })
            }
        }

        function save() {
            return stockService.saveStockDocument(vm.movement, "movement", 'StockMovement');
        }

        function contactCallback(data) {
            vm.movement.related_doc = data;
        }
    }
})
();
