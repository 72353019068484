(function () {

    'use strict';

    const Draggable = require('draggable');
    const VIDEO_CALL_DIALOG = require('../dialogs/video-call/video-call.dialog');

    class VideoCallService {
        constructor($q, $mdPanel, $mdToast, configService, mnWebSocket, uploadService, authService, $translate, system) {
            this.q = $q;
            this.panel = $mdPanel;
            this.ws = mnWebSocket;
            this.translate = $translate;
            this.uploadService = uploadService;

            this.dateFormat = system['date_format'].js;

            this.panel.newPanelGroup('video-call-group', {
                maxOpen: 1
            });

            if (configService.hasVideoCall()) {
                this.ws.sub("video_call.VideoCallNotification.notify", data => {
                    if (_.get(authService.staff, 'id', -1) !== data.physician_id) return;

                    const toast = $mdToast.mnVideoCallToast()
                        .call(data)
                        .hideDelay(3000);

                    $mdToast.show(toast);
                });
            }
        }

        static get $inject() {
            return ["$q", "$mdPanel", "$mdToast", "configService", "mnWebSocket", "uploadService", "authService", "$translate", "system"];
        }

        openVideoCall(room, patient, folderId, ev) {
            this.panel.open(_.assign({}, VIDEO_CALL_DIALOG, {
                targetEvent: ev,
                locals: {room, patient, folderId},
                position: this.panelPosition(),
                // animation: this.panelAnimation(),
            }));
        }

        resendNotification(room) {
            return this.ws.call("video_call.VideoCallNotification.resend_notification", {room});
        }

        getDevices() {
            if (!("mediaDevices" in navigator) || !("RTCPeerConnection" in window)) Promise.resolve([]);
            return navigator.mediaDevices.enumerateDevices();
        }

        getStream(device) {
            return navigator.mediaDevices.getUserMedia({
                audio: {echoCancellation: true},
                video: {deviceId: {exact: device.deviceId}},
            });
        }

        // file related
        getCallFolder(folder, patient) {
            const deferred = this.q.defer();

            if (folder) this.uploadService.loadFolder(folder, {patient}).then(deferred.resolve, deferred.reject);
            else deferred.resolve(null);

            return deferred.promise;
        }

        createCallFolder(room, patient) {
            const data = {
                room,
                patient,
                folder_name: this.translate.instant('video_call_folder_name', {date: moment().format(this.dateFormat)})
            };
            return this.ws.call("video_call.VideoCallNotification.create_call_folder", data);
        }

        // panel related
        panelAnimation() {
            const animation = this.panel.newPanelAnimation();

            animation.duration(150);
            //animation.closeTo({bottom: 0, right: 0});
            //animation.openFrom({bottom: 0, right: 0});
            animation.withAnimation(this.panel.animation.SLIDE);

            return animation
        }

        panelPosition() {
            return this.panel.newPanelPosition()
                .absolute()
                // debug
                .left()
                .bottom();
        }
    }

    module.exports = VideoCallService;

})();
