/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    class PrescriptionsListSummaryCtrl {
        constructor(prescriptionService) {
            this.prescriptionService = prescriptionService;

            this.visit = this.visit || null;
            this.query = this.query || {};
            this.observable = this.observable || null;
            this.prescriptions = [];
        }

        static get $inject() {
            return ["prescriptionService"];
        }

        $onInit() {
            this.prescriptionService.getVisitPrescriptionsList(this.visit)
                .then(data => {
                    this.prescriptions = _.flatten(data)
                    const deactivated = _.every([
                        "medical_prescriptions",
                        "procedure_prescriptions",
                        "text_prescriptions",
                        "biology_prescriptions"
                    ], item => _.get(this.query.activated, item) === false);

                    if (this.prescriptions.length > 0 && !deactivated) {
                        this.observable.next(true);
                        this.observable.complete();
                    }
                });
        }

        isShown(item) {
            return _.get(this.query.activated, `${_.snakeCase(item.prescription_type)}s`, false)
        }

        constructedTitle(item) {
            return this.prescriptionService.constructedTitle(item);
        }
    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            visit: "=visit",
            query: "=",
            observable: "="
        },
        template: require("stand-alone/patient-summary/views/prescriptions-list.tpl.html"),
        controllerAs: "vm",
        controller: PrescriptionsListSummaryCtrl,
    }
})();