/**
 * Created by Hp on 10/03/2017.
 */


(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/in-serials-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "serialService", "$scope"];

    function DialogCtrl($mdDialog, serialService, $scope) {

        let vm = this;
        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.serialsSum = serialsSum;
        vm.checkSerialUnique = checkSerialUnique;
        vm.saveSerial = saveSerial;

        vm.generateSerials = generateSerials;
        vm.removeItem = removeItem;
        vm.showRemove = showRemove;
        vm.applyFilter = applyFilter;

        function init() {
            vm.serialConfig = {step: 1};
            vm.searchKey=null;
            vm.filteredSerials=[];
            vm.inputFocus=true;
            let add_length = vm.limit - _.size(vm.serials);
            if (add_length != 0) {
                for (let i = 0; i < add_length; i++) {
                    vm.serials.push({});
                }
            }
        }

        function checkSerialUnique(serial, idx) {
            if (_.filter(vm.serials, {code: serial.code}).length > 1) {
                $scope.serialForm['code_' + idx].$setValidity('unique', false);

            } else {
                serialService.checkSerialUniqueness(_.get(serial, 'code'), vm.article.id).then(function (data) {
                    if (data) {
                        $scope.serialForm['code_' + idx].$setValidity('unique', true);
                    } else $scope.serialForm['code_' + idx].$setValidity('unique', false);
                });
            }
        }

        function serialsSum() {
            return _.size(vm.serials);
        }

        function generateSerials() {
            let next_value = vm.serialConfig.start
            for (let i = 0; i < vm.limit; i++) {
                vm.serials[i].code = (vm.serialConfig.prefix || '') + next_value;
                checkSerialUnique(vm.serials[i], i)
                let increment_val = (+next_value) + vm.serialConfig.step;
                let serial = (_.repeat('0', next_value.length) + increment_val).slice(-next_value.length);
                next_value = serial
            }
        }

        function saveSerial() {
            $mdDialog.hide(vm.serials);
        }

        function showRemove(){
            return (vm.limit < _.size(vm.serials));
            // vm.serials = _.slice(vm.serials, 0, vm.limit);
        }

        function removeItem(idx){
            vm.serials.splice(idx, 1);
        }

        function applyFilter(){
            vm.filteredSerials = _.filter(vm.serials, item => {
                return _.isMatch(item['code'], vm.searchKey);
            });
        }
    }

    module.exports = dialog;
})();