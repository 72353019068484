/**
 * Created by BETALOS on 30/09/2016.
 */
(function () {
    "use strict";

    module.exports = mnDuration;

    mnDuration.$inject = ["system", "$translate"];

    function mnDuration(system, $translate) {

        var directive = {
            restrict: 'A',
            scope: {
                durationDate: "=mnDuration",
                startDate: "=mnStartDate",
                ngModel: "=",
            },
            require: 'ngModel',
            link: durationLink,
        };

        function durationLink(scope, element, attrs, ctrl) {
            var dateFormat = system['date_format'].js;

            var month = _.toLower($translate.instant('month'));
            var isMonth = false;

            var $parent = element.parents('md-input-container');
            var $labelElem = $("label", $parent);

            var label = $labelElem.text();
            var monthLabel = label + ' (' + month + ')';

            scope.$watch('durationDate', durationDateChange);
            scope.$watch('ngModel', durationChanged);

            ctrl.$render = render;

            function durationDateChange(value) {
                var duration = _.isNil(value) ? null : moment(scope.durationDate, dateFormat).diff(moment(scope.startDate, dateFormat), 'days');
                var oldValue = ctrl.$viewValue;

                ctrl.$viewValue = duration;
                ctrl.$render();
                ctrl.$commitViewValue();
            }

            function durationChanged(value, oldValue) {
                if (_.eq(value, oldValue)) return;
                else if (!_.isNil(value)) {
                    scope.durationDate = moment(scope.startDate, dateFormat).add(value, 'days').format(dateFormat);
                }
            }

            function render() {
                if (_.isNil(ctrl.$viewValue)) {
                    $parent.removeClass('md-input-has-value');
                    element.val(0);
                }
                else {
                    element.val(ctrl.$viewValue);
                    $parent.addClass('md-input-has-value');
                }
            }
        }

        return directive;
    }

})();
