/**
 * Created by Hp on 14/06/2017.
 */
(function () {

    'use strict';

    module.exports = moveRightLeft;

    moveRightLeft.$inject = ["$compile"];

    function moveRightLeft($compile) {

        var directive = {
            restrict: 'A',
            link: linkFunc,
        };

        function linkFunc(scope, elem, attrs) {

            var right_btn = '<md-icon class="option-icon" md-font-icon="mdi-chevron-right" md-font-set="mdi"' +
                'aria-label="right"ng-click="moveRight()"  ></md-icon>';
            var left_btn = '<md-icon class="option-icon" md-font-icon="mdi-chevron-left" md-font-set="mdi"' +
                'aria-label="left" ng-click="moveLeft()"></md-icon>';

            elem.children('th:first-child').append($compile(right_btn)(scope));
            elem.children('th:nth-child(2)').append($compile(left_btn)(scope));

            scope.moveLeft = function moveLeft() {

                jQuery.each($("table#"+elem.closest('table').attr('id')+ " tr"), function () {
                    $(this).children(":eq(1)").after($(this).children(":eq(0)"));
                });
                reOrderIcon();
            }

            scope.moveRight = function moveRight() {
                jQuery.each($("table#"+elem.closest('table').attr('id')+ " tr"), function () {
                    $(this).children(":eq(0)").before($(this).children(":eq(1)"));
                });
                reOrderIcon();
            }

            function reOrderIcon() {
                jQuery.each($("table#"+elem.closest('table').attr('id')+ " tr"), function () {
                    $compile($(this).children("th:eq(0)").children("md-icon").replaceWith($compile(right_btn)(scope)))(scope);
                    $compile($(this).children("th:eq(1)").children("md-icon").replaceWith($compile(left_btn)(scope)))(scope);
                });
            }
        };
        return directive;
    };

})()