/**
 * Created by Hp on 25/08/2017.
 */
(function () {
    'use strict';
    module.exports = MovementStateCtrl;
    MovementStateCtrl.$inject = ["$translate", "articleService", "system", "$stateParams", "$state"];

    function MovementStateCtrl($translate, articleService, system, $stateParams, $state) {
        var currentArticleId = $stateParams['articleId'];

        var vm = this;
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.handleChange = handleChange;
        vm.handleArticleChange = handleArticleChange;
        vm.getInQte = getInQte;
        vm.getOutQte = getOutQte;

        function init() {
            vm.stateQuery = {
                article: null,
                provider: null,
                warehouse: null,
                from_date: moment().startOf('year').format(dateFormat),
                to_date: moment().format(dateFormat)
            };
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            vm.options = [5, 10, 20, 100];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }
            vm.maxToDate = new Date(moment());
            vm.maxFromDate = new Date(moment(vm.stateQuery.to_date, dateFormat))
            // if (!_.isNil(currentArticleId)) {
            //     vm.stateQuery.article = {id: currentArticleId};
            //     vm.handleChange();
            // }
        }

        function loadSuccess(data) {
            vm.historic_lines = data;
        }

        function handleChange(ev) {
            vm.promise = articleService.getMovementState({
                article: _.get(vm.stateQuery, 'article.id'),
                provider: _.get(vm.stateQuery, 'provider.id'),
                warehouse: _.get(ev, 'id'),
                from_date: _.get(vm.stateQuery, 'from_date'),
                to_date: _.get(vm.stateQuery, 'to_date')
            }).then(loadSuccess);
        }

        function handleArticleChange(article) {
            vm.stateQuery.article = article;
            // if (_.isNil(currentArticleId)) $state.go('app.stock.movement-state', {articleId: article.id}, {location: 'replace'});
            vm.handleChange();
        }

        function getInQte() {
            return _.chain(vm.historic_lines).filter({type: 'IN'}).sumBy('qte').value();
        }

        function getOutQte() {
            return _.chain(vm.historic_lines).filter({type: 'OUT'}).sumBy('qte').value();
        }
    }
})
();
