/**
 * Created by amine on 20/07/2021.
 */
(function () {
    'use strict';

    const {AsyncSubject} = require("rxjs");

    const EditorConfig = require("../classes/editor-config");

    const {
        getEditor,
        getModelContent
    } = require("../utils/editor-functions");

    const {DEFAULT_OPTIONS} = require("../utils/consts");

    class Editor {
        #data = [];

        constructor(configKey, options, externalConfig, services) {
            this.configKey = _.cloneDeep(configKey);
            this.options = _.cloneDeep(options);
            this.externalConfig = _.cloneDeep(externalConfig);

            this.translate = services["translate"];
            this.dialog = services["dialog"];
            this.q = services["q"];
            this.ws = services["ws"];
            this.patientService = services["patientService"];
            this.measureService = services["measureService"];
            this.configService = services["configService"];
            this.authService = services["authService"];
            this.dateFormat = services["dateFormat"];
            this.lang = services["lang"];


            this.tinymce = require('tinymce/tinymce');
            this.addMenusSubject = new AsyncSubject();
            this.tinymceConfig = new EditorConfig(this);
        }

        init() {
            let deferred = this.q.defer();
            if (this.configKey) {
                this.ws.call("shared.Editor.get_config", {keys: [this.configKey]})
                    .then(data => {
                        this.configSuccess(data)
                            .then(editor => deferred.resolve(editor), () => deferred.reject())
                    });
            } else {
                this.configSuccess()
                    .then(editor => deferred.resolve(editor), () => deferred.reject())
            }

            // this.addMenusSubject
            //     .subscribe(data => this.addMenus(data.name, data.menus));

            return deferred.promise;
        }

        configSuccess(data = null) {
            const deferred = this.q.defer();
            // const promises = [];
            if (_.isNil(data)) {
                this.options = _.assign(DEFAULT_OPTIONS, this.options);
            } else {
                const value = _.get(data, '0.value', {});
                this.options = _.assign({}, this.options, value);
            }

            this.options.isModel = !_.isNumber(this.options.patient);

            if (this.externalConfig.customMenus) {
                const customMenus = this.externalConfig.customMenus;

                customMenus.subscribe(next => {
                    this.customMenus = next;
                    this.tinymceConfig.customMenus = _.isArray(next) ? next : false;

                    this.tinymce.init(this.tinymceConfig.generate(deferred));
                });
            } else {
                this.tinymce.init(this.tinymceConfig.generate(deferred));
            }


            return deferred.promise;
        }

        addMenus(name, menus) {
            console.log("addMenus", name, menus);
            // let editor = getEditor(name);
            //
            // if (!_.isNil(editor)) {
            //     editor.resetMenus();
            //     editor.addMenus(menus);
            // } else {
            //     this.addMenusSubject.next({name: name, menus: menus});
            // }
        }

        getModelContent(content = "") {
            return getModelContent(content);
        }

        getModel(name) {
            const editor = getEditor(name);

            if (editor) return editor.getModel();
        }

        focusOn() {
            console.warn("deprecated ot never used, to be removed");
            //let editor = getEditor(name);
            // console.log(tinymce.activeEditor);

            //this.fire("focus");
        }

        setData(key, value) {
            _.set(this.#data, key, value);
        }

        getData(key) {
            return _.chain(this.#data).cloneDeep().get(key).value();
        }

        unsetData(key) {
            return _.unset(this.#data, key);
        }

        // noToolbar() {
        //     return [
        //         this.options['has_patient_menu'],
        //         this.options['has_physician_menu'],
        //         this.options['has_measurement_menu'],
        //         this.options['has_misc_menu']
        //     ].every(e => !e) || this.options['minimal'];
        // }

    }

    module.exports = Editor;
})();