/**
 * Created by Hp on 11/04/2017.
 */
(function () {

    module.exports = InventoryFormCtrl;

    InventoryFormCtrl.$inject = ["inventoryService", "$transition$", "$state", "system", "$mdToast", "stockService", "$scope", "$translate", "$mdDialog", "mnWebSocket"];

    function InventoryFormCtrl(inventoryService, $transition$, $state, system, $mdToast, stockService, $scope, $translate, $mdDialog, mnWebSocket) {
        var vm = this;

        var currentInventory = $transition$.params('to')["inventoryId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.validateInventory = validateInventory;
        vm.invalidateInventory = invalidateInventory;
        vm.isAllValid = isAllValid;

        vm.unblockStore = unblockStore;
        vm.blockStore = blockStore;
        vm.updateWarehouse = updateWarehouse;

        vm.removeInventory = removeInventory;
        vm.cancel = cancel;
        vm.prePrint = prePrint;
        vm.exportInventoryLines = exportInventoryLines;

        function init() {
            vm.is_new = !_.isNil(currentInventory) ? false : true;
            vm.docType = 'IN';
            $scope.$watch('vm.inventory.is_valid', readOnlyInventory);
            vm.modelName = 'Inventory';

            if (!_.isNil(currentInventory)) vm.promise = inventoryService.getInventory(currentInventory).then(success);
            else vm.inventory = {inv_date: moment().format(dateFormat), lines: []};

            function success(data) {
                vm.inventory = data;
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = inventoryService.saveInventory(vm.inventory).then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) {
                    window.history.back();
                }
                else {
                    saveDone(data);
                }
            }

            function error(err) {
                vm.prevent_save = false;
            }

            function saveDone(data) {
                vm.inventory = data;
                if (_.isNil(currentInventory)) $state.go('app.stock.inventory-form', {inventoryId: data.id}, {location: 'replace'});
            }
        }

        function validateInventory() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validate_warning'))
                        .ariaLabel('validate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    vm.prevent_validate = true;
                    inventoryService.saveInventory(vm.inventory).then(handleValidation);
                });
            }

            function handleValidation(data) {
                vm.inventory = data;
                blockStore();
                vm.promise = inventoryService.checkDocValidation(vm.inventory).then(success, errorCallback);

                function success(data) {
                    if (!_.isNil(data) && data[0] == true) startValidation();
                    else {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .parent($(document.body))
                                .clickOutsideToClose(true)
                                .title('Warning')
                                .textContent($translate.instant('validating_inventory_impossible' + data[1]))
                                .ariaLabel('validating_inventory_impossible')
                                .ok('Ok')
                        );
                        unblockStore();
                        vm.prevent_validate = false;
                    }
                }

                function errorCallback(err) {
                    unblockStore();
                    vm.prevent_validate = false;
                }
            }
        }

        function invalidateInventory() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    vm.prevent_validate = true;
                    startInValidation();
                });
            }

            function startInValidation() {
                vm.promise = inventoryService.invalidateInventory(vm.inventory).then(invalidationDone, invalidationError);

                function invalidationDone(data_obj) {
                    vm.inventory = data_obj;
                    // unblockStore();
                    vm.prevent_validate = false;
                }

                function invalidationError(error) {
                }
            }
        }

        function startValidation() {
            vm.promise = inventoryService.validateInventory(vm.inventory).then(validationDone, validationError);

            function validationDone(data_obj) {
                vm.inventory = data_obj;
                unblockStore();
                vm.prevent_validate = false;
            }

            function validationError(error) {
            }
        }

        function readOnlyInventory() {
            vm.readOnly = (vm.inventory && vm.inventory.is_valid) ? vm.inventory.is_valid : false;
        }

        function isAllValid() {
            // return !_.find(this.inventory.lines , (row) => {return row.qte_diff<0 || row.pmp_diff<0 }) && stockService.isAllValid(vm.inventory, vm.docType, vm.modelName);
            return stockService.isAllValid(vm.inventory, vm.docType, vm.modelName);
        }

        function successBlock(data) {
            vm.inventory.is_start = data.is_start;
            mnWebSocket.pub("stock.Inventory.is_inventory_started", vm.inventory.is_start, false);
        }

        function unblockStore() {
            inventoryService.blockStore({is_start: false}, vm.inventory.id).then(successBlock);
        }

        function blockStore() {
            inventoryService.blockStore({is_start: true}, vm.inventory.id).then(successBlock);

        }

        function updateWarehouse(ev) {
            if (vm.inventory) {
                _.forEach(vm.inventory.lines, function (line) {
                    if(ev){line.warehouse = ev;}
                });
            }
        }

        function removeInventory() {
            if (!vm.prevent_remove) {
                vm.prevent_remove = true;
                vm.promise = stockService.deleteStockObject(vm.inventory.id, 'inventory', vm.modelName).then(success);
            }

            function success(data) {
                vm.prevent_remove = false;
                goBack();
            }
        }

        function cancel() {
            window.history.back();
        }

        function prePrint() {
            return inventoryService.saveInventory(vm.inventory);
        }

        function exportInventoryLines() {
            let viewColumns = [
                {
                    label: 'code',
                    key: 'code',
                    type: 'text'
                },
                {
                    label: 'designation',
                    key: 'article.short_desc',
                    type: 'text'
                },
                {
                    label: 'real_qte',
                    key: 'qte',
                    type: 'number'
                },
                {
                    label: 'theo_qte',
                    key: 'theo_qte',
                    type: 'number'
                },
                {
                    label: 'qte_diff',
                    key: 'qte_diff',
                    type: 'number'
                },
                {
                    label: 'pmp_price',
                    key: 'pmp_price',
                    type: 'number'
                },
                {
                    label: 'theo_pmp_price',
                    key: 'theo_pmp_price',
                    type: 'number'
                },
                {
                    label: 'pmp_diff',
                    key: 'pmp_diff',
                    type: 'number'
                },
                {
                    label: 'detail_amount',
                    key: 'sub_total',
                    type: 'number'
                }
            ]
            function reduceFunc(items, item) {
                return _.set(items, item.key, $translate.instant(item.label));
            }
            let translatedColumns;
            translatedColumns = _.reduce(viewColumns, reduceFunc.bind(this), {
                file_name: 'inventory-details',
                page_name: $translate.instant('export_excel_page_name')
            });
            let excelColumns;
            excelColumns = _.map(viewColumns, (e) => {
                return _.pick(e, 'key', 'type');
            });
            vm.promise = inventoryService.saveInventory(vm.inventory).then(success, (err) => {
                _.noop();
            });
            function success(data) {
                inventoryService.exportXls(vm.inventory.id,translatedColumns,  excelColumns ).then((data) => {
                    data.download();
                })
            }
        }
    }
})();