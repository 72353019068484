/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    var addArticleDialog = {
        controller: addArticleDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/article-form-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen:false
    };

    addArticleDialogCtrl.$inject = ["$mdDialog", "articleService", "medicineService", "familyService", "$scope"];

    function addArticleDialogCtrl($mdDialog, articleService, medicineService, familyService, $scope) {

        let vm = this;
        vm.$onInit = init;

        vm.cancel = $mdDialog.cancel;
        vm.submit = submit;
        vm.changeMedicine = changeMedicine;
        vm.getMedicines = medicineService.getMedicines;
        vm.checkCodeUniqueness = checkCodeUniqueness;
        vm.filterSubFamily = filterSubFamily;


        function init() {
            if (_.isNil(vm.article)) vm.article = {
                store_min_qte: 0.0,
                sale_price: 1.0,
                sale_min_price: 1.0,
                purchase_price: 1.0,
                stock_valuation: 'FIFO',
                store_min_deposition:0.0

            };
            vm.allow_update = _.isNil(vm.article.id) ? true: false;
            familyService.getFamilies().then(success);
            function success(data) {
                vm.families = data;
            }
        }

        function submit() {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                if (!_.isNil(vm.article.family) && !_.has(vm.article.family, 'id')) familyService.saveFamily(vm.article.family).then(function (data) {
                    vm.article.family = data;
                    if (!_.isNil(vm.article.sub_family)) vm.article.sub_family.parent_family = data;
                    articleService.saveArticle(vm.article, vm.file)
                        .then(success, error);
                });
                else articleService.saveArticle(vm.article, vm.file)
                    .then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                $mdDialog.hide(data);
            }
            function error(err) {
                vm.prevent_save = false;
            }
        }

        function changeMedicine(item) {
            articleService.changeMedicine(item, vm.article, vm.families);
        }

        function checkCodeUniqueness(code) {
            articleService.checkCodeExistence(code, vm.article.id).then(done);
            function done(data) {
                var code_valid = !_.isNil(data) ? false : true;
                $scope.ArticleForm['code'].$setValidity('unique', code_valid);
            }
        }
        function filterSubFamily(item) {
            return (!_.isNil(vm.article.family) && !_.isNil(vm.article.family.id) && item.parent_family_id == vm.article.family.id) || item.parent_family != null;
        }
    }

    module.exports = addArticleDialog;

})();