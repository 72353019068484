/**
 * Created by amine on 08/09/2017.
 */

(function () {
    "use strict";

    const {AsyncSubject} = require("rxjs");

    const DataInjector = require("../plugins/data-injector");
    const SpeechRecognitionCapability = require("../plugins/speech-recognition-capability");
    //const TextTemplates = require("../plugins/text-templates");

    const TEXT_EDITOR_CONFIG = require('../json/text-editor.json');
    const {DEFAULT_KEY_HANDLERS} = require("../utils/consts");
    const CONTEXTS = {
        "minimal": require('../json/minimal-text-editor.json'),
        "email": require("../json/email-text-editor.json"),
        "consent": require("../json/consent-text-editor.json"),
        "video-call": require("../json/video-call-text-editor.json"),
        "reminder-email": require("../json/reminder-email-text-editor.json"),
    };

    const {
        compile,
        compileContent,
        resetMenus,
        getModel,
        addMenus
    } = require("../utils/editor-functions");

    const calculateFormula = require('shared/utils/calculate-formula');

    class EditorConfig {
        constructor(editorInstance) {
            this.editorInstance = editorInstance;
            this.customMenus = false;
        }

        get #options() {
            return this.editorInstance.options;
        }

        get #externalConfig() {
            return this.editorInstance.externalConfig;
        }

        get #isMinimal() {
            return !!this.#options.minimal;
        }

        get #context() {
            return this.#options.context
        }

        set #context(value) {
            this.#options.context = value
        }

        generate(deferred) {
            const contextConfig = _.get(CONTEXTS, this.#context, {})
            const dataInjector = new DataInjector(this.editorInstance);
            new SpeechRecognitionCapability(this.editorInstance);
            // self.textTemplates = new TextTemplates(options, $translate, $mdDialog, $q, editorTemplateService);
            const dynamicToolbar = dataInjector.setMenus(_.concat([
                this.#patientMenu(),
                this.#measurementMenu(),
                this.#physicianMenu(),
                this.#definedBlockMenu(),
                this.#ReminderEmailMenu(),
                this.#VideoCallMenu(),
                this.#ConsentMenu()
            ], !!this.customMenus ? this.customMenus : []));

            if (this.#isMinimal) {
                this.#context = "minimal"
            }

            const fullConfig = _.assign({
                language: this.editorInstance.lang,
                keyHandlers: DEFAULT_KEY_HANDLERS,
                template: "",
                setup: (editor) => {
                    if (_.isFunction(this.#externalConfig.onSetup))
                        this.#externalConfig.onSetup(editor);

                    if (_.isFunction(this.#options.onSetup))
                        this.#options.onSetup(editor);
                },
                init_instance_callback: editor => {
                    editor.__meta__ = {
                        options: this.#options,
                        config: this.#externalConfig
                    };
                    //editor.theme.panel.$el.find(".mce-toolbar-grp").addClass("only-toolbar-grp")

                    if (!this.#options.minimal) {
                        editor.compile = content => compile(editor, content);
                        editor.compileContent = () => compileContent(editor);
                        editor.resetMenus = () => resetMenus(editor);
                        editor.getModel = () => getModel(editor);
                        editor.addMenus = menus => addMenus(editor, menus);

                        editor.plugins['data_injector'].handleMnVarClick();
                        editor.plugins['speech_recognition'].afterEditorInit();
                    }

                    this.editorInstance.addMenusSubject.complete();
                    this.editorInstance.addMenusSubject = new AsyncSubject();

                    deferred.resolve(editor);

                    if (_.isFunction(this.#options.onInit)) this.#options.onInit(editor);
                },
                file_picker_callback: (cb, value, meta) => {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = function () {
                        let file = this.files[0];

                        let reader = new FileReader();
                        reader.onload = function () {
                            // Note: Now we need to register the blob in TinyMCEs image blob
                            // registry. In the next release this part hopefully won't be
                            // necessary, as we are looking to handle it internally.
                            const id = 'blobid' + (new Date()).getTime();
                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            // call the callback and populate the Title field with the file name
                            cb(blobInfo.blobUri(), {title: file.name});
                        };
                        reader.readAsDataURL(file);
                    };

                    input.click();
                }
            }, TEXT_EDITOR_CONFIG, contextConfig, this.#externalConfig);

            if (!!dynamicToolbar) fullConfig.toolbar = fullConfig.toolbar.replace("data_injector_menus", dynamicToolbar)

            return fullConfig
        }

        #getValue(instance, key) {
            if (this.#options.isModel || !instance) {
                return null
            } else {
                return () => {
                    let value = _.get(instance, key, "");
                    return _.isNil(value) ? "" : value;
                };
            }
        }

        #patientMenu() {
            return {
                title: "patient_editor_data",
                dynamic: true,
                show: this.#options.has_patient_menu,
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();

                    this.editorInstance.patientService
                        .getFormPatient(this.#options.patient)
                        .then(data => {
                            const has_parents_name = this.editorInstance.configService.activeModules['has_parents_name']
                            const patient = _.has(data, "id") ? data : null;
                            // this.editorInstance.setData("patient", patient);

                            let items = [
                                {
                                    title: "file_number",
                                    value: this.#getValue(patient, "file_number"),
                                    key: "patient::file_number"
                                },
                                {
                                    title: "full_name",
                                    value: this.#getValue(patient, "full_name"),
                                    key: "patient::full_name"
                                },
                                {
                                    title: "birth_date",
                                    value: this.#getValue(patient, "birth_date"),
                                    key: "patient::birth_date"
                                },
                                {
                                    title: "age",
                                    value: this.#getValue(patient, "age"),
                                    key: "patient::age"
                                },
                                {
                                    title: "address",
                                    value: this.#getValue(patient, "contact_info.address"),
                                    key: "patient::address"
                                },
                                {
                                    title: "city",
                                    value: this.#getValue(patient, "contact_info.city.full_name"),
                                    key: "patient::city"
                                },
                                {
                                    title: "phone_number",
                                    value: this.#getValue(patient, "contact_info.phone_numbers[0]"),
                                    key: "patient::phone_number"
                                },
                                {
                                    title: "profession",
                                    value: this.#getValue(patient, "profession.value"),
                                    key: "patient::profession"
                                },
                                {
                                    title: "national_id",
                                    value: this.#getValue(patient, "national_id"),
                                    key: "patient::national_id"
                                },
                                {
                                    title: "treating_physician",
                                    value: this.#getValue(patient, "external_treating_physician.full_name"),
                                    key: "patient::treating_physician"
                                },
                                {
                                    title: "file_date",
                                    value: this.#getValue(patient, "file_date"),
                                    key: "patient::file_date"
                                }
                            ];

                            if (has_parents_name) {
                                items = _.concat(items, [{
                                    title: "|",
                                }, {
                                    title: "father_fullname",
                                    value: this.#getValue(patient, "father_name"),
                                    key: "patient::father_name"
                                }, {
                                    title: "mother_fullname",
                                    value: this.#getValue(patient, "mother_name"),
                                    key: "patient::mother_name"
                                }])
                            }

                            deferred.resolve(items);
                        });

                    return deferred.promise;
                }
            }
        }

        #getMeasurement(measure, measures, measurements) {
            if (this.#options.isModel || _.isNull(measurements)) {
                return null;
            } else {
                return () => {
                    const unit = !_.isNull(measure.unit) ? (" " + measure.unit.value) : "";
                    const measurement = _.get(measurements, measure.id, {});
                    if (_.isEmpty(measurement) && measure.type !== "calculated") {
                        return `- ${unit}`;
                    } else {
                        switch (measure.type) {
                            case "integer":
                                return `${measurement.value.toFixed(0)} ${unit}`;
                            case "float":
                                return `${measurement.value.toFixed(2)} ${unit}`;
                            case "boolean":
                                return measurement.value ? this.editorInstance.translate.instant("yes") : this.editorInstance.translate.instant("no");
                            case "calculated":
                                const calculatedValue = calculateFormula(measure.formula, measurements, measures);
                                return `${calculatedValue ? calculatedValue.toFixed(2) : "-"} ${unit}`;
                            default:
                                return `${measurement.value} ${unit}`;
                        }
                    }
                }
            }
        }

        #measurementMenu() {
            return {
                title: "patient_measurements_editor_data",
                dynamic: true,
                show: this.#options.has_measurement_menu,
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();

                    this.editorInstance.q.all([
                        this.editorInstance.measureService.getMeasures(),
                        this.#options.patient
                            ? this.editorInstance.measureService.getMeasurementResume({patient_id: this.#options.patient})
                            : this.editorInstance.q.when(null)
                    ]).then(data => {
                        const measures = data[0];
                        const measurements = data[1];

                        deferred.resolve(_.reduce(measures, (result, value) => {
                            result.push({
                                title: value.name,
                                value: this.#getMeasurement(value, measures, measurements),
                                key: `measurements::measure${value.id}`
                            })

                            return result;
                        }, []));
                    });

                    return deferred.promise;
                }
            }
        }

        #physicianMenu() {
            let physician = _.get(this.editorInstance.authService, "staff", false);

            return {
                title: "physician_editor_data",
                dynamic: true,
                show: this.#options.has_physician_menu,
                items: [
                    {
                        title: "full_name",
                        value: this.#getValue(physician, "full_name"),
                        key: "physician::full_name"
                    }
                ]
            }
        }

        #getVaccinations(vaccinations) {
            return () => {
                if (!!vaccinations) {
                    const $el = $("<div />").append($("<ul />"));

                    vaccinations.forEach(vaccination => {
                        const li =
                            `<li>
                                    <span style='text-decoration: underline;font-weight: bold;'>${vaccination.vaccine_name}: ${vaccination.vaccination_date}(${vaccination.age_at_vaccination})</span>
                                </li>`;

                        $("ul", $el).append(li);

                        return $el.html();
                    });
                } else {
                    return "-";
                }
            }
        }

        #definedBlockMenu() {
            return {
                title: "plus_editor_data",
                dynamic: true,
                show: this.#options.has_misc_menu,
                fetch: () => {
                    const deferred = this.editorInstance.q.defer();
                    (
                        this.#options.patient
                            ? this.editorInstance.patientService.getVaccinations(this.#options.patient)
                            : this.editorInstance.q.when([])
                    ).then(data => {
                        deferred.resolve([
                            {
                                title: "date",
                                value: () => {
                                    return this.editorInstance.moment().format(this.editorInstance.dateFormat);
                                },
                                key: "misc::date"
                            },
                            {
                                title: "patient_vaccination",
                                value: this.#getVaccinations(data),
                                key: "defined::vaccinations"
                            }
                        ]);
                    });

                    return deferred.promise;
                }
            }
        }

        #ReminderEmailMenu() {
            return {
                title: "reminder_email",
                items: [
                    {
                        title: "full_name",
                        value: null,
                        key: "patient::fullname"
                    },
                    {
                        title: "appointment_date",
                        value: null,
                        key: "appointment::date"
                    },
                    {
                        title: "appointment_time",
                        value: null,
                        key: "appointment::time"
                    }
                ]
            };
        }

        #VideoCallMenu() {
            return {
                title: "video_call",
                items: [
                    {
                        title: "full_name",
                        value: null,
                        key: "patient_full_name"
                    },
                    {
                        title: "physician_name",
                        value: null,
                        key: "physician_full_name"
                    },
                    {
                        title: "video_call_url",
                        value: null,
                        key: "video_call_url"
                    },
                    {
                        title: "video_call_password",
                        value: null,
                        key: "video_call_password"
                    }
                ]
            };
        }

        #ConsentMenu() {
            return {
                title: "consent",
                items: [
                    {
                        title: "full_name",
                        value: null,
                        key: "patient::fullname"
                    },
                    {
                        title: "birth_date",
                        value: null,
                        key: "patient::birth_date"
                    },
                    {
                        title: "age",
                        value: null,
                        key: "patient::age"
                    },
                    {
                        title: "|"
                    },
                    {
                        title: "signature",
                        value: null,
                        key: "document::signature"
                    },
                    {
                        title: "signature_date",
                        value: null,
                        key: "document::signature_date"
                    },
                ]
            };
        }
    }

    module.exports = EditorConfig;
})();
