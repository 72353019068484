/**
 * Created by Hp on 10/05/2017.
 */
(function () {
    'use strict';

    module.exports = ProviderCtrl;

    ProviderCtrl.$inject = ["$mdDialog", "providerService", "$translate", "$mdToast"];

    function ProviderCtrl($mdDialog, providerService, $translate, $mdToast) {

        var vm = this;
        vm.reset = null;
        vm.$onInit = init;

        vm.addProvider = addProvider;

        function init() {
            vm.filter = {is_deleted: {$ne: true}};
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'provider',
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'provider',
                        action: 'delete',
                        behavior: 'disable',
                        method: deleteProvider
                    }
                ],
                multiple: []
            }

        }

        function goToEdition(obj) {
            showProviderDialog(null, obj);
        }

        function deleteProvider(obj) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_object_warning'))
                    .ariaLabel('delete_object_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                providerService.checkProviderDependencies(obj.id).then(checkSuccess);
            });

            function checkSuccess(data) {
                if (data == true) providerService.deleteProvider(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
                else showWarning();
            }

            function showWarning() {
                var simpleToast = $mdToast.simple()
                    .textContent($translate.instant('impossible_delete'))
                    .position("top right")
                    .hideDelay(3000);
                $mdToast.show(simpleToast);
            }

        }

        function addProvider(ev) {
            showProviderDialog(ev, null);
        }

        function showProviderDialog(ev, provider) {
            const dialog = _.assign({}, require('../dialogs/provider-dialog'), {
                targetEvent: ev || null,
                locals: {
                    provider: _.cloneDeep(provider) || null
                },
                clickOutsideToClose: false,
                focusOnOpen: false
            });
            $mdDialog.show(dialog)
                .then(_.isFunction(vm.reset) ? vm.reset : _.noop);
        }
    }
})();