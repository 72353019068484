/**
 * Created by Hp on 03/05/2017.
 */
(function () {

    module.exports = tariffService;

    tariffService.$inject = ["mnWebSocket", "$q", "$http"];

    function tariffService(mnWebSocket, $q, $http) {
        var self = this;

        self.saveTariff = saveTariff;
        self.getTariffs = getTariffs;
        self.checkNameUniqueness = checkNameUniqueness;
        self.handlePrice = handlePrice;
        self.deleteTariff = deleteTariff;


        function saveTariff(tariff) {
            var deferred = $q.defer();
            var url = "/api/pricing/";
            if (!_.isNil(tariff.id)) url += tariff.id + "/";
            $http.post(url, tariff)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteTariff(pk) {
            var deferred = $q.defer();
            $http.delete(`/api/pricing/${pk}/`)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getTariffs() {
            var deferred = $q.defer();
            var url = "/api/pricing/";
            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function checkNameUniqueness(name, _pk) {
            return mnWebSocket.call('shared.Pricing.check_name_uniqueness', {name, _pk});
        }

        function handlePrice(price, tariff, obj, obj_model, obj_module, rule, value) {
            let l = _.find(tariff.lines, (e) => {
                return e.base_object.id == obj.id && e.base_object._model == obj_model && e.base_object._module == obj_module;
            })
            if (!_.isNil(l)) {
                price = handleTariffException(l, price, rule, tariff, value)
            }
            else{
                price = handleTariffException(tariff, price, rule, tariff, value)
            }
            return price
        }

        function handleTariffException(line, price, rule, tariff, value) {
            let t = _.find(line.tariffs, (e) => {
                return e.rule.name == rule
            })
            if (!_.isNil(t)) {
                let d = _.find(t.details, (e) => {
                    if (t.rule.type == 'number') {
                        return _.inRange(value, e.born_inf, e.born_sup);
                    }
                    if (t.rule.type == 'select') {
                        return value == e.born_inf;
                    }
                })
                if (!_.isNil(d)) {
                    price = handlePriceByTariffType(price, d, t.type);
                }
                else {
                    if (!_.isNil(line.base_tariff.type) && !_.isNil(line.base_tariff.value)) {
                        price = handlePriceByTariffType(price, line.base_tariff, line.base_tariff.type)
                    }
                    else {
                        price = handleTariffException(tariff, price, rule, tariff, value);
                    }
                }
            }
            else{
                if (!_.isNil(line.base_tariff.type) && !_.isNil(line.base_tariff.value)) {
                    price = handlePriceByTariffType(price, line.base_tariff, line.base_tariff.type)
                }
                else {
                    price = handleTariffException(tariff, price, rule, tariff, value);
                }
            }
            return price;
        }

        function handlePriceByTariffType(price, tariff, type) {
            if (type == 'amount') {
                price = tariff.value;
            }
            if (type == 'discount') {
                price = price - (price * tariff.value / 100);
            }
            if (type == 'formula') {
                price = price;
            }
            return price
        }
    }

})();