/**
 * Created by amine on 11/11/2018.
 */


(function () {

    'use strict';

    class SMSEditorCtrl {
        constructor($element) {
            this.$element = $element;

            this.regularFields = [
                {
                    label: "full_name",
                    insert: () => this.insert('patient::fullname', 'patient.full_name'),
                },
                {
                    label: "appointment_date",
                    insert: () => this.insert('appointment::date', 'appointment.date'),
                },
                {
                    label: "appointment_time",
                    insert: () => this.insert('appointment::time', 'appointment.start_time'),
                }
            ];

            this.videoCallfields = [
                {
                    label: "full_name",
                    insert: () => this.insert('patient_full_name', 'patient_full_name'),
                },
                {
                    label: "physician_name",
                    insert: () => this.insert('physician_full_name', 'physician_full_name'),
                },
                {
                    label: "video_call_url",
                    insert: () => this.insert('video_call_url', 'video_call_url'),
                },
                {
                    label: "video_call_password",
                    insert: () => this.insert('video_call_password', 'video_call_password'),
                }
            ];

            this.model = this.model || null;
            this.patient = this.patient || null;
            this.appointment = this.appointment || null;
            this.isModel = this.isModel || null;
            this.isVideoCall = this.isVideoCall || null;
        }

        static get $inject() {
            return ["$element"];
        }

        $onInit() {
            this.fields = this.customFields ? this.handleCustomFields() : this.regularFields;
        }


        $onChanges() {
            this.fields = this.isVideoCall ? this.videoCallfields : this.regularFields;
        }

        insert(key, path) {
            const textArea = $('textarea', this.$element);
            const cursorPos = textArea.prop('selectionStart');
            const v = textArea.val();
            const textBefore = v.substring(0, cursorPos);
            const textAfter = v.substring(cursorPos, v.length);

            textArea
                .val(`${textBefore} ${this.isModel ? key.replace(/^/, '{{ ').replace(/$/, ' }}') : _.get(this, path, '')} ${textAfter}`)
                .trigger('change')
                .trigger('focus');
        }

        handleCustomFields() {
            return _.map(this.customFields, (e) => {
                return {label: e.label, insert: () => this.insert(e.key, e.path)}
            })
        }

    }

    module.exports = {
        bindings: {
            model: "=ngModel",
            patient: "<",
            appointment: "<",

            isModel: "<",
            isVideoCall: "<?",
            customFields: "=customFields"
        },
        template: require('../views/sms-editor.tpl.html'),
        controllerAs: "vm",
        controller: SMSEditorCtrl,
    };

})();
