/**
 * Created by Hp on 08/03/2017.
 */
(function () {

    'use strict';

    module.exports = familyService;

    familyService.$inject = ["$http", "$q", "mnWebSocket"];

    function familyService($http, $q, mnWebSocket) {
        var self = this;
        self.getFamilies = getFamilies;
        self.getFamily = getFamily;
        self.saveFamily = saveFamily;
        self.deleteFamily = deleteFamily;

        self.checkFamilyDependencies = checkFamilyDependencies;
        self.checkNameExistence = checkNameExistence;
        self.checkFamilyChildren = checkFamilyChildren;

        function getFamilies() {
            var deferred = $q.defer();
            var url = "/api/family/";
            $http.get(url)
                .then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getFamily(family_id) {
            var deferred = $q.defer();
            var url = "/api/family/" + family_id + "/";
            $http.get(url)
                .then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function saveFamily(family) {
            var deferred = $q.defer();
            var url = "/api/family/";
            if (!_.isNil(family.id)) url += family.id + "/";
            $http.post(url, family)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteFamily(family_id) {
            var deferred = $q.defer();
            var url = "/api/family/" + family_id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function checkFamilyDependencies(family_id) {
            return mnWebSocket.call('stock.ArticleFamily.check_family_dependencies', {family_id: family_id});
        }

        function checkNameExistence(name, family_id, field="name") {
            return mnWebSocket.call('stock.ArticleFamily.check_name_existence', {
                name: name,
                field,
                family_id: family_id || null
            });
        }

        function checkFamilyChildren(family_id) {
            return mnWebSocket.call('stock.ArticleFamily.check_family_children', {
                family_id: family_id
            });
        }
    }


})();