/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = OrderFormCtrl;

    OrderFormCtrl.$inject = ["$transition$", "$scope", "stockService", "$mdDialog", "system", "$state", "$mdToast", "$translate", "purchaseService", "configService", "$q"];

    function OrderFormCtrl($transition$, $scope, stockService, $mdDialog, system, $state, $mdToast, $translate, purchaseService, configService, $q) {

        var vm = this;
        var currentOrder = $transition$.params('to')["orderId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateOrder = invalidateOrder;
        vm.validateOrder = validateOrder;
        vm.save = save;

        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.saveSuccess = saveSuccess;
        vm.handleState = handleState;


        function init() {
            vm.is_new = !_.isNil(currentOrder) ? false : true;
            vm.docType = 'BC';
            $scope.$watch('vm.order.is_valid', readOnlyOrder);
            vm.modelName = 'PurchaseForm';
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};

            vm.promises = [configService.get(["price_mode"], true)];

            if (!_.isNil(currentOrder)) vm.promises.push(stockService.getStockDocument(currentOrder, 'purchase-form'));
            else vm.order = {doc_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentOrder)) vm.order = data[1];
                else vm.order.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.order = data;
            if (_.isNil(currentOrder)) $state.go('app.stock.order-form', {orderId: data.id}, {location: 'replace'});
        }

        function invalidateOrder() {
            return stockService.invalidateMovement(_.get(vm.order, 'id'), 'PurchaseForm', vm.docType);
        }

        function validateOrder(data) {
            vm.order.is_valid=true;
            return vm.save();
        }

        function readOnlyOrder() {
            vm.readOnly = (vm.order && vm.order.is_valid) ? vm.order.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.order, "purchase-form", 'PurchaseForm');
        }

        function removePatient() {
            vm.order.patient = null;
        }

        function patientCallback(patient) {
            vm.order.patient = patient;
        }

        function handleState(state) {
            stockService.updateStockDocument(vm.order.id, "purchase-form", _.zipObject([state], [true]))
        }
    }
})
();
