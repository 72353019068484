/**
 * Created by Hp on 14/03/2017.
 */
(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/in-lots-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "$scope", "system", "lotService", "$q", "$translate"];

    function DialogCtrl($mdDialog, $scope, system, lotService, $q, $translate) {

        let vm = this;
        let dateFormat = system['date_format'].js;
        vm.cancel = $mdDialog.cancel;

        //*************************************************** in lot ***************************************************
        vm.addNew = addNew;
        vm.saveLot = saveLot;
        vm.maxQte = maxQte;
        vm.removeLine = removeLine;
        vm.$onInit = init;
        vm.overQte = overQte;
        vm.sumQte = sumQte;

        function init() {
            vm.lotQuery = {
                limit: 4,
                page: 1,
                order: "+code"
            };
            vm.options = [5, 10, 15];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

        }

        function addNew() {
            vm.lots.push({
                store_qte: vm.limit - _.sumBy(vm.lots, 'store_qte') ,
                expiration_date: moment().format(dateFormat),
                // article: vm.line.article
            });
            vm.focusIndex=_.size(vm.lots)>0 ? vm.lots.length-1 : 0;

        }

        function saveLot() {
            $mdDialog.hide(vm.lots);
        }

        function maxQte(idx) {
            return vm.limit -  _.sumBy(_.reject(vm.lots,(e,i)=>{return idx==i; }),'store_qte');
        }

        function removeLine(idx) {
            return  vm.lots.splice(idx, 1);

        }

        function overQte() {
            return vm.limit <= _.sumBy(vm.lots, 'store_qte');
        }
        function sumQte() {
            return _.sumBy(vm.lots, 'store_qte');
        }

    }

    module.exports = dialog;
})();