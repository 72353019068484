/**
 * Created by Hp on 11/04/2017.
 */
(function () {
    module.exports = inventoryService;

    inventoryService.$inject = ["$http", "$q", "mnWebSocket"];

    function inventoryService($http, $q, mnWebSocket) {
        var self = this;

        self.getInventory = getInventory;
        self.saveInventory = saveInventory;

        self.validateInventory = validateInventory;
        self.invalidateInventory = invalidateInventory;
        self.checkDocValidation = checkDocValidation;

        self.blockStore = blockStore;
        self.exportXls = exportXls;


        function getInventory(inventory_id) {
            var url = "/api/inventory/" + inventory_id+'/';



            var deferred = $q.defer();
            $http.get(url).then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function saveInventory(inventory) {
            var deferred = $q.defer();
            var url = "/api/inventory/";
            if (!_.isNil(inventory.id)) url += inventory.id + "/";
            $http.post(url, inventory)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function validateInventory(inventory) {
            return mnWebSocket.call('stock.Inventory.validate', inventory)
        }

        function invalidateInventory(inventory) {
            return mnWebSocket.call('stock.Inventory.invalidate', inventory)
        }

        function checkDocValidation(inventory) {
            return mnWebSocket.call('stock.Inventory.verify_store_documents_validation', inventory);
        }

        function blockStore(put_value, _id) {
            var deferred = $q.defer();
            var url = "/api/inventory/" + _id + "/";

            $http.put(url, put_value)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }
        
        function exportXls(_pk,translatedColumns,excelColumns) {
            return mnWebSocket.call('stock.Inventory.export_xls',{_pk,translatedColumns,excelColumns});
            
        }

    }
})();