(function () {
    'use strict';

    module.exports = ConsignmentReturnCtrl;

    ConsignmentReturnCtrl.$inject = ["purchaseService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog","$q", "configService"];

    function ConsignmentReturnCtrl(purchaseService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog,$q, configService) {

        var vm = this;
        var currentConsignmentReturn = $transition$.params('to')["consignmentReturnId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateConsignment = invalidateConsignment;
        vm.validateConsignment = validateConsignment;
        vm.isAllValid = isAllValid;
        vm.save = save;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;

        function init() {
            vm.is_new = !_.isNil(currentConsignmentReturn) ? false : true;
            vm.docType = 'ConsignmentReturn';
            vm.modalFilter = {is_deleted: {$ne:true}, is_valid: {$ne:false}};
            $scope.$watch('vm.consignmentReturn.is_valid', readOnlyConsignment);

            vm.promises = [ configService.get(["price_mode"], true)];
            if (!_.isNil(currentConsignmentReturn))  vm.promises.push( stockService.getStockDocument(currentConsignmentReturn, 'consignment-return') );
            else   vm.consignmentReturn = {price_mode: 'HT', doc_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);
            function success(data) {
                if (!_.isNil(currentConsignmentReturn)) vm.consignmentReturn = data[1];
                else vm.consignmentReturn.price_mode = data[0]; }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success   , error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else  saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.consignmentReturn = data;
            if (_.isNil(currentConsignmentReturn)) $state.go('app.stock.consignment-return-form', {consignmentReturnId: data.id}, {location: 'replace'});
        }

        function invalidateConsignment() {
            return stockService.invalidateMovement(_.get(vm.consignmentReturn,'id'), 'ConsignmentReturn', 'ConsignmentReturn');
        }

        function validateConsignment(data) {
            return stockService.validateMovement(_.get(data,'id'), 'ConsignmentReturn', 'ConsignmentReturn');
        }

        function readOnlyConsignment() {
            vm.readOnly = (vm.consignmentReturn && vm.consignmentReturn.is_valid) ? vm.consignmentReturn.is_valid : false;
        }

        function isAllValid() {
            return vm.consignmentReturn ? stockService.isAllValid(vm.consignmentReturn, vm.docType, vm.modelName) : true;
        }

        function save() {
            return stockService.saveStockDocument(vm.consignmentReturn, "consignment-return", 'ConsignmentReturn');
        }
        function patientCallback(patient) {
            vm.consignmentReturn.patient = patient;
        }
        function removePatient() {
            vm.consignmentReturn.patient = null;
        }
    }
})
();
