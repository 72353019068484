/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    class CertificatesListSummary {
        constructor(consultationService) {
            this.consultationService = consultationService;

            this.visit = this.visit || null;
            this.change = this.change || _.noop;
            this.consultation = this.consultation || {};
            this.config = {};
            this.titles = [];
        }

        static get $inject() {
            return ["consultationService"];
        }

        $onInit() {
            this.titles = [];
            this.consultationService.getConfigItem(this.consultation.template_id)
                .then(data => {
                    this.config = data;
                });
        }

        constructedTitle(item) {
            return this.certificateService.constructedTitle(item);
        }

    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            consultation: "=ngModel",
            visit: "<visit"
        },
        template: require("stand-alone/patient-summary/views/consultation.tpl.html"),
        controllerAs: "vm",
        controller: CertificatesListSummary,
    }
})();