/**
 * Created by amine on 11/06/2021.
 */
(function () {

    const GUARDIANSHIP_RELATIONS = [
        {value: 'PARENT', label: "patient_relations_parent"},
        {value: 'GREAT_PARENT', label: "patient_relations_great_parent"},
        {value: 'AUNT_UNCLE', label: "patient_relations_aunt_uncle"},
        {value: 'SIBLING', label: "patient_relations_sibling"},
        {value: 'COUSIN', label: "patient_relations_cousin"},
        {value: 'OTHER', label: "patient_relations_other"}
    ]

    'use strict';

    class SportFileCtrl {
        constructor(sportDataService, patientService, configService, $q, moment, system) {
            this.sportDataService = sportDataService;
            this.patientService = patientService;
            this.configService = configService;
            this.q = $q;
            this.moment = moment;

            this.promise = null;
            this.patient = null;
            this.config = {};
            this.model = {};
            this.versions = [];
            this.currentVersion = -1;

            this.dateFormat = system.date_format.js;
            this.guardianship_relations = GUARDIANSHIP_RELATIONS;
        }

        static get $inject() {
            return ["sportDataService", "patientService", "configService", "$q", "moment", "system"];
        }

        get majorityAge() {
            return _.get(this.config, "majority_age", 18);
        }

        $onInit() {
            this.handleAutoSave = _.mnDelay(() => this._handleAutoSave(), 750);

            this.maxDate = this.maxDate || null;
            this.patientId = this.patientId || null;

            this.getData();
        }

        $onChanges(obj) {
            if (_.has(obj, 'maxDate.currentValue')) {
                this.maxDate = _.get(obj, 'maxDate.currentValue');
                this.getData();
            }
        }

        getCategoryQuery() {
            if (!this.patient || !this.model.sport) return {};

            return {
                gender: {
                    $in: [this.patient.gender, "UNDEFINED", null]
                },
                sport: {
                    $in: [this.model.sport.id, null]
                }
            }
        }

        getPatient() {
            if (this.patient) {
                return this.q.resolve(this.patient);
            } else {
                return this.patientService.getMinimalPatient(this.patientId);
            }
        }

        getData() {
            const promises = [
                this.getPatient(),
                this.sportDataService.getSportFileHistory(this.patientId),
                this.sportDataService.getActiveSportFile(this.patientId, this.maxDate),
                this.configService.getByHttp('sport_data_config'),
            ];

            this.promise = this.q.all(promises)
                .then(data => {
                    this.patient = data[0]
                    this.versions = data[1];
                    this.model = data[2];
                    this.config = data[3];

                    this.categoryQuery = this.getCategoryQuery();

                    if (_.isEmpty(this.model) && this.versions.length > 0) {
                        this.currentVersion = this.versions.length - 1;
                        this._choose();
                    } else {
                        this.currentVersion = _.findIndex(this.versions, _.pick(this.model, 'id'));
                    }
                });
        }

        _handleAutoSave() {
            if (!this.model.sport) return false;

            if (this.currentVersion < 1 && this.model['created_at'] !== this.moment().format(this.dateFormat)) {
                this.model = _.chain(this.model)
                    .omit('id')
                    .assign({patient: {id: this.patientId}})
                    .value();
            }

            this.sportDataService.handleSportFile(this.model)
                .then(data => {
                    const file = _.pick(data, ['id', 'created_at']);
                    this.model = _.assign(this.model, file);
                    this.categoryQuery = this.getCategoryQuery();

                    this.versions = _.chain(this.versions)
                        .pushOrUpdate(file)
                        .orderBy(item => {
                            return this.moment(item.created_at, this.dateFormat).valueOf();
                        }, 'desc')
                        .value();

                    this.currentVersion = _.findIndex(this.versions, ['id', file.id]);
                });
        }

        _choose() {
            const version = _.get(this.versions, `[${this.currentVersion}].id`);

            this.promise = this.sportDataService.getSportFile(version)
                .then(data => this.model = data);
        }

    }

    module.exports = {
        bindings: {
            patientId: "<patient",
            maxDate: "<?",
            ngDisabled: "<?",
            readonly: "<?"
        },
        template: require('stand-alone/sport-data/components/sport-file/sport-file.tpl.html'), // or template
        controllerAs: "vm",
        controller: SportFileCtrl,
    };

})();
