/**
 * Created by amine on 19/07/2021.
 */
(function () {
    'use strict';

    const Editor = require("../classes/editor");
    const {
        getEditor
    } = require("../utils/editor-functions");

    class EditorManagerService {

        constructor($q, mnWebSocket, $translate, system, patientService, measureService, authService, configService, $mdDialog, moment) {
            this.services = {
                translate: $translate,
                dialog: $mdDialog,
                q: $q,
                ws: mnWebSocket,
                patientService: patientService,
                measureService: measureService,
                configService: configService,
                authService: authService,
                moment: moment,
                dateFormat: system.date_format.js,
                lang: system.lang.js
            };
        }

        static get $inject() {
            return [
                "$q", "mnWebSocket", "$translate", "system", "patientService", "measureService", "authService", "configService",
                "$mdDialog", "editorTemplateService", "moment"
            ];
        }

        createEditor(name, configKey, options, externalConfig) {
            const editor = new Editor(configKey, options, externalConfig, this.services);

            return editor.init();
        }

        getTinymce(name) {
            return getEditor(name);
        }

        destroyTinymce(name) {
            const editor = getEditor(name)
            if (editor) editor.remove();
        }

        getEditorInstance() {
            return null;
        }
    }

    module.exports = EditorManagerService;
})();
