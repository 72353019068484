/**
 * Created by Hp on 25/08/2017.
 */
/**
 * Created by Hp on 05/08/2017.
 */

(function () {

    'use strict';

    module.exports = focusMe;

    focusMe.$inject = [];

    function focusMe() {

        var directive = {
            restrict: "A",
            link: linkFunc,
        };

        function linkFunc(scope, element) {
            element[0].focus();

        };
        return directive;
    };
})()