/**
 * Created by Hp on 29/06/2017.
 */

(function () {
    "use strict";

    var dialog = {
        controller: ProviderSearchDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/provider-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };


    ProviderSearchDialogCtrl.$inject = ["$mdDialog"];

    function ProviderSearchDialogCtrl($mdDialog) {

        let vm = this;
        vm.$onInit = init;
        vm.close = $mdDialog.cancel;
        vm.selectRow = selectRow;

        function init() {
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-card-account-details',
                        label: 'view',
                        resource: 'provider',
                        action: 'get',
                        behavior: 'disable',
                        method: handleDetails
                    }
                ],
                multiple: []
            }

        }

        function selectRow(data) {
            $mdDialog.hide(data);
        }

        function handleDetails(row) {
            const dialog = _.assign({}, require('../dialogs/provider-dialog'), {
                targetEvent: null,
                locals: {
                    provider: _.cloneDeep(row),
                    readOnly: true
                },
                clickOutsideToClose: false,
                focusOnOpen: false
            });
            $mdDialog.show(dialog)
                .then(_.noop);
        }


    }

    module.exports = dialog;


})();
