/**
 * Created by BETALOS on 03/10/2016.
 */
(function () {
    'use strict';

    module.exports = VisitMainCtrl;
    const SUB_LINKS = require('parameters/json/visit-sub-links.json');
    const PROCEDURES_DIALOG = require('../dialogs/visit-financial-dialog');

    VisitMainCtrl.$inject = ["$transition$", "visitService", "authService", "$mdDialog", "patientService", "$q", "configService", "mnWebSocket"];

    function VisitMainCtrl($transition$, visitService, authService, $mdDialog, patientService, $q, configService, mnWebSocket) {
        let vm = this;
        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');

        vm.$onInit = init;
        vm.updateVisit = updateVisit;

        vm.showProcedures = showProcedures;

        function init() {
            if (!configService.isDental()) {
                _.remove(SUB_LINKS, ['key', 'DENTAL']);
                _.remove(SUB_LINKS, ['key', 'PERIODONTAL']);
            }

            if (!configService.hasMedicalCare()) {
                _.remove(SUB_LINKS, ['key', 'MEDICAL_CARE']);
            }

            if (!authService.aclHandler({action: "get", resource: "payment"})) {
                _.remove(SUB_LINKS, ['key', 'FINANCIAL_STATEMENT']);
            }

            if (!configService.hasForms()) {
                _.remove(SUB_LINKS, ['key', 'FORMS']);
            }

            vm.$onDestroy = destroy;

            let visitSubLinksSubscription = visitService.visitSubLinks.subscribe(handleSubLinks);

            $q.all([
                visitService.getVisit(currentVisit, currentPatient),
                patientService.getMinimalPatient(currentPatient),
                configService.get("visit_links_config", true)
            ]).then(doneCallback, error);

            function destroy() {
                visitSubLinksSubscription.unsubscribe();
            }
        }

        function updateVisit(field) {
            return visitService.updateVisit(vm.visit, field);
        }

        function error() {
            window.history.back();
        }

        function handleSubLinks(data) {
            vm.subLinks = _.chain(SUB_LINKS)
                .filter(item => !_.get(data, `["disabled"]["${item.key}"]`, false))
                .orderBy(item => _.get(data, `["order"]["${item.key}"]`))
                .value();
        }

        function doneCallback(data) {
            vm.visit = data[0];
            visitService.currentVisitSubject.next(vm.visit);
            visitService.currentPatientSubject.next(data[1]);
        }

        function showProcedures(ev) {
            $mdDialog.show(_.assign({}, PROCEDURES_DIALOG, {
                targetEvent: ev,
                locals: {
                    visit: currentVisit
                }
            })).then(done, _.noop);

            function done(data) {
                vm.visit = data;
                let fieldToUpdate = ['procedures', 'financial_status'];
                const deliveryDocument = _.get(vm.visit, "delivery_document", 0)

                if (_.get(deliveryDocument, "lines", []).length || _.get(deliveryDocument, "id"))
                    fieldToUpdate.push("delivery_document");

                updateVisit(fieldToUpdate)
                    .then(data => mnWebSocket.pub('dental_consultation.Dental.payments_update', {visit: data.id}, false));
            }
        }

    }

})();
