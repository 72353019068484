/**
 * Created by amine on 28/02/2020.
 */

(function () {

    'use strict';

    class ErrorsVisitDialog {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.title = this.title || "";
            this.visits = this.visits || [];
            this.content = this.content || "";
            this.subContent = this.subContent || "";
            this.icon = this.icon || "mdi-playlist-plus";
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: ErrorsVisitDialog,
        template: require("../views/errors-visit-dialog.tpl.html"),
    };


})();
