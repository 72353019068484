/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = OpeningBalanceCtrl;

    OpeningBalanceCtrl.$inject = ["$transition$", "$scope", "stockService", "system", "$state", "$q", "configService"];

    function OpeningBalanceCtrl($transition$, $scope, stockService, system, $state, $q, configService) {

        var vm = this;
        var currentOpeningBalance = $transition$.params('to')["openingBalanceId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidateOpeningBalance = invalidateOpeningBalance;
        vm.validateOpeningBalance = validateOpeningBalance;
        vm.save = save;

        function init() {
            vm.is_new = !_.isNil(currentOpeningBalance) ? false : true;
            vm.docType = 'OpeningBalance';
            $scope.$watch('vm.openingBalance.is_valid', readOnlyOpeningBalance);
            vm.modelName = 'OpeningBalance';

            vm.promises = [configService.get(["price_mode"], true)];
            if (!_.isNil(currentOpeningBalance)) vm.promises.push(stockService.getStockDocument(currentOpeningBalance, 'opening-balance'));
            else vm.openingBalance = {doc_date: moment().format(dateFormat), lines: []};
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentOpeningBalance)) vm.openingBalance = data[1];
                else vm.openingBalance.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.openingBalance = data;
            if (_.isNil(currentOpeningBalance)) $state.go('app.stock.opening-balance-form', {openingBalanceId: data.id}, {location: 'replace'});
        }

        function invalidateOpeningBalance() {
            return stockService.simpleInvalidate(vm.openingBalance.id, "opening-balance");
        }

        function validateOpeningBalance(data) {
            vm.openingBalance = data;
            vm.openingBalance.is_valid = true;
            return vm.save();
        }

        function readOnlyOpeningBalance() {
            vm.readOnly = (vm.openingBalance && vm.openingBalance.is_valid) ? vm.openingBalance.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.openingBalance, "opening-balance", 'OpeningBalance');
        }
    }
})
();
