/**
 * Created by BETALOS on 25/11/2016.
 */
(function () {

    'use strict';

    class OrderDialogCtrl {
        constructor($mdDialog, interfacingService, $scope) {
            this.scope = $scope;
            this.dialog = $mdDialog;
            this.service = interfacingService;

            this.requiredFields = [];
            this.selectedDrivers = [];
        }

        static get $inject() {
            return ["$mdDialog", "interfacingService", "$scope"];
        }

        $onInit() {
            this.devices = this.adaptData();
            this.scope.$watchCollection("vm.selectedDrivers", data => this.handleRequiredFields(data));

            this.promise = this.service.getPatientOrder(this.patient)
                .then(data => this.order = data);
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            return this.service.sendOrder(this.selectedDrivers, this.order)
                .then(data => this.dialog.hide(data));
        }

        adaptData() {
            return _.reduce(this.devices, (arr, item) => _.chain(item['device_active_configs'])
                    .map(i => this.handleConfigItem(item, i))
                    .concat(arr)
                    .value()
                , []);
        }

        handleRequiredFields(selectedDrivers) {
            this.requiredFields = _.chain(selectedDrivers)
                .reduce((f, item) => _.concat(f, item['required_fields']), [])
                .uniqBy('key')
                .value();
        }

        handleConfigItem(device, config) {
            let deviceAttr = _.pick(device, ['id', 'device_name']);
            return _.chain(config)
                .pick(['title', 'id_config', "required_fields"])
                .assign(deviceAttr)
                .value()
        }
    }


    module.exports = {
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: OrderDialogCtrl,
        template: require("../views/order-form-dialog.tpl.html"),
    };

})();
