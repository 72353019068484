/**
 * Created by Hp on 14/03/2017.
 */
(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/lots-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "$scope", "system", "lotService", "$q", "$translate"];

    function DialogCtrl($mdDialog, $scope, system, lotService, $q, $translate) {

        let vm = this;
        let dateFormat = system['date_format'].js;
        vm.cancel = $mdDialog.cancel;

        //*************************************************** in lot ***************************************************
        vm.addNew = addNew;
        vm.saveLot = saveLot;
        vm.checkLotUnique = checkLotUnique;
        vm.validQte = validQte;
        //************************************************** out lot ***************************************************
        vm.lotsByArticle = null;
        vm.getOutOrder = getOutOrder;
        vm.selectNew = selectNew;
        vm.getLotQte = getLotQte;
        vm.maxLotQte = maxLotQte;
        //***************************************  deposition receipt and return ****************************************
        vm.maxDepositionLotQte = maxDepositionLotQte;
        vm.getNonReturnedQte = lotService.getNonReturnedQte;
        vm.getConsignedNonReturnedQte = lotService.getConsignedNonReturnedQte;
        vm.getNonReceiptedQte = lotService.getNonReceiptedQte;
        vm.getDepositionLotQte = getDepositionLotQte;
        vm.selectDepositionLot = selectDepositionLot;
        //*************************************qte validation  global between out and in********************************
        vm.getLotQteByStore = lotService.getLotQteByStore;
        vm.currentLotStore = lotService.currentLotStore;
        vm.lotsQteSum = lotService.lotsQteSum;
        vm.maxQte = maxQte;
        vm.removeLine = removeLine;
        vm.lotWarn = lotService.lotWarn;
        vm.currentLotStoreIndex = currentLotStoreIndex;
        vm.selectConsignedLot = selectConsignedLot;
        vm.getConsignedLotQte = getConsignedLotQte;
        vm.maxConsignmentLotQte = maxConsignmentLotQte;
        vm.$onInit = init;
        vm.selectedLot = null;

        function init() {
            vm.exceptionQuery = {
                limit: 4,
                page: 1,
                order: "+code"
            };
            vm.options = [5, 10, 15];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            //************************************************** out lot ***************************************************
            loadLots();
        }

        //*************************************************** in lot ***************************************************
        function addNew() {
            vm.lots.push({
                stores: [{warehouse: vm.line.warehouse, qte: 1}],
                expiration_date: moment().format(dateFormat),
                article: vm.line.article
            });
        }

        function saveLot() {
            $mdDialog.hide(vm.lots);
        }

        function checkLotUnique(lot, idx) {
            lotService.checkLotUniqueness(lot, vm.line.article.id).then(function (data) {
                if (data == false || _.filter(vm.lots, {code: lot.code}).length > 1) $scope.lotForm['code_' + idx].$setValidity('unique', false);
                else $scope.lotForm['code_' + idx].$setValidity('unique', true);
            });
        }

        function validQte(obj, idx) {
            switch (vm.mvt_type) {
                case 'IN':
                case 'DP':
                case 'BR' && (vm.line.src_doc_cls != 'GoodDeposition'):
                    let query = {id: obj.id, new_qte: vm.getLotQteByStore(obj, vm.line)};
                    lotService.lotQteValidation(query).then(success);
                    break;
            }

            function success(data) {
                $scope.lotForm['in_qte_' + idx].$setValidity('valid_qte', data);
            }
        }

        function maxQte(idx) {
            let qte_total = _.sumBy(vm.lots, function (o) {
                if (_.indexOf(vm.lots, o) != idx) return vm.getLotQteByStore(o, vm.line);
            });
            let max_qte = !_.isNil(qte_total) ? vm.limit - qte_total : vm.limit;
            return max_qte;
        }

        //************************************************** out lot ***************************************************
        function loadLots() {
            if (!_.includes(['IN', 'DP', 'ASSEMBLY_SOURCE', 'DISASSEMBLY_LINES'], vm.mvt_type)) {
                let promises = [lotService.getActiveLot({
                    article_id: vm.line.article.id,
                    store_id: vm.line.warehouse.id
                })];

                if (vm.mvt_type == 'RETURN') promises.push(lotService.getDepositionLineLots(vm.line.src_doc_number, vm.line.code));
                else if (vm.mvt_type == 'ConsignmentReturn') promises.push(lotService.getConsignedLineLots(vm.line.src_doc_number, vm.line.code));
                else if (vm.mvt_type == 'BR' && vm.line.src_doc_cls == "GoodDeposition") promises.push(lotService.getDepositionLineLots(vm.line.src_doc_number, vm.line.code));
                else promises.push({})


                vm.promise = $q.all(promises).then(success);
            }

            function success(data) {
                switch (vm.mvt_type) {
                    case 'OUT':
                    case 'DISASSEMBLY_SOURCE':
                    case 'ASSEMBLY_LINES':
                    case 'Consignment':
                        vm.lotsByArticle = _.orderBy(_.filter(data[0], function (x) {
                            if (_.isEmpty(_.filter(vm.lots, {code: x.code}))) return x;
                        }), 'expiration_date', 'asc');
                        break;
                    case 'WarehouseExchange':
                        vm.lotsByArticle = _.orderBy(_.filter(data[0], function (x) {
                            if (_.isEmpty(_.filter(vm.lots, function (item) {
                                return item.lot.code == x.code;
                            }))) return x;
                        }), 'expiration_date', 'asc');
                        break;
                    case 'BR':
                        vm.depositonLineLots = _.filter(data[1], function (x) {
                            if (_.isEmpty(_.filter(vm.lots, {code: x.code})) && vm.currentLotStore(x, vm.line).warehouse.id == vm.line.warehouse.id && vm.getNonReturnedQte(x, vm.line) > 0) return x;
                        });
                        break;
                    case 'RETURN':
                        vm.depositonLineLots = _.filter(data[1], function (item) {
                            if (_.isEmpty(_.filter(vm.lots, {code: item.code})) && vm.currentLotStore(item, vm.line).warehouse.id == vm.line.warehouse.id && vm.getNonReceiptedQte(item, vm.line) > 0) return item;
                        });
                        break;
                    case 'ConsignmentReturn':
                        vm.consignedLineLots = _.filter(data[1], function (item) {
                            if (_.isEmpty(_.filter(vm.lots, {code: item.code})) && vm.currentLotStore(item, vm.line).warehouse.id == vm.line.warehouse.id && vm.getConsignedNonReturnedQte(item, vm.line) > 0) return item;
                        });
                        break;
                }
            }

        }


        function getOutOrder() {
            let order = 'expiration_date';
            switch (vm.line.article.stock_valuation) {
                case 'PMP':
                case 'FIFO':
                    order = 'created_at';
                    break;
                case 'FEFO':
                    order = 'expiration_date';
                    break;
                case 'LIFO':
                    order = '-created_at';
                    break;
                default :
                    order = 'created_at';
            }
            return order;
        }


        function selectNew(obj) {
            if (vm.mvt_type == 'WarehouseExchange') {
                if (_.isNil(_.find(vm.lots, function (item) {
                    return item.lot['code'] == obj.code;
                }))) vm.lots.push({lot: obj, transfer_qte: obj['lot_qte']});
            } else {
                let sold_qte = _.find(vm.currentLotStore(obj, vm.line)[vm.mvt_type == 'Consignment' ? 'consigned_quantities' : 'sold_quantities'], {
                    mvt_code: vm.mvt_code,
                    mvt_cls: vm.documentCls
                });
                if (_.isNil(sold_qte)) {
                    vm.currentLotStore(obj, vm.line)[vm.mvt_type == 'Consignment' ? 'consigned_quantities' : 'sold_quantities'].push({
                        qte: obj['lot_qte'],
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                }
                vm.lots.push(obj);
            }
            _.remove(vm.lotsByArticle, {code: obj.code});
        }

        function getLotQte(obj) {
            if (vm.mvt_type == 'WarehouseExchange') return obj.lot.qte;
            else {
                let res = _.find(vm.currentLotStore(obj, vm.line)[vm.mvt_type == 'Consignment' ? 'consigned_quantities' : 'sold_quantities'], {
                    mvt_code: vm.mvt_code,
                    mvt_cls: vm.documentCls
                });
                if (!_.isNil(res)) return res.qte;
            }
        }

        function maxLotQte(obj) {
            if (!_.isNil(obj)) {
                let qte_total = _.sumBy(vm.lots, function (o) {
                    if (o.code != obj.code) return getLotQte(o);
                });
                let max_qte = !_.isNil(qte_total) ? vm.limit - qte_total : vm.limit;

                let lot_max_qte = !_.isNil(lotService.getRemainingQuantity(_.has(obj, 'lot') ? obj.lot : obj, vm.line)) ? lotService.getRemainingQuantity(_.has(obj, 'lot') ? obj.lot : obj, vm.line) : vm.currentLotStore(_.has(obj, 'lot') ? obj.lot : obj, vm.line).qte;
                return lot_max_qte < max_qte ? lot_max_qte : max_qte;
            }
        }

        //*****************************************deposition receipt and return ***************************************
        function selectDepositionLot(obj) {
            switch (vm.mvt_type) {
                case 'BR':
                    let receipted_qte = _.find(vm.currentLotStore(obj, vm.line)['received_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    if (_.isNil(receipted_qte)) {
                        vm.currentLotStore(obj, vm.line)['received_quantities'].push({
                            qte: obj['lot_qte'],
                            mvt_code: vm.mvt_code,
                            mvt_cls: vm.documentCls
                        });
                    }
                    break;
                case 'RETURN':
                    let returned_qte = _.find(vm.currentLotStore(obj, vm.line)['returned_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    if (_.isNil(returned_qte)) {
                        vm.currentLotStore(obj, vm.line)['returned_quantities'].push({
                            qte: obj['lot_qte'],
                            mvt_code: vm.mvt_code,
                            mvt_cls: vm.documentCls
                        });
                    }
                    break;
            }
            vm.lots.push(obj);
            _.remove(vm.depositonLineLots, {code: obj.code});
        }

        function getDepositionLotQte(obj) {
            let res = null;
            switch (vm.mvt_type) {
                case 'BR':
                    res = _.find(vm.currentLotStore(obj, vm.line)['received_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    if (!_.isNil(res)) return res.qte;
                    break;
                case 'RETURN':
                    res = _.find(vm.currentLotStore(obj, vm.line)['returned_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    if (!_.isNil(res)) return res.qte;
                    break;
            }
        }

        // function getConsignmentLotQte(obj) {
        //     let res = _.find(vm.currentLotStore(obj, vm.line)['returned_quantities'], {
        //         mvt_code: vm.mvt_code,
        //         mvt_cls: vm.documentCls
        //     });
        //     if (!_.isNil(res)) return res.qte;
        // }


        function maxDepositionLotQte(obj) {
            let is_max = false;
            if (!_.isNil(obj)) {
                let qte_total = _.sumBy(vm.lots, function (o) {
                    if (o.code != obj.code) return getDepositionLotQte(o);
                });
                let max_qte = !_.isNil(qte_total) ? vm.limit - qte_total : vm.limit;

                let lot_max_qte = 0;
                switch (vm.mvt_type) {
                    case 'BR':
                        lot_max_qte = !_.isNil(vm.getNonReturnedQte(obj, vm.line)) ? vm.getNonReturnedQte(obj, vm.line) : vm.currentLotStore(obj, vm.line).qte;
                        is_max = lot_max_qte < max_qte ? lot_max_qte : max_qte;
                        break;
                    case 'RETURN':
                        lot_max_qte = !_.isNil(vm.getNonReceiptedQte(obj, vm.line)) ? vm.getNonReceiptedQte(obj, vm.line) : vm.currentLotStore(obj, vm.line).qte;
                        is_max = lot_max_qte < max_qte ? lot_max_qte : max_qte;
                        break;
                }
            }
            return is_max;
        }

        //*************************************qte validation  global between out and in********************************

        function removeLine(lot) {

            switch (vm.mvt_type) {
                case 'IN':
                case 'DP':
                case 'BR':
                case 'ASSEMBLY_SOURCE':
                case 'DISASSEMBLY_LINES':
                    if (vm.line.src_doc_cls != 'GoodDeposition') _.remove(vm.lots, {code: lot.code});
                    else {
                        _.remove(vm.currentLotStore(lot, vm.line)['received_quantities'], {
                            mvt_code: vm.mvt_code,
                            mvt_cls: vm.documentCls
                        });
                        _.remove(vm.lots, {code: lot.code});
                        vm.depositonLineLots.push(_.assign(lot, {lot_qte: null}));
                    }
                    break;
                case 'OUT':
                case 'DISASSEMBLY_SOURCE':
                case 'ASSEMBLY_LINES':
                    _.remove(vm.currentLotStore(lot, vm.line)['sold_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    _.remove(vm.lots, {code: lot.code});
                    vm.lotsByArticle.push(_.assign(lot, {lot_qte: null}));

                    break;
                case 'RETURN':
                    _.remove(vm.currentLotStore(lot, vm.line)['returned_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    _.remove(vm.lots, {code: lot.code});
                    // vm.depositonLineLots.push(_.assign(lot, {lot_qte: null}));

                    break;
                case 'Consignment':
                    _.remove(vm.currentLotStore(lot, vm.line)['consigned_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    _.remove(vm.lots, {code: lot.code});
                    // vm.depositonLineLots.push(_.assign(lot, {lot_qte: null}));

                    break;

                case 'WarehouseExchange':
                    _.remove(vm.lots, function (item) {
                        return item.lot.code == lot.lot.code;
                    });
                    vm.lotsByArticle.push(_.assign(lot.lot, {lot_qte: null}));

                case 'ConsignmentReturn':
                    _.remove(vm.currentLotStore(lot, vm.line)['consigned_return_quantities'], {
                        mvt_code: vm.mvt_code,
                        mvt_cls: vm.documentCls
                    });
                    _.remove(vm.lots, {code: lot.code});
                    // vm.depositonLineLots.push(_.assign(lot, {lot_qte: null}));

                    break;
            }
        }

        function currentLotStoreIndex(lot) {
            if (!_.isNil(lot)) return _.findIndex(lot['stores'], {warehouse: vm.line.warehouse});
            else return null
        }


        function selectConsignedLot(obj) {
            let returned_qte = _.find(vm.currentLotStore(obj, vm.line)['consigned_return_quantities'], {
                mvt_code: vm.mvt_code,
                mvt_cls: vm.documentCls
            });
            if (_.isNil(returned_qte)) {
                vm.currentLotStore(obj, vm.line)['consigned_return_quantities'].push({
                    qte: obj['lot_qte'],
                    mvt_code: vm.mvt_code,
                    mvt_cls: vm.documentCls
                });
                vm.lots.push(obj);
                _.remove(vm.consignedLineLots, {code: obj.code});

            }
        }

        function getConsignedLotQte(obj) {
            let res = _.find(vm.currentLotStore(obj, vm.line)['consigned_return_quantities'], {
                mvt_code: vm.mvt_code,
                mvt_cls: vm.documentCls
            });
            if (!_.isNil(res)) return res.qte;
        }

        function maxConsignmentLotQte(obj) {
            let is_max = false;
            if (!_.isNil(obj)) {
                let qte_total = _.sumBy(vm.lots, function (o) {
                    if (o.code != obj.code) return getConsignedLotQte(o);
                });
                let max_qte = !_.isNil(qte_total) ? vm.limit - qte_total : vm.limit;

                let lot_max_qte = !_.isNil(vm.getConsignedNonReturnedQte(obj, vm.line)) ? vm.getConsignedNonReturnedQte(obj, vm.line) : vm.currentLotStore(obj, vm.line).qte;
                is_max = lot_max_qte < max_qte ? lot_max_qte : max_qte;

            }
            return is_max;
        }

    }

    module.exports = dialog;
})();