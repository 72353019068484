/**
 * Created by Hp on 28/02/2017.
 */
(function () {

    'use strict';

    module.exports = purchaseService;

    purchaseService.$inject = ["$http", "$q", "mnWebSocket"];

    function purchaseService($http, $q, mnWebSocket) {
        var self = this;

        self.getOrderNonReceiptedLines = getOrderNonReceiptedLines;
        self.getDepositionNonReceiptedLines = getDepositionNonReceiptedLines;
        self.getDepositionNonReturnedLines = getDepositionNonReturnedLines;
        self.getReceiptNonInvoicedLines = getReceiptNonInvoicedLines;
        self.invalidateInvoice = invalidateInvoice;
        self.invalidatePriceRequest = invalidatePriceRequest;

        function getOrderNonReceiptedLines(number) {
            var deferred = $q.defer();
            mnWebSocket.call('stock.PurchaseForm.get_non_receipted_lines', {order_number: number}).then(success, error);
            function success(data) {
                deferred.resolve(data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getDepositionNonReceiptedLines(number) {
            var deferred = $q.defer();
            mnWebSocket.call('stock.GoodDeposition.get_non_receipted_lines', {deposition_number: number}).then(success, error);

            function success(data) {
                deferred.resolve(data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getDepositionNonReturnedLines(number) {
            var deferred = $q.defer();
            mnWebSocket.call('stock.GoodDeposition.get_non_returned_lines', {deposition_number: number}).then(success, error);
            function success(data) {
                deferred.resolve(data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }



        function getReceiptNonInvoicedLines(receipt_number) {
            var deferred = $q.defer();
            mnWebSocket.call('stock.PurchaseReceipt.get_non_invoiced_lines', {receipt_number: receipt_number}).then(success, error);
            function success(data) {
                deferred.resolve(data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function invalidateInvoice(invoice_id) {
            var deferred = $q.defer();
            var url = "/api/purchase-invoice/" + invoice_id + "/";
            $http.put(url, {is_valid: false})
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function invalidatePriceRequest(request_id) {
            var deferred = $q.defer();
            var url = "/api/price-request/" + request_id + "/";
            $http.put(url, {is_valid: false})
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }
    }
})();