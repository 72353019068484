/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const angular = require('angular');

    const visitService = require("./services/visitService");

    const visitProcedures = require("./components/visit-procedures");
    const payablePayments = require("./components/payable-payments");

    const VisitListCtrl = require("./controllers/VisitListCtrl");
    const VisitMainCtrl = require("./controllers/VisitMainCtrl");

    /*const CareSheetCtrl = require("./controllers/CareSheetCtrl");
    const CareSheetFormCtrl = require("./controllers/CareSheetFormCtrl");
    const CareSheetContainerCtrl = require("./controllers/CareSheetContainerCtrl");*/

    angular
        .module("medinet")

        .service('visitService', visitService)

        .component('mnVisitProcedures', visitProcedures)
        .component('mnPayablePayments', payablePayments)

        .controller('VisitMainCtrl', VisitMainCtrl)

        .controller('VisitListCtrl', VisitListCtrl);
    //.controller('CareSheetContainerCtrl', CareSheetContainerCtrl)
    //.controller('CareSheetCtrl', CareSheetCtrl)
    //.controller('CareSheetFormCtrl', CareSheetFormCtrl);


})();