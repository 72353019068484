/**
 * Created by Hp on 16/05/2017.
 */
(function () {
    'use strict';
    let mnDocumentNumber = {
        controller: MnDocumentLinesCtrl,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            disabled: "=mnDisabled",
            mnModel: "=mnModel",
            modelLabel: "=modelLabel"
        },
        template: tpl,
    };
    MnDocumentLinesCtrl.$inject = ["$attrs", "sequenceService", "$scope", "stockService", "$q", '$translate', 'mnWebSocket'];

    function MnDocumentLinesCtrl(attrs, sequenceService, $scope, stockService, $q, $translate, mnWebSocket) {
        let vm = this;
        vm.$onInit = init;
        vm.chooseNumber = chooseNumber;
        vm.chooseModel = chooseModel;
        let pkg = !_.isUndefined(attrs['pkg']) ? attrs['pkg'] : null;
        let label = attrs['mnLabel'];
        vm.field = attrs['mnField'] || 'number';

        function init() {
            vm.sequenceModels = [];
            $scope.$watch('vm.mnModel', function (newVal) {
                if (newVal) handleNumberWatch();
            });
            $scope.$on('$destroy', unSubscribe);
        }

        function unSubscribe() {
            mnWebSocket.unsub("shared.Sequence.change_next_value", '');
        }

        function handleNumberWatch() {
            let firstCall = true;
            let killWatch = $scope.$watch('vm.stockDocument', function (newVal) {
                if (firstCall && _.isUndefined(newVal)) {
                    firstCall = false;
                    return;
                } else {
                    if (!_.has(newVal, 'id')) {
                        loadSettings();
                    }
                    _.isFunction(killWatch) ? killWatch() : _.noop();
                }
            });

            function success(data) {
                if (!_.has(vm.stockDocument, 'id')) vm.stockDocument[vm.field] = data[0];
                vm.nonTakenNumbers = data[1];
                vm.sequenceModels = data[2];
            }
        }

        function loadSettings(name = null) {
            mnWebSocket.sub("shared.Sequence.change_next_value", '', (data) => {
                if (!_.has(vm.stockDocument, 'id') &&
                    _.get(data, 'name') == _.get(vm.stockDocument, 'sequence_name') &&
                    _.get(data, 'model_name') == vm.mnModel
                ) {
                    vm.stockDocument[vm.field] = _.get(data, 'next_value');
                }
            });

            let promises = [sequenceService.getAvailableSequence({
                _model: vm.mnModel,
                _package: pkg,
                _label: $translate.instant(label),
                _name: name
            }),
                stockService.getNonTakenNumbers(vm.mnModel, pkg, vm.field, name),
                sequenceService.getSequenceModels({_model: vm.mnModel, _package: pkg}),
                sequenceService.getDefaultSequenceModels({_model: vm.mnModel, _package: pkg})];
            vm.promise = $q.all(promises).then(success);

            function success(data) {
                if (!_.has(vm.stockDocument, 'id')) {
                    vm.stockDocument[vm.field] = data[0];
                }
                vm.nonTakenNumbers = data[1];
                vm.sequenceModels = data[2];
                if (!_.has(vm.stockDocument, 'id')) {
                    vm.stockDocument.sequence_name = data[3];
                }
            }
        }

        function chooseNumber(item) {
            vm.stockDocument[vm.field] = item[vm.field];
            vm.stockDocument.id = item.id;
            vm.stockDocument.sequence_name = _.get(item, 'sequence_name');
        }

        function chooseModel(m) {
            vm.stockDocument.sequence_name = m;
            vm.promise = $q.all([sequenceService.getAvailableSequence({
                _model: vm.mnModel,
                _package: pkg,
                _label: $translate.instant(label),
                _name: m
            }), stockService.getNonTakenNumbers(vm.mnModel, pkg, vm.field, m)]).then(data => {
                if (!_.has(vm.stockDocument, 'id')) vm.stockDocument[vm.field] = data[0];
                vm.nonTakenNumbers = data[1];
            });
        }

        function handleSequenceChange(ev) {
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        let mnDisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        let label = !_.isUndefined(attrs['mnLabel']) ? `translate-once="${attrs['mnLabel']}">` : ">{{ vm.modelLabel | translate }}";

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]')) $elem.addClass("flex-nogrow");
        return [
            '<md-input-container>',
            '<label ' + label + '</label>',
            '<input ng-model="vm.stockDocument[vm.field]" required readonly>',
            '<div ng-if="!vm.stockDocument.id && vm.nonTakenNumbers.length>0" class="mn-option-buttons flex-nogrow layout-row">',
            '<md-menu >' +
            '<md-button aria-label="Open menu"   class="md-icon-button" ' +
            'ng-click="$mdMenu.open($event)">',
            '<md-icon md-font-icon="mdi-dots-vertical" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-menu-content class="mn-doc-number-menu">',
            '<md-menu-item ng-repeat="item in vm.nonTakenNumbers track by $index">',
            '<md-button aria-label="Open menu"  ng-click="vm.chooseNumber(item)">',
            '<span ng-bind="item[vm.field]"></span>',
            '</md-button>',
            '</md-menu-item>',
            '</md-menu-content>',
            '</div>',
            '<div ng-if="!vm.stockDocument.id && vm.sequenceModels.length>1" class="mn-option-buttons flex-nogrow layout-row">',
            '<md-menu >' +
            '<md-button aria-label="Open menu"   class="md-icon-button" ' +
            'ng-click="$mdMenu.open($event)">',
            '<md-icon md-font-icon="mdi-cog" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-menu-content class="mn-doc-number-menu">',
            '<md-menu-item ng-repeat="m in vm.sequenceModels">',
            '<md-button aria-label="Open menu"  ng-click="vm.chooseModel(m)">',
            '<span ng-bind="m"></span>',
            '</md-button>',
            '</md-menu-item>',
            '</md-menu-content>',
            '</div>',
            '</md-input-container>',

        ].join('');
    }

    module.exports = mnDocumentNumber;
})();
