/**
 * Created by amine on 14/06/2021.
 */
(function () {

    'use strict';

    class SportFileDetailsCtrl {
        constructor(sportDataService, $translate) {
            this.sportDataService = sportDataService;
            this.translate = $translate;

            this.internalValue = {};

            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["sportDataService", "$translate"];
        }

        $onInit() {
            this.headerLabel = this.headerLabel || "sport_data.sub_header";
            this.hideHeader = this.hideHeader || false;
            this.disabled = this.disabled || false;
            this.readonly = this.readonly || false;
            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.internalValue) {
                this.internalValue = this.ngModelController.$viewValue;
            }
        }

        internalValueChanged() {
            this.ngModelController.$setViewValue(_.clone(this.internalValue));
            this.ngModelController.$commitViewValue();
        }

        clear() {
            this.internalValue = {};
            this.internalValueChanged()
        }
    }

    module.exports = {
        bindings: {
            headerLabel: "@?",
            hideHeader: "<?",
            categoryQuery: "<?",
            disabled: "<?ngDisabled",
            readonly: "<?ngReadonly"
        },
        require: {ngModelController: "ngModel"},
        template: require('stand-alone/sport-data/components/sport-file-details/sport-file-details.tpl.html'), // or template
        controllerAs: "vm",
        controller: SportFileDetailsCtrl,
    };

})();
