/**
 * Created by Hp on 08/03/2017.
 */


(function () {

    'use strict';

    var mnProvider = {
        controller: MnSelectDocumentController,
        controllerAs: "vm",
        bindings: {
            query: "=mnQuery",
            model: "=mnModel",
            disabled: "=mnDisabled",
            change: "<?mnChange",

        },
        template: tpl,
    };

    MnSelectDocumentController.$inject = ["$mdDialog", "$attrs"];

    function MnSelectDocumentController($mdDialog, $attrs) {

        let vm = this;
        let modalIcon = _.get($attrs, "modalIcon", null);
        let modalTitle = _.get($attrs, "modalTitle", null);
        let modalModel = _.get($attrs, "modalModel", null);

        vm.$onInit = init;
        vm.cancel = cancel;
        vm.showCheckTable = showCheckTable;
        vm.selectedChange = selectedChange;


        function init() {
        }

        function cancel() {
            vm.model = null;
            vm.selectedChange();
        }


        function showCheckTable() {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                locals: {
                    modalIcon: modalIcon,
                    modalTitle: modalTitle,
                    modalModel: modalModel,
                    hideSelectable: true,
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(function (data) {
                vm.model = data;
                selectedChange();
            });
        }

        function selectedChange() {
            _.isFunction(vm.change) ? vm.change(vm.model) : _.noop;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        let mndisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";

        return [
            '<md-input-container>',
            '<label translate-once="contract_number"></label>',
            '<input ng-model="vm.model" disabled>',
            '<div class="mn-option-buttons flex-nogrow layout-row">',
            '<md-button class="md-icon-button" ng-click="vm.showCheckTable()" aria-label="select"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<md-button ng-if="vm.model" class="md-icon-button"  ng-click="vm.cancel()" aria-label="cancel"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '</div>',
            '</md-input-container>'
        ].join('');
    }

    module.exports = mnProvider;

})();