/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    class ConsultationsListSummary {
        constructor(consultationService) {
            this.consultationService = consultationService;

            this.visit = this.visit || null;
            this.query = this.query || {};
            this.observable = this.observable || null;
            this.consultations = [];
        }

        static get $inject() {
            return ["consultationService"];
        }

        $onInit() {
            this.consultationService.getVisitConsultationList(this.visit)
                .then(data => {
                    this.consultations = data;

                    if (data.length > 0) {
                        this.observable.next(true);
                        this.observable.complete();
                    }
                });
        }
    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            visit: "=visit",
            query: "=",
            observable: "="
        },
        template: require("stand-alone/patient-summary/views/consultations-list.tpl.html"),
        controllerAs: "vm",
        controller: ConsultationsListSummary,
    }
})();