/**
 * Created by amine on 19/07/2021.
 */
(function () {
    const {DEFAULT_KEY_HANDLERS} = require("../utils/consts");

    function compile(editor, content) {
        content = editor.plugins['data_injector'].compile(content);
        editor.fire("keyup");

        return content;
    }

    function compileContent(editor) {
        return editor.plugins['data_injector'].compileContent();
    }

    function resetMenus(editor) {
        // _(this.theme.panel.find('toolbar buttongroup')[0].items())
        //     .filter({settings: {inserted: true}})
        //     .invokeMap("remove")
        //     .value();
    }

    function getModelContent(content) {
        let $content = $("<div />").append(content);

        $("mn-var", $content).each(() => {
            const elem = $(this);

            elem
                .removeAttr("contenteditable")
                .attr("dirty", false)
                .removeClass("e")
                .addClass("ne")
                .html(_getKey(elem.attr("key")) + "&nbsp;")
        });

        return $content.html();
    }

    function getEditor(name) {
        return window.tinymce.get(name);
    }

    function getModel(editor) {
        return getModelContent(editor.getBody().innerHTML);
    }

    function addMenus(editor, menus) {
        console.log(menus);
        //this.plugins['data_injector'].addMenus(menus);
    }

    function getKey(item, editor = null) {
        let keyHandlers = _.get(editor, "settings.keyHandlers", DEFAULT_KEY_HANDLERS);

        return `${keyHandlers.start}${_.isObject(item) ? item.key : item}${keyHandlers.end}`;
    }

    function itemsFetch(fetch, $q) {
        let result = null;

        return () => {
            if (result) return $q.when(result);
            else return fetch().then(items => (result = items));
        }
    }

    module.exports = {
        compile,
        compileContent,
        resetMenus,
        getModelContent,
        getModel,
        getEditor,
        addMenus,
        getKey,
        itemsFetch
    };
})();