/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var articleHistoric = {
        controller: articleHistoricCtrl,
        controllerAs: "vm",
        bindings: {
            articleId: "=articleId",
        },
        template: require('stock/views/article-historic.tpl.html'),
    };

    articleHistoricCtrl.$inject = ["$attrs", "$translate", "$scope", "articleService"];

    function articleHistoricCtrl(attrs, $translate, $scope, articleService) {

        var vm = this;
        vm.$onInit = init;
        vm.loadHistoric = loadHistoric;
        vm.historicType = !_.isUndefined(attrs['historicType']) ? attrs['historicType'] : null;

        function init() {
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            vm.options = [5, 10, 15];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            var firstCall = true;
            var killWatch = $scope.$watch('vm.articleId', function (newVal) {
                if (firstCall && _.isNil(newVal)) {
                    firstCall = false;
                    return;
                }
                else {
                    vm.historic={criteria:'ALL'};
                    vm.promise = articleService.getHistoric(vm.articleId, vm.historicType, null).then(loadSuccess);
                    _.isFunction(killWatch) ? killWatch() : _.noop();
                }
            });
        }

        function loadSuccess(data) {
            vm.historic_lines = data;
        }

        function loadHistoric() {
            switch (vm.historic.criteria) {
                case 'PROVIDER':
                    vm.promise = articleService.getHistoric(vm.articleId, vm.historicType, {
                        criteria: 'PROVIDER',
                        value: vm.historic['provider'].id
                    }).then(loadSuccess);
                    break;
                case 'LAST_INVENTORY':
                    vm.promise = articleService.getHistoric(vm.articleId, vm.historicType, {
                        criteria: 'LAST_INVENTORY',
                        value: null
                    }).then(loadSuccess);
                    break;
                case 'WAREHOUSE':
                    vm.promise = articleService.getHistoric(vm.articleId, vm.historicType, {
                        criteria: 'WAREHOUSE',
                        value: vm.historic['warehouse'].id
                    }).then(loadSuccess);
                    break;
                default :
                    vm.promise = articleService.getHistoric(vm.articleId,  vm.historicType, {
                        criteria: 'ALL',
                        value: null
                    }).then(loadSuccess);
            }
        }
    }

    module.exports = articleHistoric;
})();