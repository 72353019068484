/**
 * Created by amine on 09/06/2021.
 */
(function () {

    'use strict';

    // const PROCEDURE_GROUP_DIALOG = require('medical-care/dialogs/procedure-group-dialog');
    const ORGANIZATION_DIALOG = require('stand-alone/sport-data/dialogs/sport-organization/sport-organization-dialog');

    class SportDataSetupCtrl {
        constructor(sportDataService, configService, $q, $mdDialog, $translate, $mdToast) {
            this.q = $q;
            this.sportDataService = sportDataService;
            this.configService = configService;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.toast = $mdToast;

            this.config = null;
            this.defaultValues = null;

            this.selectedTabIndex = 0;
            this.selectedTab = null;
            this.organizations = [];
            this.organizationsList = null;

            this.updateDefault = _.mnDelay(() => this.submitDefault(), 750);

            this.simpleToast = this.toast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
        }

        static get $inject() {
            return ["sportDataService", "configService", "$q", "$mdDialog", "$translate", "$mdToast"];
        }


        $onInit() {
            this.q.all([
                this.sportDataService.getOrganizations(),
                //this.configService.getByHttp('sport_data_config'),
                this.q.when(
                    _.chain(this.configService.defaultValues)
                        .get("sport_data", {
                            sport: null,
                            field_type: null
                        })
                        .cloneDeep()
                        .value()
                )
            ]).then(data => {
                this.organizations = _.orderBy(data[0], ["is_national_team", "name"], ["desc", "asc"]);
                //this.config = data[1] || {};

                this.defaultValues = data[1];
            });
        }

        selectTab(tab) {
            this.selectedTab = tab;
        }

        handleOrganization(item, event) {
            this.dialog
                .show(_.assign({}, ORGANIZATION_DIALOG, {
                    targetEvent: event,
                    locals: {
                        model: _.cloneDeep(item)
                    }
                }))
                .then(data => _.pushOrUpdate(this.organizations, data), _.noop);
        }

        removeOrganization(item, event) {
            const confirm = this.dialog.confirm()
                .targetEvent(event)
                .ariaLabel('removeProcedure')
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'))
                .title(this.translate.instant('medical_care.remove_confirm'));

            this.dialog.show(confirm)
                .then(() => {
                    this.sportDataService.deleteOrganization(item);
                    this.organizations.splice(_.findIndex(this.organizations, ["id", item.id]), 1);
                }, _.noop);
        }

        submitDefault() {
            const default_values = _.cloneDeep(this.configService.defaultValues);
            default_values.sport_data = this.defaultValues;

            this.configService.set({default_values}, true)
                .then(() => {
                    this.configService.defaultValues = _.cloneDeep(default_values);
                    this.toast.show(this.simpleToast);
                });
        }
    }

    module.exports = SportDataSetupCtrl;

})();
