/**
 * Created by Hp on 10/07/2017.
 */
/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var mnTariffDetails = {
        controller: tariffDetailsCtrl,
        controllerAs: "vm",
        bindings: {
            model: "=mnModel",
            tariffTypes: "=mnTypes",
            tariffRules: "=mnRules"
        },
        template: require('stock/views/tariff-details.tpl.html'),
    };

    tariffDetailsCtrl.$inject = ["$element", "$attrs", "$translate", "$scope","$mdDialog"];

    function tariffDetailsCtrl($element, attrs, $translate, $scope,$mdDialog) {

        var vm = this;
        vm.$onInit = init;
        vm.handleRule = handleRule;
        vm.addDetails = addDetails;
        vm.showDetails = showDetails;
        vm.removeTariff = removeTariff;
        vm.removeDetail = removeDetail;

        function init() {
            vm.selectedTariff = -1;
        }

        function handleRule(tariff) {
            if (tariff.rule.type == 'boolean') {
                tariff.details = [
                    {
                        attribute: true,
                        value: 0
                    },
                    {
                        attribute: false,
                        value: 0
                    }
                ]
            }
        }

        function addDetails(tariff) {
            if (vm.selectedTariff != tariff) {
                showDetails(tariff)
            }
            tariff.details.push({
                born_inf: _.get(tariff,'rule.values.0.value',0),
                born_sup: 0,
                value: tariff.type == 'formula' ? "" :0
            })
        }

        function showDetails(tariff) {
            if (vm.selectedTariff == tariff) {
                vm.selectedTariff = -1;
            } else {
                vm.selectedTariff = tariff;
            }
        }

        function removeTariff(line, index) {
            line.tariffs.splice(index, 1);
        }


        function removeDetail(tariff, index) {
            tariff.details.splice(index, 1);

        }
    }

    module.exports = mnTariffDetails;
})();