/**
 * Created by Hp on 09/03/2017.
 */

(function () {
    'use strict';
    module.exports = providerService;
    providerService.$inject = ["mnWebSocket", "$http", "$q"];
    function providerService(mnWebSocket, $http, $q) {
        var self = this;
        self.getProviders = getProviders;
        self.saveProvider = saveProvider;
        self.deleteProvider = deleteProvider;
        self.getProvider = getProvider;
        self.checkProviderExistence = checkProviderExistence;
        self.checkProviderDependencies = checkProviderDependencies;
        self.getProviderObject=getProviderObject;

        function saveProvider(provider) {
            var deferred = $q.defer();
            var url = "/api/provider/";
            if (!_.isNil(provider.id)) url += provider.id + "/";

            $http.post(url, provider)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }
            function errorCallback(err) {
                deferred.reject(err);
            }
            return deferred.promise;
        }

        function deleteProvider(provider_id) {
            var deferred = $q.defer();
            var url = "/api/provider/" + provider_id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }
            function errorCallback(err) {
                deferred.reject(err);
            }
            return deferred.promise;
        }

        function getProvider(provider_id) {
            var deferred = $q.defer();
            var url = "/api/provider/" + provider_id + "/";
            $http.get(url)
                .then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }
            function error(err) {
                deferred.reject(err);
            }
            return deferred.promise;
        }

        function getProviders(query) {
            return mnWebSocket.call("stock.Provider.search_provider", query);
        }

        function checkProviderExistence(social_reason, provider_id) {
            return mnWebSocket.call('stock.Provider.existence', {
                social_reason: social_reason,
                provider_id: provider_id || null
            });
        }

        function checkProviderDependencies(provider_id) {
            return mnWebSocket.call('stock.Provider.check_provider_dependencies', {provider_id: provider_id});
        }

        function getProviderObject(social_reason) {
            return mnWebSocket.call('stock.Provider.get_provider', {social_reason:social_reason});
        }
    }
})();

