/**
 * Created by Hp on 11/03/2017.
 */
(function () {

    'use strict';

    module.exports = serialService;

    serialService.$inject = ["mnWebSocket", "$http", "$q"];

    function serialService(mnWebSocket, $http, $q) {
        var self = this;
        self.getActiveSerial = getActiveSerial;
        self.checkSerialUniqueness = checkSerialUniqueness;
        self.getAvailableSerials = getAvailableSerials;





        function getActiveSerial(article_id) {
            return mnWebSocket.call('stock.SerialNumber.get_article_available_serials', article_id);
        }

        function checkSerialUniqueness(serial, article_id) {
            return mnWebSocket.call('stock.SerialNumber.check_uniqueness', {serial: serial, article_id: article_id});
        }

        function getAvailableSerials(depot, article, mvtType,sourceDoc, line_uid=null){
            return mnWebSocket.call("stock.SerialNumber.get_available_serials", {depot, article,mvtType,sourceDoc, line_uid});

        }

    }
})();