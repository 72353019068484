
/**
 * Created by Hp on 10/05/2017.
 */
(function () {
    'use strict';

    module.exports = warehouseCtrl;

    warehouseCtrl.$inject = ["$mdDialog", "$mdToast", "$translate", "warehouseService"];

    function warehouseCtrl($mdDialog, $mdToast, $translate, warehouseService) {

        var vm = this;
        vm.reset = null;
        vm.$onInit = init;
        vm.addWarehouse = addWarehouse;

        function init() {
            vm.filter = {is_deleted: {$ne:true}};
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'warehouse',
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'warehouse',
                        action: 'delete',
                        behavior: 'disable',
                        method: deleteWarehouse
                    }
                ],
                multiple: []
            }

        }

        function goToEdition(obj) {
            showWarehouseDialog(null, obj);
        }

        function deleteWarehouse(obj) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_object_warning'))
                    .ariaLabel('delete_object_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                warehouseService.checkWarehouseDependencies(obj.id).then(checkSuccess);
            });
            function checkSuccess(data) {
                if (data == true) warehouseService.deleteWarehouse(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
                else showWarning();
            }
        }

        function showWarning() {
            var simpleToast = $mdToast.simple()
                .textContent($translate.instant('impossible_delete'))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function addWarehouse(ev) {
            showWarehouseDialog(ev, null);
        }

        function showWarehouseDialog(ev, warehouse) {
            const dialog = _.assign({}, require('../dialogs/warehouse-dialog'), {
                targetEvent: ev || null,
                locals: {
                    warehouse: _.cloneDeep(warehouse) || null,

                },
                clickOutsideToClose: false,
            });
            $mdDialog.show(dialog)
                .then(_.isFunction(vm.reset) ? vm.reset : _.noop);
        }


    }
})();