
/**
 * Created by Hp on 08/06/2017.
 */
(function () {

    'use strict';

    module.exports = arrowSelector;

    arrowSelector.$inject = [];

    function arrowSelector() {

        var directive = {
            restrict: 'A',
            priority: 0,
            link: linkFunc,
        };

        function linkFunc(scope, elem, attrs) {
            elem.bind('keydown', function (e) {
                var index = elem.closest('td').index();

                if (e.keyCode == 38) {
                    elem.closest('tr').prev().find('td').eq(index).find('input').focus();
                    return false;
                }
                if (e.keyCode == 40) {
                    elem.closest('tr').next().find('td').eq(index).find('input').focus();
                    return false;
                }
            });
        };
        return directive;
    };
})()