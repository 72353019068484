/**
 * Created by amine on 10/10/2016.
 */
(function () {
    'use strict';

    const SUMMARY_DIALOG = require("../dialogs/summary-dialog");

    class PatientSummary {
        constructor($mdDialog) {
            this.restrict = "A";
            this.dialog = $mdDialog;
        }

        static create() {
            return new PatientSummary(...arguments);
        }

        link(scope, element, attrs) {
            element.on("click", ev => {
                let initQuery = scope.$eval(attrs['initQuery']);
                let patient = scope.$eval(attrs['mnPatientSummaryLink']);

                if (_.isFunction(initQuery)) initQuery = initQuery();

                const dialog = _.assign({}, SUMMARY_DIALOG, {
                    targetEvent: ev,
                    locals: {patient, initQuery}
                });

                this.dialog.show(dialog);
            });
        }
    }

    PatientSummary.create.$inject = ["$mdDialog"];

    module.exports = PatientSummary.create;

})();