(function () {
    "use strict";

    var dialog = {
        controller: GenerateStockDocDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/generate-stock-doc-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    GenerateStockDocDialogCtrl.$inject = ["$mdDialog", "$scope", "stockService", "$translate", "warehouseService"];

    function GenerateStockDocDialogCtrl($mdDialog, $scope, stockService, $translate, warehouseService) {
        let vm = this;
        vm.cancel = cancel;
        vm.submit = submit;
        vm.handleValidate = handleValidate;
        vm.isAllValid = isAllValid;
        vm.$onInit = init;

        function init() {
            vm.docTypes = [{cls: 'StockMovement', apiUrl: 'movement', number_label: 'mvt_number'},
                {cls: 'DeliveryForm', apiUrl: 'delivery-form', number_label: 'delivery_number'},
                {cls: 'Consignment', apiUrl: 'consignment', number_label: 'consignment_number'}];
            vm.docType = vm.docTypes[0];
            if (_.get(vm.model, 'id')) {
                vm.promise = stockService.getStockDocument(_.get(vm.model, 'id'), vm.docType.apiUrl).then(data => {
                    vm.model = data;
                })
            } else {
                vm.promise = warehouseService.getDefault().then(function (data) {
                    _.forEach(vm.model.lines, (e) => {
                        e.warehouse = data;
                    })
                });
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function submit() {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.model = data;
        }

        function save() {
            return stockService.saveStockDocument(vm.model, vm.docType.apiUrl, vm.docType.cls);
        }

        function handleValidate() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .multiple(true)
                        .title('')
                        .textContent($translate.instant('validate_warning'))
                        .ariaLabel('validate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    vm.prevent_validate = true;
                    save().then(success);
                });
            }

            function success(data) {
                vm.promise = validate(data).then(refresh);
            }

            function refresh(data) {
                if (data) {
                    vm.model = data;
                    vm.prevent_validate = false;
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent($(document.body))
                            .clickOutsideToClose(true)
                            .multiple(true)
                            .title('')
                            .textContent($translate.instant('document_validate_impossible'))
                            .ariaLabel('document_validate_impossible')
                            .ok('Ok')
                    );
                    vm.prevent_validate = false;

                }

            }
        }

        function validate(data) {
            return stockService.validateMovement(_.get(data, 'id'), vm.docType.cls, vm.docType.cls == 'Consignment' ? vm.docType.cls : 'OUT');
        }

        function isAllValid() {
            return vm.model ? stockService.isAllValid(vm.model, vm.docType.cls == 'Consignment' ? vm.docType.cls : 'OUT', vm.docType.cls) : true;
        }

    }
})();
