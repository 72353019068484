/**
 * Created by amine on 12/11/2021.
 */

(function () {

    'use strict';

    const FIELDS_PARAMS = require('parameters/json/patient-fields-params.json');
    const LIST_DIALOG = require('shared/dialogs/list-dialog');

    class TechnicalFileSetupCtrl {
        constructor(configService, listService, $q, $mdDialog, $translate, $mdToast) {
            this.q = $q;
            this.configService = configService;
            this.listService = listService;
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.toast = $mdToast;

            this.selectedTab = "fields";
            this.fields = [];

            this.lists = [];
            this.currentList = null;
            this.selectedListItems = [];

            this.simpleToast = this.toast.simple()
                .textContent($translate.instant('exam_setup_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
        }

        static get $inject() {
            return ["configService", "listService", "$q", "$mdDialog", "$translate", "$mdToast"];
        }

        getLists() {
            return _.filter(this.fields, ["type", "custom-select"]);
        }

        $onInit() {
            this.q.all([
                this.configService.getByHttp('technical_file_fields')
            ]).then(data => {
                this.fields = data[0] || [];
                this.lists = this.getLists();
            });
        }

        selectTab(tab) {
            this.selectedTab = tab;
        }

        saveFields() {
            this.configService.set({technical_file_fields: this.fields}).then(() => {
                this.toast.show(this.simpleToast);
                this.lists = this.getLists();
            });
        }

        addNewField() {
            this.fields.push(_.cloneDeep(FIELDS_PARAMS.emptyField));
        }

        viewList(item) {
            this.currentList = item;

            this.listService
                .list("CustomList", false, {custom_slug: {'$eq': item.slug}})
                .then(data => this.selectedListItems = data);
        }

        orderList(order) {
            this.selectedListItems = _.orderBy(this.selectedListItems, item => _.chain(item.value).toLower().deburr().value(), order);
            this.endSort();
        }

        endSort() {
            const sortedItems = _.map(this.selectedListItems, (item, index) => {
                return _.assign({
                    order: index
                }, _.pick(item, 'id'));
            });

            this.listService.reorderList(sortedItems, "CustomList")
                .then(() => this.toast.show(this.simpleToast));
        }


        //dialog-options="{'custom_slug': field.slug,'parent_slug':field.parent_slug,'fields':vm.fields}"
        handleItem(item, ev) {
            this.dialog.show(
                _.assign(
                    {},
                    LIST_DIALOG,
                    {
                        template: require(`shared/views/list.custom-list.tpl.html`),
                        targetEvent: ev,
                        locals: {
                            list: _.cloneDeep(item),
                            mnLabel: this.currentList.label,
                            mnModel: "CustomList",
                            options: {
                                custom_slug: this.currentList.slug,
                                parent_slug: this.currentList.parent_slug,
                                fields: this.fields
                            }
                        },
                    }
                )
            ).then(data => this.selectedListItems = _.pushOrUpdate(this.selectedListItems, data), _.noop);
        }
    }

    module.exports = TechnicalFileSetupCtrl;

})();
