/**
 * Created by Hp on 11/03/2017.
 */
(function () {

    'use strict';

    module.exports = lotService;

    lotService.$inject = ["mnWebSocket", "$http", "$q"];

    function lotService(mnWebSocket, $http, $q) {
        var self = this;
        self.lotsQteSum = lotsQteSum;
        self.getLotQteByStore = getLotQteByStore;
        self.currentLotStore = currentLotStore;
        self.lotWarn = lotWarn;

        self.checkLotUniqueness = checkLotUniqueness;
        self.lotQteValidation = lotQteValidation;
        self.getActiveLot = getActiveLot;
        self.getRemainingQuantity = getRemainingQuantity;

        self.getNonReturnedQte = getNonReturnedQte;
        self.getConsignedNonReturnedQte = getConsignedNonReturnedQte;
        self.getNonReceiptedQte = getNonReceiptedQte;
        self.getDepositionLineLots = getDepositionLineLots;
        self.getConsignedLineLots = getConsignedLineLots;
        
        self.getAvailableLots = getAvailableLots;

        function lotsQteSum(line, documentType, lots, mvt_code, documentCls) {
            switch (documentType) {
                case 'IN':
                case 'DP':
                case  'ASSEMBLY_SOURCE':
                case 'DISASSEMBLY_LINES':

                    return _.sumBy(lots, function (lot) {
                        return self.getLotQteByStore(lot, line);
                    });
                    break;
                case 'WarehouseExchange':
                    return _.sumBy(lots, function (lot) {
                        return lot['transfer_qte'];
                    });
                    break;
                case 'OUT':
                case 'DISASSEMBLY_SOURCE':
                case 'ASSEMBLY_LINES':
                    var res = _.sumBy(lots, function (o) {
                        return _.sumBy(self.currentLotStore(o, line)['sold_quantities'], function (item) {
                            if (item.mvt_code == mvt_code && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                        })
                    });
                    return res;
                    break;
                case 'BR':
                    if (line.src_doc_cls != 'GoodDeposition') return _.sumBy(lots, function (lot) {
                        return self.getLotQteByStore(lot, line);
                    });
                    else {
                        _.sumBy(lots, function (lot) {
                            return self.getLotQteByStore(lot, line);
                        });
                        var res = _.sumBy(lots, function (o) {
                            return _.sumBy(self.currentLotStore(o, line)['received_quantities'], function (item) {
                                if (item.mvt_code == mvt_code && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                            })
                        });
                        return res;
                    }
                    break;

                case 'RETURN':
                    var res = _.sumBy(lots, function (o) {
                        return _.sumBy(self.currentLotStore(o, line)['returned_quantities'], function (item) {
                            if (item.mvt_code == mvt_code && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                        })
                    });
                    return res;
                    break;
                case 'Consignment':
                    var res = _.sumBy(lots, function (o) {
                        return _.sumBy(self.currentLotStore(o, line)['consigned_quantities'], function (item) {
                            if (item.mvt_code == mvt_code && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                        })
                    });
                    return res;
                    break;
                case 'ConsignmentReturn':
                    var res = _.sumBy(lots, function (o) {
                        return _.sumBy(self.currentLotStore(o, line)['consigned_return_quantities'], function (item) {
                            if (item.mvt_code == mvt_code && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
                        })
                    });
                    return res;
                    break;
            }
        }

        function getLotQteByStore(lot, line) {
            return self.currentLotStore(lot, line).qte;
        }

        function currentLotStore(lot, line) {
            if (!_.isNil(lot))    return _.find(lot['stores'], {warehouse: line.warehouse});
            else  return null
        }

        function lotWarn(line, lots) {
            return _.filter(lots, function (item) {
                    return self.currentLotStore(_.has(item, 'lot') ? item.lot : item, line).warehouse.id != line.warehouse.id;
                }).length > 0;
        }

        function getRemainingQuantity(lot, line) {
            var store = self.currentLotStore(lot, line);
            var sold_qts = _.sumBy(store['sold_quantities'], function (item) {
                if (!item['is_deleted']) return item.qte;
            })
            var consigned_qts = _.sumBy(store['consigned_quantities'], function (item) {
                if (!item['is_deleted']) return item.qte;
            })
            var consigned_return_qts = _.sumBy(store['consigned_return_quantities'], function (item) {
                if (!item['is_deleted']) return item.qte;
            })
            var return_qts = _.sumBy(store['returned_quantities'], function (item) {
                if (!item['is_deleted']) return item.qte;
            })
            return store.qte - (sold_qts + return_qts)-(consigned_qts - consigned_return_qts);
        }

        function lotQteValidation(query) {
            return mnWebSocket.call("stock.LotNumber.qte_validation", query);
        }

        function getActiveLot(query) {
            return mnWebSocket.call('stock.LotNumber.get_article_available_lots', query);
        }

        function checkLotUniqueness(lot, article_id) {
            return mnWebSocket.call('stock.LotNumber.check_uniqueness', {lot: lot, article_id: article_id});
        }

        function getNonReturnedQte(obj, line) {
            var returned_qte_sum = _.chain(self.currentLotStore(obj, line)['returned_quantities']).map('qte').sum().value();
            var receipted_qte_sum = _.chain(self.currentLotStore(obj, line)['received_quantities']).map('qte').sum().value();
            return self.currentLotStore(obj, line).qte - (returned_qte_sum + receipted_qte_sum)

        }
        function getConsignedNonReturnedQte(obj, line) {
            var returned_qte_sum = _.chain(self.currentLotStore(obj, line)['consigned_return_quantities']).map('qte').sum().value();
            return  self.currentLotStore(obj, line).qte - returned_qte_sum

        }

        function getNonReceiptedQte(obj, line) {
            var sold_qte_sum = _.chain(self.currentLotStore(obj, line)['sold_quantities']).map('qte').sum().value();
            var returned_qte_sum = _.chain(self.currentLotStore(obj, line)['returned_quantities']).map('qte').sum().value();
            var receipted_qte_sum = _.chain(self.currentLotStore(obj, line)['received_quantities']).map('qte').sum().value();
            if (sold_qte_sum <= receipted_qte_sum) return self.currentLotStore(obj, line).qte - (returned_qte_sum + receipted_qte_sum)
            else   return self.currentLotStore(obj, line).qte - (returned_qte_sum + receipted_qte_sum + (sold_qte_sum - receipted_qte_sum))

        }

        function getDepositionLineLots(deposition_number, line_code) {
            return mnWebSocket.call('stock.GoodDeposition.get_non_returned_lot_numbers', {
                _deposition_number: deposition_number,
                _line_code: line_code
            });
        }
        function getConsignedLineLots(consignment_number, line_code) {
            return mnWebSocket.call('stock.Consignment.get_non_returned_lot_numbers', {
                _consignment_number: consignment_number,
                _line_code: line_code
            });
        }
        
        function getAvailableLots(depot, article, mvtType,sourceDoc, line_uid=null) {
            return mnWebSocket.call("stock.LotNumber.get_available_lots", {depot, article,mvtType,sourceDoc,line_uid})

        }
    }
})();