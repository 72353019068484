/**
 * Created by amine on 11/09/2017.
 */
(function () {
    'use strict';

    class TableLegend {
        constructor() {
            this.priority = 0;
            this.restrict = "E"
        }

        static create() {
            return new TableLegend(...arguments);
        }

        compile(element, attrs) {
            element.addClass("legend-container").prepend(this.tpl());

            return (scope, element, attrs) => this.link(scope, element, attrs)
        }

        link(scope, element, attrs) {
            $("md-table-pagination", element).addClass("flex-noshrink").removeClass("flex");
            scope.includeTypes = scope.$eval(attrs.includeTypes)
        }

        tpl() {
            return `<div class="legend"> 
                       <div class="legend-group">
                           <div class="legend-item"> 
                                 <span class="round-span  in"></span>
                                 <span class="item-label" translate-once="mvt_in"></span>
                           </div>
                           <div class="legend-item">
                                <span class="round-span out"></span>
                                <span class="item-label" translate-once="mvt_out"></span>
                           </div>
                       </div>
                    </div>`;
        }
    }

    TableLegend.create.$inject = [];

    module.exports = TableLegend.create;

})();