/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    var articleSearchDialog = {
        controller: articleSearchDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/article-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    articleSearchDialogCtrl.$inject = ["$mdDialog"];

    function articleSearchDialogCtrl($mdDialog) {
        let vm = this;
        vm.$onInit = init;

        vm.cancel = $mdDialog.cancel;
        vm.selected = [];
        vm.addSelection = addSelection;
        vm.addNewArticle = addNewArticle;

        function init() {
            vm.selectable = !_.isNil(vm.selectable) ? vm.selectable : true;
            vm.noActions = !_.isNil(vm.noActions) ? vm.noActions : false;
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function addNewArticle(ev, is_medicine) {
            $mdDialog.show(_.assign(require('stock/dialogs/add-article-dialog'), {
                targetEvent: ev,
                locals: {
                    article: null,
                    is_medicine: is_medicine
                }

            })).then(success);

            function success(data) {
                _.isFunction(vm.reset) ? vm.reset : _.noop;
                vm.selected.push(data);
                $mdDialog.hide(vm.selected);
            };
        }
    }

    module.exports = articleSearchDialog;

})();