/**
 * Created by Hp on 09/05/2017.
 */

(function () {
    'use strict';

    module.exports = ArticleFormCtrl;

    ArticleFormCtrl.$inject = ["$q", "$state", "medicineService", "$scope", "$stateParams", "$mdToast", "$translate", "articleService", "familyService", "$mdDialog", "stockService", "configService"];

    function ArticleFormCtrl($q, $state, medicineService, $scope, $stateParams, $mdToast, $translate, articleService, familyService, $mdDialog, stockService, configService) {

        var vm = this;
        var currentArticle = $stateParams["articleId"];
        let duplicateObject = $stateParams['duplicateObject'];

        vm.$onInit = init;
        vm.getMedicines = medicineService.getMedicines;
        vm.submit = submit;
        vm.changeMedicine = changeMedicine;
        vm.checkCodeUniqueness = checkCodeUniqueness;
        vm.resetPicture = resetPicture;
        vm.cancel = cancel;
        vm.filterSubFamily = filterSubFamily;
        vm.selectComponent = selectComponent;
        vm.selectSubstitution = selectSubstitution;
        vm.onPaginateComponent = onPaginateComponent;
        vm.onPaginateSubstitution = onPaginateSubstitution;
        vm.onPaginateComplement = onPaginateComplement;
        vm.getSubTotal = getSubTotal;
        vm.removeLine = removeLine;
        vm.addCode = addCode;
        vm.codeChange = codeChange;
        vm.viewLineArticle = viewLineArticle;
        vm.duplicate = duplicate;
        vm.printCode = printCode;

        function init() {
            vm.editorConfigKey = "article_editor";
            vm.editorOpts = {};
            vm.allow_update = false;
            vm.componentQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            vm.substitutionQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            vm.complementaryQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            vm.options = [1, 5, 10, 20, 100];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };
            var promises = [familyService.getFamilies(), configService.get('store_config')];
            if (!_.isNull(currentArticle)) {
                promises.push(articleService.getArticle(currentArticle));
                promises.push(articleService.checkArticleDependencies(currentArticle));
            } else {
                if (duplicateObject) {
                    vm.article = _.omit(duplicateObject, ['id']);
                    vm.article.code = `${vm.article.code}#DUP`
                } else {
                    vm.article = {
                        store_min_qte: 0.0,
                        sale_price: 1.0,
                        sale_min_price: 1.0,
                        purchase_price: 1.0,
                        // stock_valuation: vm.store_config['stock_valuation'],
                        store_min_deposition: 0.0,
                        components: [],
                        substitutions: [],
                        complementary_codes: [],
                        is_stored: true
                    };
                }
            }

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                vm.families = data[0];
                vm.store_config = data[1];
                if (!_.isNull(currentArticle)) vm.article = data[2];
                else {
                    vm.article.stock_valuation = vm.store_config['stock_valuation']
                }
                vm.allow_update = !_.isNull(currentArticle) ? data[3] : true;
                // vm.allow_update = _.get(vm.article,'id') ? false: true;
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                if (!_.isNil(vm.article.family) && !_.has(vm.article.family, 'id')) {
                    familyService.saveFamily(vm.article.family).then(function (data) {
                        vm.article.family = data;
                        if (!_.isNil(vm.article.sub_family)) vm.article.sub_family.parent_family = data;
                        articleService.saveArticle(vm.article, vm.file).then(success.bind(with_cancel), error);
                    });
                } else articleService.saveArticle(vm.article, vm.file).then(success.bind(with_cancel), error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) $state.go('app.stock.article-list');
                else {
                    vm.article = data;
                    var toast_text = _.isNil(currentArticle) ? $translate.instant('article_save_success') : $translate.instant('edit_success');
                    if (vm.article.is_medicine) $state.go('app.stock.article-medicine-form', {articleId: data.id}, {replace: true});
                    else $state.go('app.stock.article-form', {articleId: data.id}, {replace: true});
                    var simpleToast = $mdToast.simple()
                        .textContent($translate.instant(toast_text))
                        .position("bottom left")
                        .hideDelay(1500);
                    $mdToast.show(simpleToast);
                }
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function changeMedicine(item) {
            articleService.changeMedicine(item, vm.article, vm.families);
        }

        function checkCodeUniqueness(code) {
            articleService.checkCodeExistence(code, vm.article.id).then(done);

            function done(data) {
                var code_valid = !_.isNil(data) ? false : true;
                $scope.ArticleForm['code'].$setValidity('unique', code_valid);
            }
        }

        function resetPicture(ev) {
            ev.stopPropagation();
            vm.file = null;
            vm.article.picture = null;
        }

        function cancel(ev) {
            $state.go('app.stock.article-list');
        }

        function filterSubFamily(item) {
            return (!_.isNil(vm.article.family) && !_.isNil(vm.article.family.id) && item.parent_family_id == vm.article.family.id) || item.parent_family != null;
        }

        function onPaginateComponent(page, limit) {
            vm.componentQuery = _.assign(vm.componentQuery, {page: page, limit: limit});
            getData('component');
        }

        function onPaginateSubstitution(page, limit) {
            vm.substitutionQuery = _.assign(vm.substitutionQuery, {page: page, limit: limit});
            getData('substitution');
        }

        function onPaginateComplement(page, limit) {
            vm.complementaryQuery = _.assign(vm.complementaryQuery, {page: page, limit: limit});
            getData('complement');
        }

        function getData(type) {
            switch (type) {
                case 'component' :
                    if (vm.article.components.length < ((vm.componentQuery.page - 1) * vm.componentQuery.limit)) {
                        vm.componentQuery.page = 1;
                    }
                    break;
                case 'substitution' :
                    if (vm.article.substitutions.length < ((vm.substitutionQuery.page - 1) * vm.substitutionQuery.limit)) {
                        vm.substitutionQuery.page = 1;
                    }
                    break;
                case 'complement' :
                    if (vm.article.complementary_codes.length < ((vm.complementaryQuery.page - 1) * vm.complementaryQuery.limit)) {
                        vm.complementaryQuery.page = 1;
                    }
                    break;
            }
        }

        function getSubTotal(line) {
            return (line.qte || 0.0) * (line.unit_price || 0.0);
        }

        function selectComponent(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack.bind(null, 'component'));

        }

        function selectSubstitution(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack.bind(null, 'substitution'));

        }

        function selectArticleCallBack(type, data) {
            _.forEach(data, function (item) {
                if (item.id != vm.article.id && _.isEmpty(_.filter(vm.article.components, {code: item.code}))) {
                    if (type == "component") vm.article.components.push({
                        code: item.code,
                        short_desc: item.short_desc,
                        qte: item.qte,
                        unit_price: item.unit_price,
                        article: item
                    });

                    if (type == "substitution") vm.article.substitutions.push(item);

                }
            })
        }

        function removeLine(idx, type) {
            switch (type) {
                case "component":
                    vm.article.components.splice((vm.componentQuery.page - 1) * vm.componentQuery.limit + idx, 1);
                    break;
                case  "substitution":
                    vm.article.substitutions.splice((vm.substitutionQuery.page - 1) * vm.substitutionQuery.limit + idx, 1);
                    break;
                case "complement":
                    vm.article.complementary_codes.splice((vm.complementaryQuery.page - 1) * vm.complementaryQuery.limit + idx, 1);
                    break;
            }
        }

        function addCode(ev) {
            vm.article.complementary_codes.push({code: ""});
        }

        function codeChange(code, index) {
            if (code == vm.article.code || _.filter(vm.article.complementary_codes, {code: code}).length > 1) {
                $scope.ArticleForm['code_' + index].$setValidity('unique', false);
            } else $scope.ArticleForm['code_' + index].$setValidity('unique', true);
        }

        function viewLineArticle(ev, article) {
            $mdDialog.show(_.assign(require('stock/dialogs/add-article-dialog'), {
                targetEvent: ev,
                locals: {
                    article: article,
                    is_medicine: article.is_medicine,
                    readOnly: true
                }
            }))
        }

        function duplicate() {
            $state.go('app.stock.article-form', {articleId: null, duplicateObject: vm.article}, {replace: true});

        }

        function printCode(ev) {
            articleService.printBarCodes([vm.article.id]).then(data => {
                _.noop();
            });
        }
    }
})();
