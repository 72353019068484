/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    class WorkflowContainerCtrl {
        constructor() {

        }

        static get $inject() {
            return [];
        }

        $onInit() {

        }

    }

    module.exports = WorkflowContainerCtrl;

})();
