/**
 * Created by BETALOS on 11/11/2016.
 */

(function () {

    'use strict';

    const DATETIME_FIELD = require('../views/date-time-field.tpl.html');

    class FormBlockFieldCtrl {
        constructor($scope, $element, $compile, $interpolate, moment, system) {
            this.scope = $scope;
            this.moment = moment;
            this.element = $element;
            this.compile = $compile;
            this.interpolate = $interpolate;

            this.body = {};
            this.field = {};
            this.timeFormat = system['time_format'].js;
            this.dateFormat = system['date_format'].js;
        }

        static get $inject() {
            return ["$scope", "$element", "$compile", "$interpolate", "moment", "system"];
        }

        $onInit() {
            this.readonly = _.isUndefined(this.readonly) ? false : this.readonly;
            this.element.addClass(`layout-row flex-${this.field.flex} flex-order-${this.field.order}`);
        }

        $postLink() {
            let tpl = "";

            if (_.eq(this.field.type, 'dateTime')) {
                this.body[`${this.field.key}_TIME`] = this.moment().format(this.timeFormat);
                this.body[`${this.field.key}_DATE`] = this.moment().format(this.dateFormat);

                tpl = DATETIME_FIELD;
            }

            tpl = _.isEmpty(tpl) ? tpl : this.interpolate(tpl)(this.field);

            let compiledDirective = this.compile(tpl);
            let directiveElement = compiledDirective(this.scope);
            this.element.html(directiveElement);

            if (this.element.is('[required]')) {
                $('input , md-datepicker, mn-number-container, textarea', this.element).attr('required', 'required');
            }
        }

    }

    module.exports = {
        template: '',
        bindings: {
            body: '=',
            field: '<',
            readonly: '<',
        },
        controllerAs: "vm",
        controller: FormBlockFieldCtrl,
    };

})();
