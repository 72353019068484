/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var stockDocActions = {
        controller: stockDocActionsCtrl,
        controllerAs: "vm",
        bindings: {
            handleRemove: "=remove",
            prePrint: "=prePrint",
            handleSave: "=handleSave",
            validate: "=validate",
            invalidate: "=invalidate",
            stockDocument: "=ngModel",
            documentType: "=documentType",
        },
        template: tpl,
    };

    stockDocActionsCtrl.$inject = ["$element", "$attrs", "$translate", "$scope", "stockService", "$mdDialog", "configService", "articleService"];

    function stockDocActionsCtrl($element, attrs, $translate, $scope, stockService, $mdDialog, configService, articleService) {

        var vm = this;
        vm.$onInit = init;
        vm.cancel = goBack;
        vm.handleSubmit = handleSubmit;
        vm.removeDocument = removeDocument;
        vm.handleInvalidate = handleInvalidate;
        vm.handleValidate = handleValidate;
        vm.handleConfirm = handleConfirm;
        vm.handleAnswers = handleAnswers;
        vm.printCodes = printCodes;
        var documentCls = !_.isUndefined(attrs['documentCls']) ? attrs['documentCls'] : null;
        var docPackage = !_.isUndefined(attrs['package']) ? attrs['package'] : null;

        function init() {
            vm.allow_stock_doc_confirm = false;
            vm.promise = configService.get('store_config')
                .then(data => {
                    vm.allow_stock_doc_confirm = _.get(data, 'allow_stock_doc_confirm', false);
                });
        }

        function handleSubmit(with_exit) {
            vm.handleSave['action'](with_exit);
        }

        function removeDocument() {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    vm.promise = stockService.deleteStockObject(vm.stockDocument.id, vm.handleRemove['viewName']).then(success);
                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            window.history.back();
        }

        function handleInvalidate() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    startInvalidate();
                });
            }

        }

        function startInvalidate() {
            vm.prevent_invalidate = true;
            vm.promise = vm.invalidate.action().then(refresh);


            function refresh(data) {
                if (data) {
                    vm.prevent_invalidate = false;
                    vm.stockDocument = data;
                    if (_.get(vm.validate, 'saveSuccess')) {
                        vm.validate.saveSuccess(data);
                    }
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent($(document.body))
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant('document_invalidate_impossible'))
                            .ariaLabel('document_invalidate_impossible')
                            .ok('Ok')
                    );
                    vm.prevent_invalidate = false;
                }
            }
        }

        function handleValidate() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validate_warning'))
                        .ariaLabel('validate_warning')
                        .ok('Ok')
                        .cancel('No')
                ).then(function () {
                    vm.prevent_validate = true;
                    vm.prePrint().then(success);
                });
            }

            function success(res) {
                vm.promise = vm.validate.action(res).then(refreshData);
            }

            function refreshData(data) {
                if (data) {
                    vm.stockDocument = data;
                    vm.prevent_validate = false;
                    if (_.get(vm.validate, 'saveSuccess')) {
                        vm.validate.saveSuccess(data);
                    }
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .parent($(document.body))
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant('document_validate_impossible'))
                            .ariaLabel('document_validate_impossible')
                            .ok('Ok')
                    );
                    vm.prevent_validate = false;

                }
            }
        }

        function handleConfirm() {
            vm.stockDocument.is_confirmed = true;
            handleSubmit(false);
        }

        function handleAnswers(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/price-request-response-dialog'), {
                targetEvent: ev,
                locals: {
                    lines: _.cloneDeep(vm.stockDocument.lines),
                    pk: _.cloneDeep(vm.stockDocument.id),
                    providers: _.cloneDeep(vm.stockDocument.providers),
                    responses: _.cloneDeep(vm.stockDocument.responses)
                }
            })).then(data => {
                if (data) {
                    vm.stockDocument.responses = data;
                }
            });
        }

        function printCodes(ev) {
            vm.promise = articleService.printBarCodes(_.chain(vm.stockDocument.lines).map('article.id').compact().value()).then(data => {
                _.noop();
            });
        }
    }

    tpl.$inject = ["$attrs", "$element"];

    function tpl(attrs, $elem) {
        var printGroup = 'group="' + attrs['printGroup'] + '"';
        var confirmAcl = '"mn-acl="' + {"action": "confirm", "resource": "exam", "behavior": "remove"} + '"'
        let addButton = attrs['documentCls'] == 'PriceRequest' ? true : false;

        return ['<div class="mn-module-actions">',
            '<md-button class="mn-square-icon-button md-raised md-warn" aria-label="delete"' +
            'ng-click="vm.removeDocument()"' +
            'ng-if="vm.handleRemove.if">',
            '<md-icon md-font-icon="mdi-delete" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<mn-print ng-if="vm.stockDocument.id" ' + printGroup + ' model="vm.stockDocument.id" pre-print="vm.prePrint()">',
            '</mn-print>',
            '<md-button ng-if="vm.stockDocument.id"  class="mn-square-icon-button md-raised md-primary" aria-label="print_code" ng-click="vm.printCodes($event)">',
            '<md-icon md-font-icon="mdi-barcode" md-font-set="mdi"></md-icon>',
            '<md-tooltip md-direction="bottom" md-delay="150">',
            '<span translate-once="print_bar_code"></span>',
            '</md-tooltip>',
            '</md-button>',
            '<span flex></span>',
            '<md-button class="md-raised md-warn flex-right" aria-label="cancel_model" ng-click="vm.cancel()"' +
            '>',//ng-if="!vm.stockDocument.is_valid"
            '<span translate-once="cancel"></span>',
            '</md-button>',
            '<md-button class="md-raised  mn-button-icon mn-success"  aria-label="add_answer" ' +
            'ng-if="vm.invalidate.if && ' + addButton + '" ' +
            'ng-click="vm.handleAnswers($event)">' +
            '<md-icon md-font-icon="mdi-playlist-plus" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="answers">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised md-primary" aria-label="invalidate" block-movement ng-if="vm.invalidate.if" ' +
            'ng-click="vm.handleInvalidate()" ng-disabled="vm.invalidate._disable">',
            '<md-icon md-font-icon="mdi-lock-open-outline" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="invalidate"></span>',
            '</md-button>',
            '<md-button class="md-raised  mn-button-icon mn-success" block-movement aria-label="validate" ' +
            'ng-if="vm.validate.if"' +
            'ng-disabled="vm.validate._disable || (vm.allow_stock_doc_confirm && !vm.stockDocument.is_confirmed)"' +
            'ng-click="vm.handleValidate()">' +
            '<md-icon md-font-icon="mdi-lock-outline" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="validate">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised  mn-button-icon mn-success" block-movement aria-label="confirm" ' +
            'ng-if="vm.validate.if && vm.allow_stock_doc_confirm && !vm.stockDocument.is_confirmed" ' + confirmAcl + ' ng-disabled="vm.validate._disable"' +
            'ng-click="vm.handleConfirm()">' +
            '<md-icon md-font-icon="mdi-bookmark-check" md-font-set="mdi">',
            '</md-icon>',
            '<span translate-once="confirm">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised md-primary" aria-label="save" ng-click="vm.handleSubmit(true)" ng-if="vm.handleSave.if"' +
            'ng-disabled="vm.handleSave._disable">',
            '<span translate-once="save_and_quit">',
            '</span>',
            '</md-button>',
            '<md-button class="md-raised md-primary mn-button-icon" aria-label="save" block-movement type="submit" ng-click="vm.handleSubmit()"' +
            'ng-if="vm.handleSave.if"' +
            'ng-disabled="vm.handleSave._disable">',
            '<span translate-once="save"></span>',
            '</md-button>',
            '</div>'].join('');
    }

    module.exports = stockDocActions;
})();
