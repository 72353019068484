(function () {

    'use strict';

    class PriceRequestAnswerDialogCtrl {
        constructor($mdDialog, $translate, system, stockService) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.dateFormat = this.system['date_format'].js;
            this.cancel = $mdDialog.cancel;
            this.stockService = stockService;
            this.$translate = $translate;
            this.providerFilter = this.providerFilter.bind(this);
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "stockService"];
        }

        $onInit() {
            this.autoFocus=true;
            this.response = {response_date: moment().format(this.dateFormat)};
            this.options = [5, 10, 20,50,100];
            this.query1 = {
                limit: 5,
                page: 1,
                order: "-code"
            };
            this.query2 = {
                limit:5,
                page: 1,
                order: "-code"
            };
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.initLines();

        }

        initLines() {
            this.lines = _.map(this.lines, (l) => {
                l.unit_price = _.chain(this.responses).find({uid: this.response.uid}).get('details', []).find({code: l.code}).get('unit_price', 0).value();
                return l;
            });
        }

        submit(withCancel = false) {
            this.response.details = _.map(this.lines, (l) => {
                return _.pick(l, 'code', 'unit_price')
            });
            if (_.has(this.response, 'uid')) {
                let idx = _.findIndex(this.responses, {uid: this.response.uid});
                this.responses[idx] = this.response;
            } else {
                this.responses.push(this.response);
            }
            this.stockService.updateStockDocument(this.pk, "price-request", {
                responses: this.responses
            }).then(data => {
                if (data) {
                    if (withCancel) {
                        this.$mdDialog.hide(_.get(data, 'responses'));
                    } else {
                        this.responses = _.get(data, 'responses');
                        this.response = this.response.uid ? this.response : _.last(this.responses) || {response_date: moment().format(this.dateFormat)};
                    }
                }
            });
        }

        providerFilter(p) {
            return _.find(this.providers, {id: p.id});
        }

        getProviderPrice(p, code) {
            return _.chain(this.responses).filter((r) => {
                return r.provider.id == p.id
            }).last().get('details').find({code: code}).get('unit_price', 0).value();
        }

        editResponse(item) {
            this.response = _.cloneDeep(item);
            this.initLines();
        }

        removeItem(idx) {
            this.responses.splice(idx, 1);
        }

        initResponse() {
            this.autoFocus=true;
            this.response = {response_date: moment().format(this.dateFormat)};
            this.initLines();
        }

        isPriceMin(p, code) {
            return this.getProviderPrice(p, code) == _.chain(this.responses).map('details')
                .flatten()
                .filter({code: code})
                .map('unit_price')
                .min()
                .value();
        }

        isPriceMax(p, code) {
            return this.getProviderPrice(p, code) == _.chain(this.responses).map('details')
                .flatten()
                .filter({code: code})
                .map('unit_price')
                .max()
                .value();
        }
        onPaginateResponses(page, limit) {
            this.query1 = _.assign(this.query1, {page: page, limit: limit});
            if (this.lines.length < ((this.query1.page - 1) * this.query1.limit)) {
                this.query1.page = 1;
            }
        }
        onPaginateLines(page, limit) {
            this.query2 = _.assign(this.query2, {page: page, limit: limit});
            if (this.lines.length < ((this.query2.page - 1) * this.query2.limit)) {
                this.query2.page = 1;
            }
        }
    }

    module.exports = {
        template: require("../views/price-request-response.tpl.html"),
        controllerAs: "vm",
        controller: PriceRequestAnswerDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false

    }

})();