const {Subject} = require('rxjs');
// const Worker = require("worker-loader?filename=ws.[contenthash].worker.js&esModule=false!core/workers/wsWorker.js");

// message related
const REGISTER = 0;
const ROOM = 1;
const OFFER = 2;
const ANSWER = 3;
const CANDIDATE = 4;
const HANGUP = 5;

const SDP_OFFER_OPTIONS = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
    voiceActivityDetection: false,
};

const CONFIGURATION = {
    iceTransportPolicy: "relay",
    rtcpMuxPolicy: "require",
    bundlePolicy: "max-bundle",
    iceServers: [
        {
            urls: [
                "turn:turn.ft-api.ma:12050?transport=udp",
                "turn:turn.ft-api.ma:12050?transport=tcp"
            ],
            username: 'ft-turn-user',
            maxRateKbps: "8000",
            credential: 'FireTurnUser@*1973'
        },
        {
            urls: [
                "stun:turn.ft-api.ma:12050"
            ],
            username: 'ft-turn-user',
            credential: 'FireTurnUser@*1973'
        }
    ]
};

export class Connection {
    constructor(room) {
        // connection with be
        this.id = null;
        this.room = room;
        this.remote = null;

        this.worker = null;
        this.trackSubject = new Subject();
        this.errorSubject = new Subject();
        this.fileReceived = new Subject();
        this.connectionStatusSubject = new Subject();
        this.wsUrl = `${location.protocol === 'https:' ? 'wss' : 'ws'}://${location.hostname}:${location.port}/video-call/`;

        // file related
        this.fileMeta = null;
        this.receivedSize = 0;
        this.receiveBuffer = [];
        this.fileReceiving = false;

        this.peerConnection = new RTCPeerConnection(CONFIGURATION);
        this.peerConnection.addEventListener('icecandidate', event => {
            if (event.candidate) this.sendIceCandidate(event.candidate);
        });
        this.peerConnection.addEventListener('connectionstatechange', (_) => {
            if (this.peerConnection.connectionState === 'disconnected') {
                this.connectionStatusSubject.next(false);
            }
        });

        this.peerConnection.addEventListener('track', event => this.trackSubject.next(event));
        this.peerConnection.addEventListener('datachannel', event => {
            event.channel.binaryType = 'arraybuffer';
            event.channel.addEventListener('message', event => this.dataChanelMessage(event));
        });

        const sendChannel = this.peerConnection.createDataChannel('sendDataChannel', {ordered: true});
        sendChannel.binaryType = 'arraybuffer';
        sendChannel.addEventListener('message', event => this.dataChanelMessage(event));
    }

    connect() {
        this.worker = new Worker(new URL('core/workers/wsWorker.js', import.meta.url)); // new Worker();
        this.worker.postMessage({cmd: 'connect', address: this.wsUrl});

        return new Promise((resolve) => {
            this.worker.addEventListener('message', ev => {
                switch (ev.data.cmd) {
                    case 'open':
                        return resolve(true);
                    case 'close':
                        this.errorSubject.next(3);
                        break;
                    case 'message':
                        this.receiveMessage(ev.data);
                        break;
                }
            });
        })
    }

    destroy() {
        this.peerConnection.close();
        if (this.id) this.sendHangup();
        if (this.worker) this.worker.terminate();

        this.trackSubject.complete();
        this.errorSubject.complete();
        this.connectionStatusSubject.complete();
    }

    // ws related
    receiveMessage(result) {
        const {type, body, error} = JSON.parse(result.data);

        switch (type) {
            case REGISTER:
                this.handleRegister(body, error);
                break;
            case ROOM:
                this.startCall(body);
                break;
            case OFFER:
                this.handleOffer(body);
                break;
            case ANSWER:
                this.handleAnswer(body);
                break;
            case CANDIDATE:
                this.handleCandidate(body);
                break;
            case HANGUP:
                this.handleHangup(body);
                break;
        }
    }

    // ws messages to send
    register() {
        const message = {type: REGISTER, body: {room_id: this.room, physician: true}};
        this.worker.postMessage({cmd: 'send', msg: JSON.stringify(message)});
    }

    sendOffer(offer) {
        const message = {
            type: OFFER,
            body: {room_id: this.room, connection_id: this.remote, remote_connection_id: this.id, offer}
        };
        this.worker.postMessage({cmd: 'send', msg: JSON.stringify(message)});
    }

    sendIceCandidate(candidate) {
        const message = {type: CANDIDATE, body: {room_id: this.room, connection_id: this.remote, candidate}};
        this.worker.postMessage({cmd: 'send', msg: JSON.stringify(message)});
    }

    sendAnswer(answer) {
        const message = {type: ANSWER, body: {room_id: this.room, connection_id: this.remote, answer}};
        this.worker.postMessage({cmd: 'send', msg: JSON.stringify(message)});
    }

    sendHangup() {
        const message = {type: HANGUP, body: {room_id: this.room, connection_id: this.remote, physician: true}};
        this.worker.postMessage({cmd: 'send', msg: JSON.stringify(message)});
    }

    // rtc related
    setStream(stream) {
        if (!this.id) this.register();
        stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
    }

    replaceStream(stream) {
        const videoTrack = stream.getVideoTracks()[0];

        const sender = this.peerConnection.getSenders().find(s => s.track.kind === videoTrack.kind);
        sender.replaceTrack(videoTrack).then();
    }

    handleRegister(body, error) {
        if (error) this.errorSubject.next(2);
        else this.id = body['connection_id'];
    }

    startCall(body) {
        this.remote = body['connection_id'];

        this.peerConnection.createOffer(SDP_OFFER_OPTIONS)
            .then(offer => {
                this.peerConnection.setLocalDescription(offer).then(() => this.sendOffer(offer));
            });
    }

    // rtc message
    handleOffer(body) {
        const {offer, remote_connection_id} = body;

        if (!this.remote) this.remote = remote_connection_id;

        const description = new RTCSessionDescription(offer);
        this.peerConnection.setRemoteDescription(description);
        this.peerConnection.createAnswer().then(answer => {
            this.peerConnection.setLocalDescription(answer).then(() => this.sendAnswer(answer));
        });
    }

    handleAnswer(body) {
        const {answer} = body;

        const description = new RTCSessionDescription(answer);
        this.peerConnection.setRemoteDescription(description);
    }

    handleCandidate(body) {
        const {candidate} = body;
        try {
            this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).then(_.noop, err => console.log(err));
        } catch (e) {
            console.log(e);
        }
    }

    handleHangup(_) {
        this.connectionStatusSubject.next(false);
    }


    // received file related
    dataChanelMessage(event) {
        if (this.fileReceiving) {
            this.receiveBuffer.push(event.data);
            this.receivedSize += event.data.byteLength;
        } else {
            this.fileReceiving = true;
            const response = new Response(event.data);
            response.text().then(text => this.fileMeta = JSON.parse(text));
        }

        if (this.fileMeta && this.receivedSize === this.fileMeta.size) {
            const blob = new Blob(this.receiveBuffer);
            const received = new File([blob], this.fileMeta.name, this.fileMeta);
            this.fileReceived.next(received);

            this.fileMeta = null;
            this.receivedSize = 0;
            this.receiveBuffer = [];
            this.fileReceiving = false;
        }
    }
}
