(function () {

    'use strict';
    class PurchaseRequestCtrl {
        constructor($transition$, $scope, stockService, $mdDialog, system, $state, $mdToast, $translate, purchaseService, configService, $q) {
            this.$transition$ = $transition$;
            this.system = system;
            this.scope = $scope;
            this.$state = $state;
            this.$q = $q;
            this.configService = configService;
            this.stockService = stockService;
            this.dateFormat = system['date_format'].js;
            this.currentOrder = this.$transition$.params('to')["orderId"];
            this.submit=this.submit.bind(this);
            this.invalidateOrder=this.invalidateOrder.bind(this);
            this.validateOrder=this.validateOrder.bind(this);
            this.removePatient=this.removePatient.bind(this);
            this.patientCallback=this.patientCallback.bind(this);
            this.save=this.save.bind(this);
            this.saveSuccess=this.saveSuccess.bind(this);
        }

        static get $inject() {
            return ["$transition$", "$scope", "stockService", "$mdDialog", "system", "$state", "$mdToast", "$translate", "purchaseService", "configService", "$q"];
        }

        $onInit() {
            this.docType = 'PR';
            this.modelName = 'PurchaseRequest';
            this.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            this.scope.$watch('vm.order.is_valid', this.readOnlyOrder.bind(this));
            if (!_.isNil(this.currentOrder)) {
                this.promise=this.stockService.getStockDocument(this.currentOrder, 'purchase-request').then(
                    data=> {
                    this.order = data;
                    });
            }
            else this.order = {is_urgent:false,price_mode:'HT',doc_date: moment().format(this.dateFormat), lines: []};

        }
        
        
        submit(with_cancel) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.promise = this.save().then(success.bind(this), error.bind(this));
            }

            function success(data) {
                this.prevent_save = false;
                if (with_cancel) window.history.back();
                else this.saveSuccess(data);
            }

            function error(err) {
                this.prevent_save = false;
            }
        }

        saveSuccess(data) {
            this.order = data;
            if (_.isNil(this.currentOrder)) this.$state.go('app.stock.purchase-request-form', {orderId: data.id}, {location: 'replace'});
        }
        invalidateOrder() {
            return this.stockService.invalidateMovement(_.get(this.order,'id'), 'PurchaseRequest', this.docType);
        }

        validateOrder(data) {
            this.order.is_valid=true;
            this.submit()
            return this.save();
        }
        
        readOnlyOrder() {
            this.readOnly = (this.order && this.order.is_valid) ? this.order.is_valid : false;
        }

        save() {
            return this.stockService.saveStockDocument(this.order, "purchase-request", 'PurchaseRequest');
        }

        removePatient() {
            this.order.patient = null;
        }

        patientCallback(patient) {
            this.order.patient = patient;
        }

        handleState(state) {
            this.stockService.updateStockDocument(this.order.id, "purchase-request", _.zipObject([state], [true]))
        }


    }

    module.exports = PurchaseRequestCtrl;
})();
