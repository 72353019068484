/**
 * Created by Hp on 10/03/2017.
 */


(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/serials-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "serialService", "$scope"];

    function DialogCtrl($mdDialog, serialService, $scope) {

        let vm = this;
        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.serialsSum = serialsSum;
        vm.checkSerialUnique = checkSerialUnique;
        vm.saveSerial = saveSerial;
        vm.selectNew = selectNew;
        vm.removeLine = removeLine;

        vm.getOutOrder = getOutOrder;
        vm.generateSerials = generateSerials;
        vm.serialWarn = serialWarn;

        function init() {
            vm.serialConfig = {step: 1};
            if (_.includes(['IN', 'BR', 'DP', 'ASSEMBLY_SOURCE', 'DISASSEMBLY_LINES'], vm.mvt_type)) {
                let add_length = vm.limit - _.size(vm.serials);
                if (add_length != 0) {
                    for (let i = 0; i < add_length; i++) {
                        vm.serials.push({warehouse: vm.lineWarehouse});
                    }
                }
                if (vm.limit < _.size(vm.serials)) vm.serials = _.slice(vm.serials, 0, vm.limit);
            }

            vm.promise = serialService.getActiveSerial(vm.article.id).then(getSuccess);

            function getSuccess(data) {
                if (_.includes(['OUT', 'RETURN', 'DISASSEMBLY_SOURCE', 'ASSEMBLY_LINES', 'WarehouseExchange', 'Consignment'], vm.mvt_type)) {
                    vm.serialsByArticle = _.filter(data, function (x) {
                        if (_.isEmpty(_.filter(vm.serials, {code: x.code})) && _.get(x,'warehouse.id') == _.get(vm.lineWarehouse,'id')) return x;
                    });
                    vm.selectedSerial= _.head(vm.serialsByArticle);
                }
            }
        }

        function checkSerialUnique(serial, idx) {
            serialService.checkSerialUniqueness(serial, vm.article.id).then(function (data) {
                if (data == false || _.filter(vm.serials, {code: serial.code}).length > 1) {
                    $scope.serialForm['code_' + idx].$setValidity('unique', false);
                } else $scope.serialForm['code_' + idx].$setValidity('unique', true);
            });
        }

        function serialsSum() {
            return _.size(vm.serials);
        }

        function generateSerials() {
            let next_value = vm.serialConfig.start
            for (let i = 0; i < vm.limit; i++) {
                vm.serials[i].code = (vm.serialConfig.prefix || '') + next_value;
                checkSerialUnique(vm.serials[i], i)
                let increment_val = (+next_value) + vm.serialConfig.step;
                let serial = (_.repeat('0', next_value.length) + increment_val).slice(-next_value.length);
                next_value = serial
            }
        }

        function saveSerial() {
            _.forEach(vm.serials, function (o) {
                o.article = vm.article;
            })
            $mdDialog.hide(vm.serials);
        }

        function selectNew(obj) {
            vm.serials.push(obj);
            _.remove(vm.serialsByArticle, {code: obj.code});
            vm.selectedSerial= _.head(vm.serialsByArticle);
        }

        function removeLine(idx) {
            let serial = vm.serials[idx];
            if (!_.isNil(serial)) {
                _.remove(vm.serials, {code: serial.code});
                vm.serialsByArticle.push(serial);
            }
        }

        function getOutOrder() {
            switch (vm.article.stock_valuation) {
                case 'PMP':
                case 'FEFO':
                case 'FIFO':
                    return 'created_at';
                    break;
                case 'LIFO':
                    return '-created_at';
                    break;
                default :
                    return 'created_at';
            }
        }


        function serialWarn() {
            return _.filter(vm.serials, function (item) {
                return item.warehouse.id != vm.lineWarehouse.id;
            }).length > 0;
        }

    }

    module.exports = dialog;
})();