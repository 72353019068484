(function () {
    'use strict';

    class ItemDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;

            this.model = this.model || null;
            this.forceDisabled = false;
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {

            this.http.post(`/api/business-detail/${this.model.id ? this.model.id + '/' : ''}`, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }

    const ITEM_DIALOG = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ItemDialogCtrl,
        focusOnOpen: false
    };


    class BusinessDetailCtrl {
        constructor($state, system, $mdToast, $translate, stockService, $mdDialog) {
            this.dialog = $mdDialog;
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.stockService = stockService;
            this.dateFormat = system['date_format'].js;
            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
        }

        static get $inject() {
            return ["$state", "system", "$mdToast", "$translate", "stockService", "$mdDialog"];
        }

        $onInit() {
            this.actions = {
                single: [{
                    icon: 'mdi-pencil',
                    label: 'edit',
                    resource: 'business-detail',
                    action: 'update',
                    behavior: 'remove',
                    method: this.editItem
                }, {
                    icon: 'mdi-delete',
                    label: 'delete',
                    resource: 'business-detail',
                    action: 'delete',
                    behavior: 'remove',
                    method: this.deleteItem
                }],
                multiple: []
            }
            this.toggle = false;
            this.filter = {is_deleted: {$ne: true}};
        }

        editItem(e = {doc_date: moment().format(this.dateFormat)}) {
            const dialogConfig = _.assign({}, ITEM_DIALOG, {
                targetEvent: null,
                template: require("stock/views/business-detail-dialog.tpl.html"),
                locals: {
                    model: _.cloneDeep(e)
                },
            });
            this.dialog.show(dialogConfig).then(this.reset);
        }

        deleteItem(e) {
            this.promise = this.stockService.deleteBusinessDetail(e.id).then(this.reset, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(this.$translate.instant('impossible_delete'))
                        .position("bottom left")
                        .hideDelay(1500)));
            });
        }
    }

    module.exports = BusinessDetailCtrl;

})()
