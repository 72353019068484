/**
 * Created by maria on 23/03/2017. [edited by amine 17/10/2019]
 */

(function () {

    'use strict';

    class PaymentConditionCtrl {
        constructor(configService, $mdDialog) {
            this.dialog = $mdDialog;
            this.configService = configService;

            this.ngModelController = this.ngModelController || null;
            this.items = [];
        }

        static get $inject() {
            return ["configService", "$mdDialog"];
        }

        $onInit() {
            this.configService.get('store_config').then(data => {
                this.items = _.chain(data).get('payment_conditions').toPairs().map((e) => {
                    return {key: e[0], value: e[1]}
                }).value();
            });

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, $attrs) {
        const ngRequired = $elem.is("[ng-required]") ? " ng-required='vm.required' " : "";
        const isRequired = $elem.is("[required]") ? " required " : "";
        const ngDisabled = !_.isUndefined($attrs['ngDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        const isDisabled = $elem.is('[disabled]') ? " disabled " : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        return `
            <md-input-container class="flex">
                <label translate-once="order_payment_deadline"></label>
                <md-select ng-model="vm.internalValue" style="min-width: 10px" ng-model-options="{ trackBy: \'$value.key\' }"
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                    <md-option ng-repeat="item in vm.items track by $index" ng-value="item">
                        <span ng-bind="item.key | translate"></span>
                    </md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${isDisabled} ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: PaymentConditionCtrl,
        require: {ngModelController: "ngModel"}
    }

})();