/**
 * Created by amine on 08/09/2017. edited by amine 26/07/2021
 */
(function () {

    'use strict';

    class FillCustomVarFormDialogController {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.data = {};
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(this.data);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: FillCustomVarFormDialogController,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/fill-custom-var.tpl.html"),
    };

})();