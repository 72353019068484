/**
 * Created by Hp on 01/03/2017.
 */
(function () {
    "use strict";

    var dialog = {
        controller: FamilyDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/family-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    FamilyDialogCtrl.$inject = ["$scope", "$mdDialog", "familyService","$q"];

    function FamilyDialogCtrl($scope, $mdDialog, familyService,$q) {
        let vm = this;
        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.hasChild = hasChild;
        vm.nameIsUnique = nameIsUnique;
        vm.codeIsUnique = codeIsUnique;
        vm.removeParent = removeParent;
        vm.submit = submit;

        function init() {
            vm.allow_update = false;

            vm.is_new = _.isNil(vm.family) ? true : false;
            var promises = [familyService.getFamilies()]

            if (!_.isNil(vm.family) && !_.isNil(vm.family.id)) promises.push(familyService.checkFamilyDependencies(vm.family.id))
            else promises.push({})
            vm.promise = $q.all(promises).then(success);


            function success(data) {
                vm.families = _.isNil(vm.family) ? data[0] : _.filter(data[0], function (o) {
                        if (_.toLower(o.name) != _.toLower(vm.family.name)) return o;
                    });

                vm.allow_update=!_.isNil(vm.family) && !_.isNil(vm.family.id) ? data[1] :true;
            }
        }

        function submit() {
            if (!_.isNil(vm.parent_family)) vm.family.parent_family = vm.parent_family;
            familyService.saveFamily(vm.family).then(success);
            function success(data) {
                vm.family = data;
                $mdDialog.hide(data);
            }
        }

        function hasChild() {
            familyService.checkFamilyChildren(vm.family.id || null).then(done);
            function done(data) {
                var res_valid = !_.isNil(data) ? data : true;
                $scope.familyForm['parent_family'].$setValidity('deptLimit', res_valid);
            }
        }

        function nameIsUnique(name) {
            familyService.checkNameExistence(name, vm.family.id).then(done);
            function done(data) {
                var res_valid = !_.isNil(data) ? false : true;
                $scope.familyForm['family_name'].$setValidity('unique', res_valid);
            }
        }
        function codeIsUnique(code) {
            familyService.checkNameExistence(code, vm.family.id, "code").then(done);
            function done(data) {
                var res_valid = !_.isNil(data) ? false : true;
                $scope.familyForm['code'].$setValidity('unique', res_valid);
            }
        }

        function removeParent() {
            vm.family.parent_family = null;
            $scope.familyForm['parent_family'].$setValidity('deptLimit', true);
        }

    }


    module.exports = dialog;

})();
