/**
 * Created by amine on 26/07/2017.
 */
(function () {
    'use strict';

    module.exports = {
        TechnicalFileSetupCtrl: require('./controllers/technical-file-setup/TechnicalFileSetupCtrl'),
        technicalFileService: require('./services/technicalFileService'),
        mnTechnicalFile: require('./components/technical-file/technical-file'),
        // mnSportFileDetails: require('./components/sport-file-details/sport-file-details')
    };
})();
