/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    class CertificatesListSummary {
        constructor(certificateService) {
            this.certificateService = certificateService;

            this.visit = this.visit || null;
            this.query = this.query || {};
            this.observable = this.observable || null;
            this.certificates = [];
        }

        static get $inject() {
            return ["certificateService"];
        }

        $onInit() {
            this.certificateService.getVisitCertificatesList(this.visit)
                .then(data => {
                    this.certificates = data;

                    if (data.length > 0) {
                        this.observable.next(true);
                        this.observable.complete();
                    }
                });
        }

        constructedTitle(item) {
            return this.certificateService.constructedTitle(item);
        }

    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            visit: "=visit",
            query: "=",
            observable: "="
        },
        template: require("stand-alone/patient-summary/views/certificates-list.tpl.html"),
        controllerAs: "vm",
        controller: CertificatesListSummary,
    }
})();