/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    const angular = require('angular');

    // controllers
    const DailyWorkflowCtrl = require("./controllers/daily-workflow/DailyWorkflowCtrl");
    const WorkflowContainerCtrl = require("./controllers/workflow-container/WorkflowContainerCtrl");

    // services
    const WorkFlowService = require("./services/WorkFlowService");

    angular
        .module("medinet")

        .controller('DailyWorkflowCtrl', DailyWorkflowCtrl)
        .controller('WorkflowContainerCtrl', WorkflowContainerCtrl)

        .service("WorkFlowService", WorkFlowService);

})();
