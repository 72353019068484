/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    class ExamsListSummary {
        constructor(examService) {
            this.examService = examService;

            this.visit = this.visit || null;
            this.query = this.query || {};
            this.observable = this.observable || null;
            this.exams = [];
        }

        static get $inject() {
            return ["examService"];
        }

        $onInit() {
            this.examService.getVisitExamList(this.visit)
                .then(data => {
                    this.exams = data;

                    if (data.length > 0) {
                        this.observable.next(true);
                        this.observable.complete();
                    }
                });
        }

    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            visit: "=visit",
            query: "=",
            observable: "="
        },
        template: require("stand-alone/patient-summary/views/exams-list.tpl.html"),
        controllerAs: "vm",
        controller: ExamsListSummary,
    }
})();