(function () {

    'use strict';

    const Draggable = require('draggable');
    const {Connection} = require('../../classes/connection');

    class VideoCallDialogCtrl {
        constructor($scope, $q, mdPanelRef, videoCallService, uploadService, mnWebSocket, $element, $mdToast, $translate) {
            this.q = $q;
            this.scope = $scope;
            this.element = $element;
            this.toast = $mdToast;
            this.dialog = mdPanelRef;
            this.translate = $translate;

            this.ws = mnWebSocket;
            this.uploadService = uploadService;
            this.videoCallService = videoCallService;

            // connection related
            this.callFolder = null;
            this.dateTimeFormat = 'DD-MM-YYYY HH:mm'; // system['datetime_format'].js;

            this.devices = [];
            this.localStream = null;
            this.remoteStream = new MediaStream();

            this.isFullScreen = false;
            this.isInitialized = false;
            this.isRecording = false;
            this.isAudioActive = true;
            this.isVideoActive = true;

            // draggable related
            this.draggable = null;

            this.miniVideo = $('#mini-video', this.element);
            this.localVideo = $('#local-video', this.element);
            this.remoteVideo = $('#remote-video', this.element);

            this.miniVideo.get(0).muted = true;
            this.localVideo.get(0).muted = true;
            this.remoteVideo.get(0).srcObject = this.remoteStream;

            this.remoteVideo.on('canplay', () => this.transitionToActive());
            this.remoteVideo.on('resize', () => this.setVideoDimension(this.remoteVideo));

            this.errorMsgs = [
                $translate.instant("video_call_unable_to_access_the_cameras"),
                $translate.instant("video_call_busy_room"),
                $translate.instant("video_call_unable_to_connect_to_server"),
            ];

            this.simpleToast = this.toast.simple()
                .position("bottom left")
                .hideDelay(2500);
        }

        static get $inject() {
            return [
                '$scope', '$q', 'mdPanelRef', 'videoCallService', 'uploadService', 'mnWebSocket', '$element', '$mdToast',
                '$translate'
            ];
        }

        $onInit() {
            this.room = this.room || null;
            this.folderId = this.folderId || null;
            this.patient = this.patient || null;

            this.connection = new Connection(this.room);
            this.setSubscriptions();

            this.promise = this.q.all([
                this.videoCallService.getDevices(), this.connection.connect(),
                this.videoCallService.getCallFolder(this.folderId, this.patient)
            ]).then(data => {
                this.callFolder = data[2];
                this.devices = data[0].filter(device => device.kind === 'videoinput');

                if (this.devices.length > 0) this.loadLocalDevice(0);
                else this.error(0);

                this.postLink();

                this.scope.$applyAsync();
            }, () => error(0));

            this.ws.sub("shared.upload.FileManager.file_converted", file => {
                if (!this.callFolder) return;
                const index = _.findIndex(this.callFolder.files, {id: file.id});

                if (index !== -1) {
                    this.callFolder.files.splice(index, 1, file);
                    this.scope.$applyAsync();
                }
            });
        }

        postLink() {
            const $panelEl = $('.video-call-dialog-container', this.element);

            this.draggable = new Draggable($panelEl.get(0), {
                useGPU: false,
                smoothDrag: false,
                setPosition: false,
                handle: $('.drag-button', $panelEl).get(0)
            });
        }

        destroy() {
            this.connection.destroy();
            this.ws.unsub("shared.upload.FileManager.file_converted");
            this.remoteStream.getTracks().forEach(track => track.stop());

            if (this.draggable) this.draggable.destroy();
            if (this.trackSubscription) this.trackSubscription.unsubscribe();
            if (this.errorSubscription) this.errorSubscription.unsubscribe();
            if (this.connectionSubscription) this.connectionSubscription.unsubscribe();
            if (this.fileReceivedSubscription) this.fileReceivedSubscription.unsubscribe();
            if (this.localStream) this.localStream.getTracks().forEach(track => track.stop());
        }

        setSubscriptions() {
            this.trackSubscription = this.connection.trackSubject.subscribe(event => {
                this.remoteStream.addTrack(event.track);
            });

            this.connectionSubscription = this.connection.connectionStatusSubject.subscribe(status => {
                if (!status) {
                    this.connection.destroy();
                    delete this.connection;

                    this.connection = new Connection(this.room);
                    this.promise = this.connection.connect().then(() => {
                        this.transitionToDeactivate();
                        this.connection.setStream(this.localStream);
                    });
                }
            });

            this.errorSubscription = this.connection.errorSubject.subscribe(error => this.error(error));
            this.fileReceivedSubscription = this.connection.fileReceived.subscribe(file => this.submitFile(file));
        }

        loadLocalDevice(index) {
            this.currentIndex = index;
            const device = this.devices[index];

            if (this.localStream) this.localStream.getVideoTracks().forEach(track => track.stop());

            this.promise = this.videoCallService.getStream(device)
                .then(stream => {
                    if (this.localStream) {
                        this._localStream = stream;
                        this.miniVideo.get(0).srcObject = this._localStream;
                        this.connection.replaceStream(this._localStream);
                    } else {
                        this.localStream = stream;
                        this.localVideo.get(0).srcObject = this.localStream;
                        this.localVideo.on('canplay', () => this.setVideoDimension(this.localVideo));

                        this.connection.setStream(stream);
                    }
                    this.scope.$applyAsync();
                }, () => error(0));
        }

        error(code) {
            this.simpleToast.textContent(this.errorMsgs[code]);
            this.toast.show(this.simpleToast);

            this.cancel();
        }

        cancel() {
            this.destroy();
            this.dialog.close();
        }

        // full screen related
        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
            $('.video-call-dialog-container', this.element).toggleClass('full-screen-call');

            if (this.isFullScreen && this.draggable) this.draggable.destroy();
            else {
                this.postLink();

                if (this.isInitialized) this.setVideoDimension(this.remoteVideo);
                else this.setVideoDimension(this.localVideo);
            }
        }

        // video Audio related
        toggleVideo() {
            this.localStream.getVideoTracks().forEach(track => {
                track.enabled = !track.enabled;
            });

            this.isVideoActive = !this.isVideoActive;
        }

        toggleAudio() {
            this.localStream.getAudioTracks().forEach(track => {
                track.enabled = !track.enabled;
            });

            this.isAudioActive = !this.isAudioActive;
        }

        switchCamera() {
            if ((this.currentIndex + 1) === this.devices.length) this.loadLocalDevice(0);
            else this.loadLocalDevice(this.currentIndex + 1);
        }

        // file related
        takePicture() {
            const deferred = this.q.defer();
            const mediaStreamTrack = this.remoteStream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(mediaStreamTrack);

            imageCapture.grabFrame().then(frame => {
                const canvasElement = document.createElement('canvas');
                const canvas2dContext = canvasElement.getContext('2d');

                canvasElement.width = frame.width;
                canvasElement.height = frame.height;
                canvas2dContext.drawImage(frame, 0, 0);
                canvasElement.toBlob((blob,) => {
                    const name = this.translate.instant('video_call_new_capture', {date: moment().format(this.dateTimeFormat)});
                    const file = new File([blob], name, {type: 'image/png'});
                    this.submitFile(file);

                    this.scope.$applyAsync();
                    deferred.resolve(true);
                });
            }, deferred.reject);

            return deferred.promise;
        }

        startRecording() {
            this.mediaRecorder.start();
        }

        stopRecording() {
            this.mediaRecorder.stop();
        }

        getRoomFolder() {
            return this.videoCallService.createCallFolder(this.room, this.patient);
        }

        submitFile(file) {
            if (this.callFolder) this.actualUpload(file);
            else this.getRoomFolder().then(folder => {
                this.callFolder = folder;
                this.actualUpload(file);
            });
        }

        actualUpload(file) {
            this.uploadService.upload([file], this.callFolder.id, null, this.callFolder.context, {})
                .then(folder => {
                    this.callFolder = folder;
                    this.ws.pub("shared.upload.FileManager.folder_notify", {patient_id: this.patient}, true);

                    this.scope.$applyAsync();
                });
        }

        openFile(file, event) {
            const visualizedFiles = _.filter(this.callFolder.files, {is_visualized: true, is_converted: true});
            const index = _.findIndex(visualizedFiles, {id: file.id});
            this.uploadService.visualizeFiles(visualizedFiles, index, event, false);
        }

        // video handling
        transitionToActive() {
            this.localVideo.hide();
            this.localVideo.get(0).srcObject = null;
            this.miniVideo.get(0).srcObject = this.localStream;

            this.miniVideo.addClass('active');
            this.remoteVideo.addClass('active');

            this.isInitialized = true;
            this.setVideoDimension(this.remoteVideo);

            // video record related
            let recordedBlobs = [];
            this.mediaRecorder = new MediaRecorder(this.remoteStream, {mimeType: 'video/webm;codecs=vp9'});
            this.mediaRecorder.addEventListener('stop', _ => {
                this.isRecording = false;
                const name = this.translate.instant('video_call_new_video', {date: moment().format(this.dateTimeFormat)});
                const blob = new Blob(recordedBlobs, {type: 'video/webm'});
                const file = new File([blob], name, {type: 'video/webm'});

                this.submitFile(file);
            });
            this.mediaRecorder.addEventListener('start', _ => this.isRecording = true);
            this.mediaRecorder.addEventListener('dataavailable', event => {
                recordedBlobs.push(event.data);
            });

            this.scope.$applyAsync();
        }

        transitionToDeactivate() {
            this.localVideo.show();
            this.miniVideo.get(0).srcObject = null;
            this.localVideo.get(0).srcObject = this.localStream;

            this.miniVideo.removeClass('active');
            this.remoteVideo.removeClass('active');

            this.remoteStream.getTracks().forEach(track => track.stop());

            this.isInitialized = false;
            this.remoteStream = new MediaStream();
            this.remoteVideo.get(0).srcObject = this.remoteStream;
            this.setSubscriptions();
            this.scope.$applyAsync();
        }

        setVideoDimension($el) {
            const videoWidth = $el.prop('videoWidth');
            const videoHeight = $el.prop('videoHeight');
            const isHeight = videoHeight < videoWidth;

            const dimension = this.calculateDimensionBase(videoHeight, videoWidth, isHeight)

            $el.css({'max-height': '', 'max-width': ''});
            $el.parent().css({'max-height': '', 'max-width': ''});
            $el.css(isHeight ? 'max-height' : 'max-width', dimension);

            setTimeout(() => {
                $el.parent().css(isHeight ? 'max-height' : 'max-width', dimension);
            }, 150);
        }

        calculateDimensionBase(h, w, isHeight) {
            return isHeight ? Math.floor(h * 300 / w) : Math.floor(w * 300 / h); // 300px is max width or height
        }
    }

    module.exports = {
        zIndex: 150,
        trapFocus: true,
        controllerAs: "vm",
        hasBackdrop: false,
        id: 'video-call-panel',
        bindToController: true,
        clickEscapeToClose: false,
        clickOutsideToClose: false,
        attachTo: $(document.body),
        propagateContainerEvents: true,
        groupName: ['video-call-group'],
        controller: VideoCallDialogCtrl,
        panelClass: 'video-call-dialog-container',
        template: require("./video-call.dialog.html"),
    };

})();
