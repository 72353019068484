/**
 * Created by amine on 04/09/2019 V2.0
 */

(function () {
    'use strict';

    const {Subject} = require("rxjs");

    class VisitSummaryCtrl {
        constructor() {
            this.show = false;
            this.dataObserver = new Subject();
            this.dataObserver.subscribe(() => this.notEmpty());
        }

        static get $inject() {
            return [];
        }

        $onInit() {
        }

        notEmpty() {
            this.show = true;
        }
    }

    module.exports = {
        bindings: {
            change: "&ngChange",
            visit: "=ngModel",
            query: "="
        },
        template: require("stand-alone/patient-summary/views/visit.tpl.html"),
        controllerAs: "vm",
        controller: VisitSummaryCtrl,
    };

})();
