(function () {
    "use strict";

    var dialog = {
        controller: CommentPreviewDialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/comment-preview.tpl.html"),
        parent: $(document.body),
        bindToController: true,
        clickOutsideToClose: false,
        focusOnOpen: false,
        multiple: true

    };

    module.exports = dialog;

    CommentPreviewDialogCtrl.$inject = ["$mdDialog", "system", "$translate", "$timeout"];

    function CommentPreviewDialogCtrl($mdDialog, system, $translate, $timeout) {
        let vm = this;
        vm.cancel = cancel;
        vm.$onInit = init;

        function init() {
            vm.has_comment=false;
            $timeout(function () {
                var elem =document.getElementById('comment-input').focus();

                if (elem) elem.focus();
            }, 300);
        }

        function cancel() {
            $mdDialog.hide(vm.has_comment);
        }
    }
})();
