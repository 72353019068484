/**
 * Created by Hp on 24/03/2017.
 */
(function () {

    'use strict';
    var mnDocumentDetails = {
        controller: MnDocumentDetailsController,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            documentCls: "=documentCls",
            documentType: "=documentType",
            readOnly: "=readOnly",
            viewHiding: "=viewHiding",
            noPagination: "=noPagination",
            fileContext: "=fileContext",
            viewName: "=viewName"
        },
        template: tpl,
    };

    MnDocumentDetailsController.$inject = ["$scope", "$translate", "$attrs", "$q", "system", "stockService"];

    function MnDocumentDetailsController($scope, $translate, attrs, $q, system, stockService) {

        var vm = this;

        var dateFormat = system['date_format'].js;
        var timeFormat = system['time_format'].js;
        var documentCls = !_.isUndefined(attrs['documentCls']) ? attrs['documentCls'] : null;

        vm.$onInit = init;
        // vm.newCommentClick = newCommentClick;
        vm.handleCommentClick = handleCommentClick;

        function init() {
            vm.comment={};
        }

        // function newCommentClick(ev) {
        //     ev.stopPropagation();
        //     if (!_.isEmpty(_.get(vm.comment,'content'))) {
        //         vm.comment = _.assign(vm.comment,{
        //             title: $translate.instant('comment_note') + moment().format(dateFormat),
        //             comment_date: moment().format(dateFormat),
        //             comment_time: moment().format(timeFormat),
        //         });
        //
        //         if (_.isEmpty(vm.stockDocument.comments)) vm.stockDocument.comments = [];
        //
        //         vm.stockDocument.comments = _.concat(vm.stockDocument.comments || [], vm.comment);
        //
        //         vm.comment=null;
        //
        //         if (!_.isNil(_.get(vm.stockDocument, 'id'))) {
        //             stockService.updateStockDocument(vm.stockDocument.id, vm.viewName, {comments: vm.stockDocument.comments}).then(_.noop());
        //         }
        //
        //     }
        // }
        function handleCommentClick(ev) {
            document.getElementById('comment-input').focus();
        }
    }

    tpl.$inject = ["$attrs"];

    function tpl(attrs) {

        var label = attrs['mnLabel'];
        return label == 'inventory' ? require('stock/views/inventory-lines.tpl.html') : require('stock/views/stock-document-details.tpl.html');
    }

    module.exports = mnDocumentDetails;
})();