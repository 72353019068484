/**
 * Created by BETALOS on 14/10/2016. Edited by amine on 04/10/2018
 */
(function () {

    'use strict';

    const SELECTED_CATALOG_KEY = "visit_procedure_catalog";

    class VisitFinancialCtrl {
        constructor($mdDialog, $translate, visitService, catalogService, $q, $element, $timeout, paymentService, $scope, configService, warehouseService) {
            this.dialog = $mdDialog;
            this.translate = $translate;
            this.visitService = visitService;
            this.catalogService = catalogService;
            this.$q = $q;
            this.$element = $element;
            this.$timeout = $timeout;
            this.paymentService = paymentService;
            this.warehouseService = warehouseService;
            this.$scope = $scope;

            this.advanceForm = configService.hasAdvanceVisitFinancial();
            this.selectedCatalog = null
        }

        static get $inject() {
            return [
                "$mdDialog", "$translate", "visitService", "catalogService", "$q", "$element", "$timeout", "paymentService", "$scope",
                "configService", "warehouseService"
            ];
        }

        $onInit() {
            this.visitProcedures = this.visitProcedures || [];
            this.visitInstance = this.visitInstance || null;
            this.visit = this.visit || null;

            const savedId = parseInt(localStorage.getItem(SELECTED_CATALOG_KEY)) || null;
            const promises = [
                this.visitService.getVisit(this.visit),
                this.visitService.getProcedures(),
                this.warehouseService.getDefault()
            ];
            if (!!savedId) promises.push(this.catalogService.getCatalog(savedId));

            this.promise = this.$q.all(promises)
                .then(data => {
                        this.visitInstance = data[0];
                        this.initProcedures = this.procedures = data[1];
                        this.visitProcedures = this.visitInstance.procedures;
                        this.selectedCatalog = data[3] || null;

                        if (this.advanceForm) {
                            const defaultWarehouse = data[2];
                            if (this.visitInstance.delivery_document) {
                                this.visitInstance.delivery_document.doc_date = this.visitInstance.delivery_document.doc_date || this.visitInstance.visit_date;
                                this.visitInstance.delivery_document.warehouse = this.visitInstance.delivery_document.warehouse || defaultWarehouse;
                            } else {
                                this.visitInstance.delivery_document = {
                                    price_mode: 'TTC',
                                    patient: this.visitInstance.patient_id,
                                    doc_date: this.visitInstance.visit_date,
                                    warehouse: defaultWarehouse,
                                    lines: []
                                }
                            }
                        }

                        this.catalogSelected();
                        this.$timeout(() => this.calculateHeight());
                    }
                );

            this.applyDiscount = this.paymentService.applyDiscount;

            this.$scope.$watch("vm.total()", value => {
                if (_.isNil(this.visitInstance) || _.get(this.visitInstance, 'financial_status.total') === value) return false;

                this.visitInstance.financial_status.gross_total = value;
                this.applyDiscount("value", this.visitInstance);

                this.$timeout(() => this.calculateHeight(), 300);
            });
        }

        cancel() {
            this.dialog.cancel();
        }

        addProcedure(procedure) {
            let existence = _.find(this.visitProcedures, {name: procedure.name, procedure: {id: procedure.id}});

            if (_.isUndefined(existence)) this.visitProcedures.push(this.procedureToVisit(procedure));
            else existence.qte += 1;

            this.$timeout(() => this.calculateHeight());
        }

        remove(index) {
            this.visitProcedures.splice(index, 1);
            this.$timeout(() => this.calculateHeight());
        }

        procedureToVisit(procedure) {
            let visitProcedure = _.pick(procedure, ['name', 'price']);
            return _.assign(visitProcedure, {qte: 1, discount: 0, total_discount: 0, procedure: procedure});
        }

        total(net = false) {
            let start = 0;
            if (this.visitInstance) {
                const deliveryDocumentTaxedTotal = _.get(this.visitInstance, "delivery_document.taxed_total", 0);
                const globalDiscount = this.visitInstance.financial_status.global_discount;
                start = net ? deliveryDocumentTaxedTotal - globalDiscount : deliveryDocumentTaxedTotal;
            }

            const t = _.reduce(this.visitProcedures, (result, item) => {
                return result + item['qte'] * (item['price'] - item['discount']);
            }, start);

            return _.isNaN(t) ? 0 : t;
        }

        procedureDiscountChange(item, field) {
            switch (field) {
                case "line":
                    item.total_discount = item.discount * item.qte;
                    break;
                default:
                    item.discount = item.total_discount / item.qte;
                    break;
            }

        }

        prePrint() {
            return this.visitService.updateVisit(this.visitInstance, ['procedures', 'financial_status']);
        }

        // empty
        emptyVisitDetail(ev) {
            let confirm = this.dialog.confirm()
                .multiple(true)
                .targetEvent(ev)
                .ariaLabel('empty visit financial warning')
                .ok(this.translate.instant('confirm'))
                .cancel(this.translate.instant('cancel'))
                .title(this.translate.instant('empty_financial_confirm_msg'));

            this.dialog.show(confirm).then(() => {
                this.visitProcedures = [];
                if (this.advanceForm) this.visitInstance.delivery_document.lines = [];
            });
        }

        calculateHeight() {
            const tableHeight = $("md-table-container", this.$element).height() + 45;
            const documentLinesHeight = this.advanceForm ? $("mn-document-lines", this.$element).height() + 62 : 0;

            this.height = {
                'max-height': (tableHeight + documentLinesHeight + 45) + 'px',
                'min-height': '182px'
            };
        }

        save() {
            if (this.visitInstance.financial_status.is_validated && this.total() < this.visitInstance.financial_status.paid_amount) {
                const alert = this.dialog.alert()
                    .parent(this.$element)
                    .clickOutsideToClose(true)
                    .multiple(true)
                    .title('')
                    .htmlContent(this.translate.instant('visit_financial_alert_msg'))
                    .ariaLabel('confirm')
                    .ok(this.translate.instant('ok'));

                this.dialog.show(alert);
            } else {
                this.dialog.hide(this.visitInstance);
            }
        }

        catalogSelected() {
            if (!!this.selectedCatalog) {
                this.procedures = _.cloneDeep(this.selectedCatalog.procedures);
            } else {
                this.procedures = this.initProcedures;
            }
            localStorage.setItem(SELECTED_CATALOG_KEY, _.get(this.selectedCatalog, "id", null));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: VisitFinancialCtrl,
        template: require("../views/visit-financial.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };
})();

