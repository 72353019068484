/**
 * Created by amine on 22/02/2017.
 */

(function () {
    'use strict';

    class PayablePaymentsCtrl {
        constructor(paymentService) {
            this.paymentService = paymentService;

            this.payments = [];
        }

        static get $inject() {
            return ["paymentService"];
        }

        $onInit() {
            //this.load();
            this.visit = this.visit || null;
            this.plan = this.plan || null;
            this.medicalPlan = this.medicalPlan || null;
        }

        $onChanges() {
            this.load();
        }

        load() {
            if (this.visit) {
                if (!_.isObject(this.visit)) return false;

                this.paymentService.visitPayments(this.visit)
                    .then(data => {
                        this.payments = _.reduce(data, (result, item) => {
                            item.main_detail = _.find(item.details, ['visit.id', this.visit.id]);
                            return _.concat(result, item);
                        }, []);
                    });
            } else if (this.plan) {
                if (!_.isObject(this.plan)) return false;

                this.paymentService.dentalTreatmentPlanPayments(this.plan)
                    .then(data => {
                        this.payments = _.reduce(data, (result, item) => {
                            item.main_detail = _.find(item.details, ['treatment_plan.id', this.plan.id]);
                            return _.concat(result, item);
                        }, []);
                    });
            } else if (this.medicalPlan) {
                if (!_.isObject(this.medicalPlan)) return false;

                this.paymentService.medicalPlanPayments(this.medicalPlan)
                    .then(data => {
                        this.payments = _.reduce(data, (result, item) => {
                            item.main_detail = _.find(item.details, ['medical_plan.id', this.plan.id]);
                            return _.concat(result, item);
                        }, []);
                    });
            }
        }
    }

    module.exports = {
        controller: PayablePaymentsCtrl,
        controllerAs: "vm",
        bindings: {
            visit: "<",
            plan: "<"
        },
        template: require('visit/views/payable-payments.tpl.html'),
    };
})();
