/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = PriceRequestFormCtrl;

    PriceRequestFormCtrl.$inject = ["$transition$", "$scope", "stockService", "$mdDialog", "system", "$state", "$mdToast", "$translate", "purchaseService"];

    function PriceRequestFormCtrl($transition$, $scope, stockService, $mdDialog, system, $state, $mdToast, $translate, purchaseService) {

        var vm = this;
        var currentPriceRequest = $transition$.params('to')["priceRequestId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;

        vm.submit = submit;
        vm.invalidatePriceRequest = invalidatePriceRequest;
        vm.validatePriceRequest = validatePriceRequest;
        vm.save = save;

        function init() {
            vm.is_new = !_.isNil(currentPriceRequest) ? false : true;
            vm.docType = 'PR';
            $scope.$watch('vm.priceRequest.is_valid', readOnlyPriceRequest);
            vm.modelName = 'PriceRequest';

            if (!_.isNil(currentPriceRequest)) vm.promise = stockService.getStockDocument(currentPriceRequest, 'price-request').then(success);
            else vm.priceRequest = {doc_date: moment().format(dateFormat), lines: []};
            function success(data) {
                vm.priceRequest = data;
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else  saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            if (_.isNil(currentPriceRequest)) $state.go('app.stock.price-request-form', {priceRequestId: data.id}, {location: 'replace'});
            vm.priceRequest = data;
        }

        function invalidatePriceRequest() {
            return purchaseService.invalidatePriceRequest(vm.priceRequest.id);
        }

        function validatePriceRequest(data) {
            return stockService.updateStockDocument(vm.priceRequest.id,  "price-request", {is_valid:true});

        }

        function readOnlyPriceRequest() {
            vm.readOnly = (vm.priceRequest && vm.priceRequest.is_valid) ? vm.priceRequest.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.priceRequest, "price-request", 'PriceRequest');
        }
    }
})
();
