/**
 * Created by BETALOS on 03/10/2016. edited by amine 20/02/2020
 */
(function () {

    'use strict';

    const {Subject, BehaviorSubject} = require("rxjs");

    class VisitService {
        constructor($q, $http, $stateParams, configService, mnWebSocket, stockService) {
            this.q = $q;
            this.http = $http;
            this.stateParams = $stateParams;
            this.configService = configService;
            this.ws = mnWebSocket;
            this.stockService = stockService;

            this.careSheetForm = new Subject();
            this.currentPatientSubject = new BehaviorSubject(null);
            this.currentVisitSubject = new BehaviorSubject(null);

            this.visitSubLinks = new BehaviorSubject(null);
            this.getSubLinksConfig = () => this._getSubLinksConfig();
        }

        static get $inject() {
            return ["$q", "$http", "$stateParams", "configService", "mnWebSocket", "stockService"];
        }

        getVisit(pk) {
            const deferred = this.q.defer();
            const url = `/api/visit/${pk}/`;
            const patient = this.stateParams['pId'];

            this.http.get(url)
                .then(response => {
                    if (patient && response.data.patient_id !== patient) deferred.reject(null);
                    else deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        getVisitOrDentalConsultation(visit_id) {
            return this.ws.call('visit.Visit.visit_or_dental', {visit_id});
        }

        getPatientVisits(patient, query) {
            const deferred = this.q.defer();
            const url = `/api/visit/?patient=${patient}${_.isNil(query.start) ? "" : `&start=${query.start}`}${_.isNil(query.end) ? "" : `&end=${query.end}`}`;

            this.http.get(url)
                .then((response) => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        updateVisit(visit, fields) {
            const deferred = this.q.defer();
            const url = `/api/visit/${visit.id}/`;
            const data = _.isNil(fields) ? visit : _.pick(visit, fields);
            const http = this.http[_.isNil(fields) ? 'post' : 'put'](url, data);

            http.then(response => {
                deferred.resolve(response.data);
            }, deferred.reject);

            return deferred.promise;
        }

        removeVisits(lists) {
            let ids = _.map(lists, 'id');
            return this.ws.call('visit.Visit.remove', ids);
        }

        privatizeVisits(lists) {
            return this.ws.call('visit.Visit.privatize', _.map(lists, 'id'));
        }

        unprivatizeVisits(lists) {
            return this.ws.call('visit.Visit.unprivatize', _.map(lists, 'id'))
        }

        getProcedures() {
            const deferred = this.q.defer();
            const url = '/api/procedure/';

            this.q.all([this.http.get(url), this.configService.get("procedure_sort", true)])
                .then(response => {
                    const data = _.isNil(response[1]) ? response[0].data : _.orderBy(
                        response[0].data, item => _.get(response, `[1].${item.id}`)
                    );

                    deferred.resolve(data);
                }, deferred.reject);

            return deferred.promise;
        }

        removeProcedures(procedure) {
            const url = `/api/procedure/${procedure.id}/`;
            return this.http.delete(url);
        }

        _getSubLinksConfig() {
            return this.configService.get("visit_links_config", true)
                .then(data => {
                    this.visitSubLinks.next(data);
                });
        }

        deliveryDocumentValidation(validate = true) {
            return (document) => {
                if (validate) return this.stockService.validateMovement(_.get(document, 'id'), "DeliveryForm", "OUT");
                else return this.stockService.invalidateMovement(_.get(document, 'id'), "DeliveryForm", "OUT");
            }
        }
    }

    module.exports = VisitService;
})();
