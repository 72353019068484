(function () {

    'use strict';

    class OrganizationDialogCtrl {
        constructor(sportDataService, $mdDialog, $translate) {
            this.sportDataService = sportDataService;
            this.dialog = $mdDialog;

            this.options = _.assign({}, {
                label: $translate.instant('medical_care.color'),
                okText: $translate.instant('select'),
                cancelText: $translate.instant('cancel'),
                openOnInput: false
            });
        }

        static get $inject() {
            return ["sportDataService", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.model = this.model || {};
        }

        submit() {
            this.sportDataService.saveOrganization(this.model)
                .then(data => this.dialog.hide(data), _.noop);
        }

        cancel() {
            this.dialog.cancel();
        }

        procedureChanged() {
            if (!this.model.title) {
                this.model.title = this.model.procedure.name;
            }
        }

        reasonChanged() {
            if (!this.model.color) {
                this.model.color = this.model.reason.color;
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: OrganizationDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./sport-organization-dialog.tpl.html"),
    };

})();
