/**
 * Created by Hp on 28/04/2017.
 */

(function () {

    'use strict';

    module.exports = StockDocListCtrl;

    StockDocListCtrl.$inject = ["$state", "$transition$", "$mdToast", "stockService", "$translate", "printService", "$mdDialog"];

    function StockDocListCtrl($state, $transition$, $mdToast, stockService, $translate, printService, $mdDialog) {

        var vm = this;

        var resourceName = $transition$.params('to')["resourceName"];
        var tableParameters = require('../json/stock-table.json');

        vm.$onInit = init;

        function init() {
            vm.reset = null;
            vm.filter = {is_deleted: {$ne: true}};
            vm.resourceName=resourceName;
            vm.stockTable = _.find(tableParameters, {label: resourceName});
            vm.documentCls=_.chain(vm.stockTable).get('tableModel').split('.').last().value();
            vm.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: resourceName,//vm.stockTable['actionsResource'],
                        action: 'update',
                        behavior: 'disable',
                        method: goToEdition
                    },
                    {
                        icon: 'mdi-comment-text-outline',
                        label: 'comment',
                        resource: resourceName,//vm.stockTable['actionsResource'],
                        action: 'comment',
                        behavior: 'disable',
                        method: addComment
                    },
                    {
                        icon: 'mdi-printer',
                        label: 'print',
                        resource: resourceName,//vm.stockTable['actionsResource'],
                        action: 'print',
                        behavior: 'disable',
                        method: printObject
                    }

                ],
                multiple:  []
            //     resourceName == 'movement' ? [
            //     {
            //         icon: 'mdi-file-multiple',
            //         label: 'merge',
            //         resource: 'resourceName', //vm.stockTable['actionsResource'],
            //         action: 'create',
            //         behavior: 'disable',
            //         method: mergeObjects
            //     }
            // ] :
            };
        }


        function goToEdition(obj) {
            var editionKey = _.zipObject([vm.stockTable['editionKey']], [obj.id]);
            $state.go(vm.stockTable.addState, editionKey);
        }

        function deleteObject(objs) {
            _.forEach(objs, function (obj) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('delete_warning'))
                        .ariaLabel('delete_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    !obj['is_valid'] ? stockService.deleteStockObject(obj.id, vm.stockTable['viewUrl'])
                        .then(_.isFunction(vm.reset) ? vm.reset : _.noop) : showWarning();
                });
            })
        }

        function showWarning() {
            var simpleToast = $mdToast.simple()
                .textContent($translate.instant('impossible_delete'))
                .position("top right")
                .hideDelay(3000);
            $mdToast.show(simpleToast);
        }

        function printObject(obj) {
            printService
                .printItem(vm.stockTable['printGroup'], obj.id, null, null);
        }

        function addComment(obj) {
            $mdDialog.show(_.assign({},require('../dialogs/comment-preview-dialog'), {
                stockDocument:obj,
                documentCls:vm.documentCls
            })).then(success);

            function success(comment) {
                // if (_.isEmpty(obj.comments)) obj.comments = [];
                // obj.comments.push(comment);
                // stockService.updateStockDocument(obj.id, vm.stockTable['viewUrl'], {comments: obj.comments}).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
            }
        }

        function mergeObjects(objs) {
            $mdDialog.show(_.assign(require('stock/dialogs/merge-documents-dialog'), {
                locals: {
                    documents: objs
                }
            })).then(success, (err) => {
                _.noop()
            });

            function success(data) {
                if (data) {
                }
            }
        }
    }

})();
