/**
 * Created by amine on 08/08/2018.
 */
(function () {
    'use strict';

    class EditorTemplateService {
        constructor($q, mnWebSocket, $http, $mdDialog) {
            this.$q = $q;
            this.mnWebSocket = mnWebSocket;
            this.$http = $http;
            this.$mdDialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http", "$mdDialog"];
        }

        saveTemplate(item) {
            let url = "/api/editor-model/";
            let deferred = this.$q.defer();

            if (!_.isNil(item.id)) url += `${item.id}/`;
            this.$http.post(url, item).then((response) => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }

        getTemplates() {
            const url = "/api/editor-model/";
            let deferred = this.$q.defer();

            this.$http.get(url).then((response) => deferred.resolve(response.data), deferred.reject)

            return deferred.promise;
        }
    }

    module.exports = EditorTemplateService;

})();