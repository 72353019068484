/**
 * Created by amine on 08/09/2017. edited 20/07/2021
 */

(function () {
    "use strict";

    const {getKey} = require("../utils/editor-functions");
    const BasePlugin = require("./base");
    const CUSTOM_VAR_DIALOG = require("../dialogs/custom-var");
    const FILL_CUSTOM_VAR_DIALOG = require("../dialogs/fill-custom-var");

    class DataInjector extends BasePlugin {

        constructor(editorInstance) {
            super(editorInstance, "data_injector");

            this.translate = this.editorInstance.translate;
            this.dialog = this.editorInstance.dialog;
            this.q = this.editorInstance.q;

            this.menus = [];
        }

        setMenus(menus) {
            let dynamicToolbar = "";

            if (!this.editorInstance.options.auto_compile) dynamicToolbar += "compile_content | ";

            menus.forEach(menu => {
                if (menu.dynamic) {
                    if (menu.show) {
                        dynamicToolbar += `${menu.title}_menu `
                        this.menus.push(menu);
                    }
                } else {
                    this.menus.push(menu);
                }
            });

            return dynamicToolbar;
        }

        initPlugin() {
            this.addIcon("format-page-break");
            this.addIcon("autorenew");
            this.addIcon("note-plus");

            //_.forEach(this.menus, menu => );
            this.menus.forEach(menu => this.#generateMenu(menu));

            this.addButton("blank_line", {
                tooltip: this.translate.instant("editor_blank_line"),
                onAction: event => this.#blank_line(event),
                icon: "format-page-break"
            });

            this.addButton("compile_content", {
                tooltip: this.translate.instant("editor_compile_content"),
                onAction: event => this.#compileContent(event),
                icon: "autorenew"
            });

            this.addButton("custom_variable", {
                tooltip: this.translate.instant("editor_custom_variable"),
                onAction: event => this.#addCustomVar(event),
                icon: "note-plus"
            });

            // ,
            //     this.options['has_physician_menu'],
            //     this.options['has_measurement_menu'],
            //     this.options['has_misc_menu']
        }

        compile(content) {
            if (this.tinymceEditor) return this.#compile(content);
        }

        compileContent() {
            if (this.tinymceEditor) return this.#compileContent();
        }

        addMenus(menus) {
            if (this.tinymceEditor) return this.#addMenus(menus);
        }

        #wrapVarStyle($mnVar) {
            let $parent = $mnVar.parent(".mn-var-style");
            if ($parent.length === 0) {
                $mnVar.wrap($("<span class='mn-var-style' />"));
                $parent = $mnVar.parent(".mn-var-style");
            }

            return $parent
        }

        handleMnVarClick() {
            const dom = this.tinymceEditor.iframeElement.contentDocument;

            $(dom).on("click", "mn-var.ne", event => {
                if (dom.getSelection && dom.createRange) {
                    const $parents = this.#wrapVarStyle($(event.currentTarget));

                    this.tinymceEditor.selection.select($parents[0]);
                }
            });
            $(dom).on("dblclick", "mn-var.ne.custom", event => {
                event.stopPropagation();
                let str_meta = $(event.currentTarget).attr("meta");

                this.dialog
                    .show(_.assign({}, CUSTOM_VAR_DIALOG, {
                        locals: {meta: JSON.parse(str_meta), selectedTab: 1},
                    }))
                    .then(meta => this.#getCustomVarMetaData(meta));
            });
        }

        #blank_line(event = null) {
            this.tinymceEditor.insertContent("<mn-nl />");
        }

        #asyncLoadMenus() {
            const deferred = this.q.defer();

            this.q.all(
                _.map(this.menus, menu => {
                    if (!menu.items) {
                        return menu.fetch();
                    } else {
                        return this.q.when(menu.items);
                    }
                })
            ).then(allItems => {
                this.menus = this.menus.map((menu, index) => {
                    return _.assign({}, menu, {
                        items: allItems[index]
                    });
                });

                deferred.resolve(true);
            });

            return deferred.promise;
        }

        #compile(content) {
            let deferred = this.q.defer();
            let $body = $(this.tinymceEditor.getBody());

            if (_.isNaN(content) || _.isNil(content) || _.isEmpty(content)) {
                setTimeout(() => {
                    deferred.reject();
                }, 500)
            } else {

                this.q.all([
                    this.#compileCustomVars($body),
                    this.#asyncLoadMenus()
                ]).then(() => {
                    const promises = _.chain($("mn-var[dirty=false],mn-var[refresh=true]", $body).not(".custom"))
                        .jqInvoke("attr", "key")
                        .uniq()
                        .map(varKey => this.#compileVars($body, varKey), [])
                        .value();

                    this.q.all(promises)
                        .then(() => {
                            deferred.resolve($body.html());
                        });
                });
            }

            return deferred.promise;
        }

        #addCustomVar(event = null) {
            this.dialog
                .show(_.assign({}, CUSTOM_VAR_DIALOG, {targetEvent: event, locals: {}}))
                .then(data => this.#getCustomVarMetaData(data))
        }

        #getCustomVarMetaData(customVar) {
            const varHTML = `<mn-var key="${customVar.key}" title='${customVar.label}' meta='${JSON.stringify(customVar)}' class="custom ne">${this.#getKey(customVar)}</mn-var>&nbsp;`
            this.#caretToTheEnd();

            this.tinymceEditor.execCommand("mceInsertContent", false, varHTML);
            this.tinymceEditor.fire("keyup");
        }

        #compileCustomVars($body) {
            const deferred = this.q.defer();

            const metas = _($("mn-var.custom[dirty=false]", $body))
                .jqInvoke("attr", "meta")
                .uniq()
                .map(JSON.parse)
                .value();

            if (_.isEmpty(metas)) {
                deferred.resolve(false);
            } else {
                this.dialog
                    .show(_.assign({}, FILL_CUSTOM_VAR_DIALOG, {
                        targetEvent: $.Event("click"),
                        locals: {metas: metas}
                    }))
                    .then(data => {
                        _.chain($("mn-var.custom[dirty=false]", $body))
                            .jqInvoke("attr", "key")
                            .uniq()
                            .map(varKey => this.#compileSingleCustomVar($body, varKey, data))
                            .value();


                        deferred.resolve(true);
                    }, deferred.resolve);
            }

            return deferred.promise;
        }

        #findItem(key) {
            // Array.some instead of Array.forEach to have the ability to break.
            // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
            let item = null;
            this.menus.some(menu => {
                item = _.find(menu.items, ['key', key]);
                return !!item;
            });

            return this.q.when(item);
        }

        #compileVars($body, key) {
            return this.#findItem(key)
                .then(item => {
                    if (!item) return false;

                    const value = item.value() || "-";

                    $body.find(`mn-var[key='${key}'][dirty=true][refresh=true]`)
                        .not(".custom")
                        .each((tagIndex, tag) => {
                            $(tag).html(value);
                        });
                    $body.find(`mn-var[key='${key}'][dirty=false]`)
                        .not(".custom")
                        .each((tagIndex, tag) => {
                            let content = $(tag).html();
                            $(tag).replaceWith(
                                $("<mn-var />")
                                    .html(content.replace(new RegExp(`%${key}%`, "g"), value))
                                    .attr("key", key)
                                    .attr("title", this.translate.instant(item.title))
                                    .attr("dirty", "true")
                                    .attr("refresh", tag.getAttribute("refresh"))
                                    .addClass("e")
                            );
                        });
                });
        }

        #compileSingleCustomVar($body, varKey, data) {
            const $tags = $body.find(`mn-var.custom[key='${varKey}'][dirty=false]`);

            $tags.each((index, tag) => {
                let meta = JSON.parse($(tag).attr("meta"));
                let value = (data[meta.slug] === 0 || !!data[meta.slug]) ? data[meta.slug] : ""

                if (meta.type === "longtext" || meta.type === "list_longtext")
                    value = value.replace(/(?:\r\n|\r|\n)/g, '<br />');
                if (meta.type === "datetime")
                    value = `${value.date} ${value.time}`;

                let content = $(tag).html();
                $(tag).replaceWith(
                    $("<mn-var />")
                        .html(content.replace(new RegExp(`%${varKey}%`, "g"), value))
                        .addClass("e")
                        .attr("key", varKey)
                        .attr("title", meta.label)
                        .attr("dirty", "true")
                );
            });
        }

        #compileContent(event = null) {
            this.tinymceEditor.undoManager.add(null, event);
            return this.#compile(this.tinymceEditor.getContent() ? this.tinymceEditor.getContent() : "")
                .then((html) => {
                    this.tinymceEditor.setContent(html);
                    this.tinymceEditor.fire("keyup");
                    this.tinymceEditor.undoManager.add(null, event);
                }, _.noop);
        }

        #getValue(item) {
            try {
                if (_.isFunction(item.value)) {
                    return item.value();
                } else {
                    return this.#getKey(item);
                }
            } catch (e) {
                return false;
            }
        }

        #getKey(item) {
            if (this.tinymceEditor) return getKey(item, this.tinymceEditor);
            else return "";
        }

        #caretToTheEnd() {
            if (this.tinymceEditor.selection.getNode().nodeName === "BODY") {
                const lastIndex = this.tinymceEditor.getBody().childNodes.length - 1;
                const lastNode = this.tinymceEditor.getBody().childNodes[lastIndex];
                this.tinymceEditor.selection.select(lastNode);
                this.tinymceEditor.selection.collapse(false);
            }
        }

        #iterMenuItems(items) {
            return _.map(items, item =>
                ({
                    type: 'menuitem',
                    text: this.translate.instant(item.title),
                    onAction: event => {
                        const content = this.tinymceEditor["auto_compile"] ? this.#getValue(item) : this.#getKey(item);
                        const title = !!item.noTranslate ? item.title : this.translate.instant(item.title);
                        this.#caretToTheEnd();

                        this.tinymceEditor.execCommand("mceInsertContent", false, `<mn-var key="${item.key}" refresh="${!!item.refresh}" title="${title}">${content}</mn-var>&nbsp;`);
                        this.tinymceEditor.fire("keyup");
                    }
                })
            );
        }

        #generateMenu(menu, inserted = false) {
            this.addMenuButton(menu.title + "_menu", {
                text: this.translate.instant(menu.title),
                tooltip: this.translate.instant(menu.title),
                inserted: inserted,
                fetch: callback => {
                    if (!!menu.items) {
                        callback(this.#iterMenuItems(menu.items));
                    } else if (!!menu.fetch) {
                        menu.fetch().then(items => {
                            menu.items = items;
                            callback(this.#iterMenuItems(menu.items));
                        }, () => callback([]))
                    }
                }
            });

            return `${menu.title}_menu`;
        }

        #addMenus(_menus) {
            this.menus = _.concat(this.menus, _menus);
            _.forEach(_menus, menu => {
                let menuName = this.#generateMenu(menu, true);

                let button = this.tinymceEditor.buttons[menuName];
                let bg = this.tinymceEditor.theme.panel.find('toolbar buttongroup')[0];
                bg._lastRepaintRect = bg._layoutRect;
                bg.append(button);
            });
        }
    }

    module.exports = DataInjector;
})();