/**
 * Created by Hp on 05/07/2017.
 */
/**
 * Created by Hp on 09/03/2017.
 */

(function () {

    'use strict';

    module.exports = warehouseService;

    warehouseService.$inject = ["mnWebSocket", "$http", "$q"];

    function warehouseService(mnWebSocket, $http, $q) {


        var self = this;

        self.saveWarehouse = saveWarehouse;
        self.deleteWarehouse = deleteWarehouse;
        self.getWarehouse = getWarehouse;

        self.checkWarehouseExistence = checkWarehouseExistence;
        self.checkWarehouseDependencies = checkWarehouseDependencies;
        self.getDefault = getDefault;


        function saveWarehouse(warehouse) {
            var deferred = $q.defer();

            var url = "/api/warehouse/";

            if (!_.isNil(warehouse.id)) url += warehouse.id + "/";

            $http.post(url, warehouse)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteWarehouse(warehouse_id) {
            var deferred = $q.defer();
            var url = "/api/warehouse/" + warehouse_id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getWarehouse(warehouse_id) {
            var deferred = $q.defer();
            var url = "/api/warehouse/" + warehouse_id + "/";
            $http.get(url)
                .then(success, error);
            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function checkWarehouseExistence(name, warehouse_id) {
            return mnWebSocket.call('stock.Warehouse.check_existence', {
                name: name,
                warehouse_id: warehouse_id || null
            });
        }

        function checkWarehouseDependencies(warehouse_id) {
            return mnWebSocket.call('stock.Warehouse.check_warehouse_dependencies', {warehouse_id: warehouse_id});
        }

        function getDefault() {
            return mnWebSocket.call('stock.Warehouse.get_default');
        }


    }
})();

