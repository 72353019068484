/**
 * Created by Hp on 28/02/2017.
 */
(function () {

    'use strict';

    module.exports = stockService;

    stockService.$inject = ["$http", "$q", "mnWebSocket", "lotService"];

    function stockService($http, $q, mnWebSocket, lotService) {
        let self = this;

        self.isAllValid = isAllValid;
        self.validateMovement = validateMovement;
        self.invalidateMovement = invalidateMovement;

        self.saveStockDocument = saveStockDocument;
        self.getStockDocument = getStockDocument;
        self.deleteStockObject = deleteStockObject;
        self.getNonTakenNumbers = getNonTakenNumbers;

        self.isMovementBlock = isMovementBlock;
        self.simpleInvalidate = simpleInvalidate;

        self.updateStockDocument = updateStockDocument;
        self.saveDocumentComment = saveDocumentComment;
        self.getDocumentComments = getDocumentComments;
        self.deleteDocumentComment = deleteDocumentComment;
        self.privatizeDocumentComments = privatizeDocumentComments;
        self.unprivatizeDocumentComments = unprivatizeDocumentComments;

        self.saveDelivery = saveDelivery;
        self.deleteDelivery = deleteDelivery;
        self.deleteBusinessDetail = deleteBusinessDetail;


        function getUrl(_model) {
            return `/api/${_model}/`;
        }

        function saveStockDocument(_document, _model, mnModel) {
            let deferred = $q.defer();
            let url = `${getUrl(_model)}${!_.isNil(_document.id) ? _document.id + "/" : ""}`;
            _document = _.assign(_document, {ignore: true});
            $http.post(url, _document)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getStockDocument(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function updateStockDocument(object_id, _model, content) {

            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";

            $http.put(url, content)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function simpleInvalidate(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.put(url, {is_valid: false})
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteStockObject(object_id, _model) {
            let deferred = $q.defer();
            let url = getUrl(_model) + object_id + "/";
            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function isAllValid(movement, movement_type, documentCls) {
            // let valid = true;
            // let non_complete_serials = _.filter(movement.lines, function (line) {
            //     return !line.is_comment && line.article['has_serial_number'] && _.size(line.serial_numbers) != line.qte  ;
            // });
            // let non_complete_lots = _.filter(movement.lines, function (line) {
            //     if (!line.is_comment && line.article['has_lot_number']) {
            //         switch (movement_type) {
            //             case 'IN':
            //             case 'DISASSEMBLY_LINES':
            //                 return _.sumBy(line.lot_numbers, function (lot) {
            //                     return lotService.getLotQteByStore(lot, line);
            //                 }) != line.qte;
            //                 break;
            //             case 'BR':
            //                 let sum_received_qte = _.sumBy(line.lot_numbers, function (o) {
            //                     return _.sumBy(lotService.currentLotStore(o, line)['received_quantities'], function (item) {
            //                         if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
            //                     })
            //                 });
            //                 return line.src_doc_cls == 'GoodDeposition' ? sum_received_qte != line.qte : _.sumBy(line.lot_numbers, function (lot) {
            //                     return lotService.getLotQteByStore(lot, line);
            //                 }) != line.qte;
            //             case 'OUT':
            //             case 'ASSEMBLY_LINES':
            //                 let sum_sold_qte = _.sumBy(line.lot_numbers, function (o) {
            //                     return _.sumBy(lotService.currentLotStore(o, line)['sold_quantities'], function (item) {
            //                         if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
            //                     })
            //                 });
            //                 return sum_sold_qte != line.qte || _.filter(line.lot_numbers, function (item) {
            //                     return lotService.currentLotStore(item, line).warehouse.id != line.warehouse.id;
            //                 }).length > 0;
            //                 break;
            //             case 'RETURN':
            //                 let sum_returned_qte = _.sumBy(line.lot_numbers, function (o) {
            //                     return _.sumBy(lotService.currentLotStore(o, line)['returned_quantities'], function (item) {
            //                         if (item.mvt_code == movement.number && item.mvt_cls == documentCls && !item['is_deleted']) return item.qte;
            //                     })
            //                 });
            //                 return sum_returned_qte != line.qte || _.filter(line.lot_numbers, function (item) {
            //                     return lotService.currentLotStore(item, line).warehouse.id != line.warehouse.id;
            //                 }).length > 0;
            //                 break;
            //
            //             case 'WarehouseExchange':
            //                 return _.sumBy(line.lot_numbers, function (lot) {
            //                     return lot['transfer_qte'];
            //                 }) != line.qte;
            //                 ;
            //                 break;
            //         }
            //     }
            // });
            // if (!_.isEmpty(non_complete_serials) || !_.isEmpty(non_complete_lots)) valid = false;

            return !(_.get(movement, 'lines', []).length == 0
                || (_.chain(movement).get('lines', []).find((e) => {
                    return _.get(e, 'article.has_serial_number') && _.size(e.serial_numbers) != _.get(e, 'qte')
                }).value())
                || (_.chain(movement).get('lines', []).find((e) => {
                    return _.get(e, 'article.has_lot_number') && _.sumBy(e.lot_numbers, 'store_qte') != _.get(e, 'qte')
                }).value())
                || (_.includes(['ASSEMBLY', 'DISASSEMBLY'], movement_type) &&
                    ((_.get(movement.article, 'has_serial_number') && _.size(movement.serial_numbers) != _.get(movement, 'qte', 0))
                        ||
                        (_.get(movement.article, 'has_lot_number') && _.sumBy(movement.lot_numbers, 'store_qte') != _.get(movement, 'qte', 0))
                    )));

        }

        function validateMovement(_pk, _model_name, _type) {
            return mnWebSocket.call(`stock.${_model_name}.validate`, {_pk, _type});
        }

        function invalidateMovement(_pk, _model_name, _type) {
            return mnWebSocket.call(`stock.${_model_name}.invalidate`, {_pk, _type});
        }

        function isMovementBlock() {
            return mnWebSocket.call('stock.Inventory.is_inventory_started');
        }

        function getNonTakenNumbers(mnModel, pkg, SeqField, model_name) {
            return mnWebSocket.call('shared.Sequence.get_deleted_seqs', {
                mnModel: mnModel,
                pkg: pkg,
                SeqField,
                model_name
            });
        }

        function privatizeDocumentComments(pk) {
            return mnWebSocket.call("stock.DocumentComments.privatize", {pk});
        }

        function unprivatizeDocumentComments(pk) {
            return mnWebSocket.call("stock.DocumentComments.unprivatize", {pk});
        }

        function getDocumentComments(cls, pk) {
            return mnWebSocket.call("stock.DocumentComments.get_comments", {cls, pk});
        }

        function saveDocumentComment(c) {
            var deferred = $q.defer();
            var url = `/api/document-comments/${c.id ? c.id + "/" : ""}`;
            $http.post(url, c)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteDocumentComment(pk) {
            var deferred = $q.defer();
            var url = `/api/document-comments/${pk}/`;
            $http.delete(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function saveDelivery(_document, _model = null) {
            let deferred = $q.defer();
            if (!_.isNil(_document.id)) {
                $http.post(`/api/delivery-form/${_document.id}/full-update/`, _document)
                    .then(successCallback, errorCallback);
            } else $http.post(`/api/delivery-form/full-create/`, _document)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function deleteDelivery(_document, _model = null,) {
            let deferred = $q.defer();
            if (!_.isNil(_document.id)) {
                $http.delete(`/api/delivery-form/${_document.id}/full-delete/`)
                    .then(successCallback, errorCallback);
            }

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


        function deleteBusinessDetail(pk) {
            let deferred = $q.defer();

            $http.delete(`/api/business-detail/${pk}/`)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


    }
})();
