(function () {

    'use strict';

    module.exports = TariffCtrl;

    TariffCtrl.$inject = ["$q", "$translate", "listService", "tariffService", "$mdDialog", "$scope"];

    function TariffCtrl($q, $translate, listService, tariffService, $mdDialog, $scope) {
        var vm = this;
        vm.$onInit = init;
        vm.onPaginate = onPaginate;
        vm.viewList = viewList;
        vm.submit = submit;
        vm.addTariff = addTariff;
        vm.selectArticle = selectArticle;
        vm.showLineDetails = showLineDetails;
        vm.checkNameUniqueness = checkNameUniqueness;
        vm.removeLine = removeLine;
        // vm.addLineTariff = addLineTariff;
        vm.addBaseTariff = addBaseTariff;
        vm.removePricing = removePricing;


        function init() {
            vm.selectedTariff = -1;
            vm.selectedLine = -1;
            vm.query = {
                limit: 10,
                page: 1,
                order: "-id"
            };
            vm.options = [5, 10, 20, 50];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };

            vm.tariffTypes = ['amount', 'discount', 'formula'];
            vm.relationTypes = [
                {value: 'PARENT', label: "patient_relations_parent", index: 0},
                {value: 'SPOUSE', label: "patient_relations_spouse", index: 1},
                {value: 'CHILD', label: "patient_relations_child", index: 2},
                {value: 'SIBLING', label: "patient_relations_sibling", index: 3},
                {value: 'NURSE', label: "patient_relations_nurse",index:4}
            ];
            vm.tariffRules = [
                {name: 'age', type: 'number'},
                {name: 'qte', type: 'number'},
                {name: 'children', type: 'number'},
                { name: 'relations_spouse',type: 'select', values:[{value:0},{value:1},{value:2},{value:3},{value:4}], label:"relations_spouse"},
                { name: 'relation',type: 'select', values:vm.relationTypes, label:"patient_relations_types"}
                ];

            loadTariffs();

        }
        function loadTariffs(){
            vm.promise =tariffService.getTariffs().then(success);
            initTariff();
            function success(data) {
                vm.tariffs = data;
            } }

        function initTariff() {
            vm.pricing = {lines: [], base_tariff: {type: "amount", value: 0.0}, tariffs: []};
        }


        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            if (vm.pricing.lines.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function viewList(item) {
            vm.pricing = item;
            $scope.tariffForm['name'].$setValidity('unique', true);

        }

        function submit() {
            vm.promise = tariffService.saveTariff(vm.pricing).then((data) => {
                if (!_.has(vm.pricing, 'id')) {
                    vm.tariffs.push(data)
                }
                vm.pricing = data;
            });
        }

        function addTariff() {
            initTariff();

        }

        function selectArticle(ev) {

            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack);

            function selectArticleCallBack(data) {
                _.forEach(_.reject(data, (a) => {
                        return !_.isNil(_.find(vm.pricing.lines, (t) => {
                            return t.base_object.code == a.code
                        }))
                    }),
                    (a) => {
                        vm.pricing.lines.push(
                            {
                                base_object: _.assign(a, {_module: "stock.models", _model: "Article"}),

                                base_tariff: {type: "amount", value: 0.0},
                                // rule: {name: 'qte', type: 'number'},
                                tariffs: []
                            }
                        )
                    })
            }

        }


        function showLineDetails(line) {
            // if (vm.selectedLine == line) {
            //     vm.selectedLine = -1;
            // } else {
            //     vm.selectedLine = line;
            // }

            $mdDialog.show(_.assign(require('stock/dialogs/tariff-details-dialog'), {

                locals: {
                    tariffTypes:vm.tariffTypes,
                    tariffRules:vm.tariffRules,
                    line:line
                }
            })).then(function handleDetails(data) {
                line=data;
            });
        }

        // function addLineTariff(line) {
        //     if (vm.selectedLine != line) {
        //         showLineDetails(line)
        //     }
        //
        //     line.tariffs.push({
        //         rule: vm.tariffRules[0],
        //         type: 'amount',
        //         details: []
        //     })
        // }

        function checkNameUniqueness(name) {
            tariffService.checkNameUniqueness(name, vm.pricing.id).then(done);

            function done(data) {
                $scope.tariffForm['name'].$setValidity('unique', data);
            }
        }


        function removeLine(index) {
            vm.pricing.lines.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function addBaseTariff(ev) {

            vm.pricing.tariffs.push({
                rule: vm.tariffRules[0],
                type: 'amount',
                details: []
            })
        }

        function removePricing() {
            tariffService.deleteTariff(vm.pricing.id).then(loadTariffs);
        }
    }

})();