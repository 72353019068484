/**
 * edit by amine on 19/07/2021.
 */
require('tinymce/themes/silver');
require('tinymce/plugins/imagetools');
require('tinymce/plugins/image');
require('tinymce/plugins/fullscreen');
require('tinymce/plugins/table');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/paste');
require('tinymce/plugins/pagebreak');
require('tinymce/plugins/insertdatetime');
require('tinymce/plugins/autolink');
require('tinymce/plugins/charmap');
require('tinymce/plugins/code');
require('tinymce/plugins/directionality');
require('tinymce/plugins/save');
require('tinymce/plugins/link');
require('tinymce/plugins/textpattern');
require('tinymce/plugins/noneditable');
require('tinymce/plugins/nonbreaking');
require('tinymce/plugins/hr');
require('tinymce/plugins/visualchars');
require('tinymce/plugins/noneditable');
require('tinymce/plugins/lists');
require('tinymce/plugins/advlist');
require('tinymce/plugins/preview');
require('tinymce/plugins/print');
require('tinymce/icons/default/icons');

require('tinymce-i18n/langs/fr_FR');