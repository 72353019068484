/**
 * Created by amine on 26/07/2017.
 */
(function () {
    'use strict';

    module.exports = {
        mnPatientSummery: require('./components/patient-summary'),
        mnVisitSummary: require('./components/visit-summary'),
        mnConsultationsListSummary: require('./components/consultations-list-summary'),
        mnConsultationSummary: require('./components/consultation-summary'),
        mnPrescriptionsListSummary: require('./components/prescriptions-list-summary'),
        mnCertificatesListSummary: require('./components/certificates-list-summary'),
        mnExamsListSummary: require('./components/exams-list-summary'),
        mnPatientSummaryLink: require('./directives/patient-summary-link'),
    };

})()