(function () {

    'use strict';

    class AdvancedFilterDialogCtrl {
        constructor($mdDialog, patientService, configService) {
            this.patientService = patientService;
            this.configService = configService;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog", "patientService", "configService"];
        }

        get customFields() {
            return this.patientService.customFields;
        }

        $onInit() {
            this.model = _.clone(this.model || {});

            this.configService.getByHttp('technical_file_fields')
                .then(data => {
                    this.technicalFileFields = data;
                })
        }

        submit() {
            this.dialog.hide({
                model:this.model,
                query: {
                    custom_fields: this.#formatData(_.get(this.model, "custom_fields", {})),
                    technical_file: this.#formatData(_.get(this.model, "technical_file", {}))
                }
            });
        }

        #formatData(data) {
            return _.reduce(data, (acc, item, key) => {
                if (!item) return acc;
                
                if (_.has(item, "id")) acc[key] = _.pick(item, "id");
                else if (_.isString(item)) acc[key] = {$regex: `.*${item}.*`, $options: "i"}

                return acc;
            }, {});
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: AdvancedFilterDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("./advenced-filter-dialog.tpl.html"),
    };

})();
