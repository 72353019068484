/**
 * Created by Hp on 08/06/2017.
 */
(function () {

    'use strict';

    module.exports = blockMovement;

    blockMovement.$inject = ["stockService", "mnWebSocket"];

    function blockMovement(stockService, mnWebSocket) {
        var directive = {
            restrict: 'A',
            priority: 0,
            scope: true,
            link: linkFunc,
        };

        function linkFunc(scope, element, attrs) {

            scope.promise = allowMovement();

            mnWebSocket.sub("stock.Inventory.is_inventory_started", '', allowMovement);
            scope.$on('$destroy', unSubscribe);

            function unSubscribe() {
                mnWebSocket.unsub("stock.Inventory.is_inventory_started", '');
            }

            function allowMovement() {
                scope.promise = stockService.isMovementBlock().then(function (data) {
                    scope.is_locked = data;
                });
            }
        };

        return directive;
    };

})()