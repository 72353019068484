/**
 * Created by Hp on 16/05/2017.
 */
(function () {

    'use strict';

    var linesHeader = {
        controller: LinesHeaderCtrl,
        controllerAs: "vm",
        bindings: {
            stockDocument: "=ngModel",
            documentType: "=documentType",
            readOnly: "=readOnly"
        },
        template: require('stock/views/document-lines-header.tpl.html'),
    };

    LinesHeaderCtrl.$inject = ["$mdDialog", "$attrs", "purchaseService", "serialService", "lotService", "$element", "warehouseService", "saleService", "articleService"];

    function LinesHeaderCtrl($mdDialog, attrs, purchaseService, serialService, lotService, $element, warehouseService, saleService, articleService) {

        var vm = this;
        vm.$onInit = init;
        vm.selectArticle = selectArticle;
        vm.handleBarCodeScan = handleBarCodeScan;
        vm.addEmptyLine = addEmptyLine;
        vm.selectCommand = selectCommand;
        vm.selectDeposition = selectDeposition;
        vm.selectReceipt = selectReceipt;
        vm.selectPriceRequest = selectPriceRequest;
        vm.selectInvoice = selectInvoice;
        vm.selectReturn = selectReturn;
        vm.selectConsignment = selectConsignment;
        vm.addCharge = addCharge;

        var mnModel = !_.isUndefined(attrs['mnModel']) ? attrs['mnModel'] : "";
        var defaultWarehouse = null;

        function init() {
            $element.addClass('layout-row');
            vm.promise = warehouseService.getDefault().then(function (data) {
                defaultWarehouse = data;
            });
        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: vm.documentType === 'OUT' ? {
                        interdict_sale: {$ne: true},
                        is_deleted: {$ne: true}
                    } : _.assign({interdict_purchase: {$ne: true}, is_deleted: {$ne: true}})
                }
                // removed in the  case of first inv
                // , mnModel === 'inventory' && vm.stockDocument.warehouse  ? {'article_store.warehouse':{'$eq':vm.stockDocument.warehouse.id}}:{}
            })).then(selectArticleCallBack);
        }

        function selectArticleCallBack(data) {
            switch (vm.documentType) {
                case 'OUT':
                    _.remove(data, {store_qte: 0});
                    break;
                // not allow select of article with null qte in the source store
                case 'WarehouseExchange':
                    _.remove(data, function (item) {
                        return _.isEmpty(_.filter(item['theo_stores'], function (store) {
                            return store.warehouse == vm.stockDocument.warehouse.id && store['store_qte'] > 0;
                        }))
                    });
                    break;
            }
            _.forEach(data, function (article) {
                refreshDocumentLines(article, "select");
            })
        }

        function refreshDocumentLines(_object, doc_source) {
            let article_idx = _.findIndex(vm.stockDocument.lines, function (line) {
                return line.code == _object.code || (!line.is_comment && line.article.id == _object.id);
            });
            if (article_idx == -1) {
                let new_line = {};
                if (mnModel == 'inventory') {
                    new_line = refreshInventoryLines(_object, doc_source);
                } else {
                    if (_object['store_qte'] <= 0 && vm.documentType == 'OUT') new_line = null;
                    else {
                        new_line = {
                            ...{
                                code: _object.code,
                                article: _.cloneDeep(_object),
                                qte: 1,
                                unit_price: vm.documentType == 'OUT' ? _object.sale_price : (_object.pmp || _object.purchase_price),
                                short_desc: _object.short_desc,
                                warehouse: vm.stockDocument.warehouse || defaultWarehouse,
                                tva: vm.documentType == 'OUT' ? _object.sale_tva : _object.purchase_tva,
                                is_comment: false,
                            }, ...(vm.documentType == 'WarehouseExchange' ? {destination: vm.stockDocument.destination} : {})
                        };
                    }
                }
                if (!_.isNil(new_line)) vm.stockDocument.lines.push(new_line);
            } else {
                vm.stockDocument.lines[article_idx].qte += 1;
            }
        }

        function refreshInventoryLines(_object, doc_source) {
            var new_line = {
                code: _object.code,
                article: _.cloneDeep(_object),
                short_desc: _object.short_desc,
                warehouse: _.get(vm.stockDocument, 'warehouse'),
                qte: 0,
                pmp_price: 0,
                is_comment: false,
                serial_numbers: [],
                lot_numbers: []
            };
            return new_line;
        }

        function addEmptyLine() {
            vm.stockDocument.lines.push({is_comment: true});
        }

        function selectCommand(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-text",
                    modalTitle: "orders_list",
                    modalModel: "stock.PurchaseForm",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectOrderCallBack);
        }

        function selectDeposition(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-arrow-down",
                    modalTitle: "depositions_list",
                    modalModel: "stock.GoodDeposition",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectDepositionCallBack);
        }

        function selectOrderCallBack(data) {
            vm.stockDocument.lines = _.concat(vm.stockDocument.lines, handleDocuments(data, 'PurchaseForm'));
        }

        function selectDepositionCallBack(data) {
            vm.stockDocument.lines = _.concat(vm.stockDocument.lines, handleDocuments(data, 'GoodDeposition'));
        }

        function refreshFromOrderLines(data) {
            refreshFromDocLines(data, "PurchaseForm", false);
        }

        function refreshFromDepositionLines(data) {
            refreshFromDocLines(data, "GoodDeposition", true);
        }

        function refreshFromDocLines(data, src_doc_cls, is_deposition_line) {
            var doc_number = data[1];
            _.forEach(data[0], function (item) {
                var line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                    return !_.isNil(line.article) && line.code == item.code && line.article.id == item.article.id;
                }));

                if (line_not_exist) {
                    if (item['lot_numbers'].length > 0 && is_deposition_line) item.lot_numbers = [];
                    vm.stockDocument.lines.push(_.assign(item, {
                        src_doc_number: doc_number,
                        src_doc_cls: src_doc_cls
                    }));
                }
            })
        }

        function selectReceipt(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "receipts_list",
                    modalModel: "stock.PurchaseReceipt",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectReceiptCallBack);
        }

        function selectReceiptCallBack(data) {
            _.forEach(data, function (receipt) {
                vm.promise = purchaseService.getReceiptNonInvoicedLines(receipt.number).then(function (data) {
                    refreshFromDocLines(data, "PurchaseReceipt", false);
                });
            });
        }

        function selectPriceRequest(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-file-document",
                    modalTitle: "price_request_list",
                    modalModel: "stock.PriceRequest",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(function (data) {
                vm.stockDocument.lines = _.concat(vm.stockDocument.lines, handleDocuments(data, 'PriceRequest'));

            });
        }

        function selectInvoice(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "invoice_list",
                    modalModel: "stock.PurchaseInvoice",
                    search: {
                        is_deleted: {$ne: true},
                        is_valid: {$ne: false},
                        provider: _.get(vm.stockDocument, 'provider.id')
                    },
                }
            })).then(function (data) {
                _.forEach(data, function (invoice) {
                    refreshFromRequest(invoice, "PurchaseInvoice", false);
                });
            });

            function refreshFromRequest(invoice, src_doc_cls) {
                _.forEach(invoice.lines, function (item) {
                    const line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                        return !_.isNil(line.article) && line.code === item.code && line.article.id === item.article.id;
                    }));

                    if (line_not_exist) {
                        vm.stockDocument.lines.push(_.assign(item, {
                            src_doc_number: invoice.number,
                            src_doc_cls: src_doc_cls
                        }));
                    }
                })
            }
        }

        function selectReturn(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-clipboard-arrow-left",
                    modalTitle: "returns_list",
                    modalModel: "stock.GoodReturn",
                    search: {
                        is_deleted: {$ne: true},
                        is_valid: {$ne: false},
                        provider: _.get(vm.stockDocument, 'provider.id')
                    },
                }
            })).then(function (data) {
                _.forEach(data, function (retrn) {
                    refreshFromRequest(retrn, "GoodReturn", false);
                });
            });

            function refreshFromRequest(retrn, src_doc_cls) {
                _.forEach(retrn.lines, function (item) {
                    const line_not_exist = _.isEmpty(_.filter(vm.stockDocument.lines, function (line) {
                        return !_.isNil(line.article) && line.code === item.code && line.article.id === item.article.id;
                    }));
                    if (line_not_exist) {
                        vm.stockDocument.lines.push(_.assign(item, {
                            src_doc_number: retrn.number,
                            src_doc_cls: src_doc_cls
                        }));
                    }
                })
            }
        }

        function selectConsignment(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-file-export",
                    modalTitle: "consignment_list",
                    modalModel: "stock.Consignment",
                    search: {is_deleted: {$ne: true}, is_valid: {$ne: false}},
                }
            })).then(selectConsignmentCallBack);
        }

        function selectConsignmentCallBack(data) {
            vm.stockDocument.lines = _.concat(vm.stockDocument.lines, handleDocuments(data, 'Consignment'));
        }

        function addCharge(ev) {
            $mdDialog.show(_.assign({}, require('stock/dialogs/charge-dialog'), {
                targetEvent: ev,
                locals: {
                    charges: vm.stockDocument.charges || []
                }
            })).then((data) => {
                if (data) {
                    vm.stockDocument.charges = data;
                }
            })
        }

        function prepareDocumentsLines(document, documentType) {
            if (documentType) {
                let lines = chooseLines(document, documentType);
                return _.map(lines, line => _.assignIn(line, {
                    src_doc_number: document.number,
                    src_doc_cls: documentType,
                    src_doc_id: document.id,
                    lot_numbers: [],
                    serial_numbers: [],
                }));

            }

        }

        function chooseLines(document, documentType) {
            let newLines = _.reject(document.lines, line => {
                return _.find(vm.stockDocument.lines, l => l.code == line.code &&
                    l.uid == line.uid &&
                    l.src_doc_cls == documentType &&
                    l.src_doc_id == document.id &&
                    l.src_doc_number == document.number)
            })
            switch (documentType) {
                case "PriceRequest":
                    return _.chain(newLines).filter(line => {
                        return _.get(line, 'qte') > _.get(line, 'commanded_qte')
                    }).map(line => _.assignIn(line, {qte: _.get(line, 'qte') - _.get(line, 'commanded_qte')})).value();
                case "PurchaseOrder":
                    return _.chain(newLines).filter(line => {
                        return _.get(line, 'qte') > _.get(line, 'receipted_qte')
                    }).map(line => _.assignIn(line, {qte: _.get(line, 'qte') - _.get(line, 'receipted_qte')})).value();
                case "GoodDeposition":
                    return _.chain(newLines).filter(line => {
                        return _.get(line, 'qte', 0) > (_.get(line, 'receipted_qte', 1) + _.get(line, 'returned_qte', 1))
                    }).map(line => _.assignIn(line, {qte: _.get(line, 'qte') - (_.get(line, 'receipted_qte', 1) + _.get(line, 'returned_qte', 1))})).value();

                default:
                    return document.lines;
            }
        }

        function handleDocuments(data, type) {
            return _.reduce(data, (documentLines, document) => {
                return _.concat(documentLines, prepareDocumentsLines(document, type));
            }, []);
        }

        function handleBarCodeScan(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/bar-code-scan-dialog'), {
                targetEvent: ev,
                locals: {}
            })).then(data => {
                if (data) {
                    let codes = _.split(data, '\n');
                    articleService.getArticles(codes).then((articles) => {
                        selectArticleCallBack(_.map(codes, (c) => {
                            return _.find(articles, {code: c})
                        }));
                    });
                }
            });
        }
    }

    module.exports = linesHeader;
})();