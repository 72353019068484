/**
 * Created by amine on 15/11/2021.
 */
(function () {
    'use strict';

    class technicalFileService {
        constructor($q, mnWebSocket, $http) {
            this.q = $q;
            this.ws = mnWebSocket;
            this.http = $http;

            this.baseUrl = "/api/tf/"
        }

        static get $inject() {
            return ["$q", "mnWebSocket", "$http"];
        }

        getFileHistory(patient) {
            return this.ws.call('specifics.technical_file.TechnicalFile.versions', {patient});
        }

        getActiveFile(patient, maxDate) {
            const query = _.assign({patient}, _.isNil(maxDate) ? {} : {max_date: maxDate});
            return this.ws.call('specifics.technical_file.TechnicalFile.active_version', query);
        }

        getFile(version) {
            const deferred = this.q.defer();

            this.http.get(this.getUrl(version), 'file')
                .then(response => {
                        deferred.resolve(response.data);
                    },
                    deferred.reject
                );

            return deferred.promise;
        }

        handleFile(file) {
            const deferred = this.q.defer();

            this.http[file.id ? 'put' : 'post'](this.getUrl(file.id, 'file'), file)
                .then(response => {
                    deferred.resolve(response.data);
                }, deferred.reject);

            return deferred.promise;
        }

        getUrl(modelId, view, prefix = "") {
            return `${this.baseUrl}${view}/${modelId ? `${modelId}/` : ''}${prefix ? `${prefix}/` : ''}`;
        }
    }

    module.exports = technicalFileService;
})();