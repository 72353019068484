/**
 * Created by Hp on 25/08/2017.
 */
(function () {
    'use strict';

    module.exports = TransformationFormCtrl;

    TransformationFormCtrl.$inject = ["patientService", "$transition$", "$scope", "stockService", "system", "$state", "lotService", "$translate", "$mdDialog"];

    function TransformationFormCtrl(patientService, $transition$, $scope, stockService, system, $state, lotService, $translate, $mdDialog) {


        var vm = this;

        var currentTransformation = $transition$.params('to')["transformationId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.invalidateTransformation = invalidateTransformation;
        vm.validateTransformation = validateTransformation;
        vm.save = save;
        vm.isAllValid = isAllValid;

        function init() {
            vm.is_new = !_.isNil(currentTransformation) ? false : true;
            $scope.$watch('vm.transformation.is_valid', readOnlyTransformation);
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.modelName = 'Transformation';

            vm.docType = 'transformation';

            if (!_.isNil(currentTransformation)) vm.promise = stockService.getStockDocument(currentTransformation, 'transformation').then(success);
            else   vm.transformation = {
                type: 'ASSEMBLY',
                doc_date: moment().format(dateFormat),
                lines: [],
                qte: 1,
                unit_price: 1.0
            };
            function success(data) {
                vm.transformation = data;
            }
        }


        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }
            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else  saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.transformation = data;
            if (_.isNil(currentTransformation)) $state.go('app.stock.transformation-form', {transformationId: data.id}, {location: 'replace'});
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }

        function invalidateTransformation() {
            return stockService.invalidateMovement(_.get(vm.transformation, 'id'), 'Transformation', vm.transformation.type);
        }

        function validateTransformation(data) {
            return stockService.validateMovement(_.get(data, 'id'), 'Transformation', data.type) ;
        }

        function readOnlyTransformation() {
            vm.readOnly = (vm.transformation && vm.transformation.is_valid) ? vm.transformation.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.transformation, "transformation", 'Transformation');
        }

        function isAllValid() {
            var valid = true;
            if (vm.transformation && vm.transformation.article ) {
                if (vm.transformation.article['has_serial_number']) {
                    valid = vm.transformation.qte == vm.transformation.serial_numbers.length;
                }
                if (vm.transformation.article['has_lot_number']) {
                    valid = vm.transformation.qte == _.sumBy(vm.transformation.lot_numbers,'store_qte');
                }
                return valid && stockService.isAllValid(vm.transformation, vm.transformation.type + '_LINES', vm.modelName)
            }
            else return valid;
        }

    }
})
();
