(function () {

    'use strict';

    class SerialsLotsStateCtrl {
        constructor($translate, articleService, system, $stateParams, $state) {
            this.$translate = $translate;
            this.articleService = articleService;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$state = $state;
            this.handleArticleChange = this.handleArticleChange.bind(this);
            this.handleChange = this.handleChange.bind(this);
        }

        static get $inject() {
            return ["$translate", "articleService", "system", "$stateParams", "$state"];
        }

        $onInit() {
            this.stateQuery = {
                article: null,
                warehouse: null,
                code: null,
                type: 'SERIAL'
            };
            this.query = {
                limit: 5,
                page: 1,
                order: "-id"
            };
            this.options = [5, 10, 20, 100];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.articleQuery = {has_serial_number: {'$eq': true}};
        }

        handleArticleChange(article) {
            this.stateQuery.article = article;
            this.handleChange();
        }

        handleChange(ev = null) {
            if (ev) {
                this.stateQuery.warehouse = ev;
            }
            if (_.get(this.stateQuery, 'article.id') && _.get(this.stateQuery, 'code')) {
                this.promise = this.articleService.getSerialsLotsMovements({
                    article: _.get(this.stateQuery, 'article.id'),
                    warehouse: _.get(this.stateQuery, 'warehouse.id'),
                    code: _.get(this.stateQuery, 'code'),
                    type: _.get(this.stateQuery, 'type'),
                }).then((data) => {
                    this.historic_lines = data;
                });
            } else {
                this.historic_lines = [];
            }

        }

        handleTypeChange() {
            this.articleQuery = _.get(this.stateQuery, 'type') == 'SERIAL' ? {has_serial_number: {'$eq': true}} : {has_lot_number: {'$eq': true}};
        }

        getInQte() {
            return _.chain(this.historic_lines).filter({type: 'IN'}).sumBy('qte').value();
        }

        getOutQte() {
            return _.chain(this.historic_lines).filter({type: 'OUT'}).sumBy('qte').value();
        }
    }

    module.exports = SerialsLotsStateCtrl;
})();
