/**
 * Created by amine on 20/07/2021.
 */

(function () {
    "use strict";

    class BasePlugin {

        constructor(editorInstance, name) {
            this.tinymceEditor = null;
            this.editorInstance = editorInstance;
            this.name = name;
            this.tinymce = require('tinymce/tinymce');

            this.#addPlugin();
        }

        #addPlugin() {
            this.tinymce.PluginManager.add(this.name, (() => {
                let plugin = this;

                return function (editor) {
                    plugin.tinymceEditor = editor;
                    plugin.initPlugin();

                    return plugin;
                }
            })());
        }

        initPlugin() {
            throw new Error('initPlugin need to be implemented');
        }

        addIcon(name) {
            this.tinymceEditor.ui.registry.addIcon(name, `<i class="mdi mdi-${name}"></i>`)
        }

        addButton(name, options) {
            this.tinymceEditor.ui.registry.addButton(name, options);
        }

        addToggleButton(name, options) {
            this.tinymceEditor.ui.registry.addToggleButton(name, options);
        }

        addMenuButton(name, options) {
            this.tinymceEditor.ui.registry.addMenuButton(name, options);
        }

        getSettings(key, defaultValue = {}) {
            return _.get(this.tinymceEditor, `settings.${key}`, defaultValue);
        }
    }


    module.exports = BasePlugin;
})();

