/**
 * Created by Hp on 28/02/2017.
 */
(function () {

    'use strict';

    module.exports = saleService;

    saleService.$inject = ["$http", "$q", "mnWebSocket"];

    function saleService($http, $q, mnWebSocket) {
        var self = this;

        self.getDeliveryNonInvoicesLines = getDeliveryNonInvoicesLines;
        self.getConsignmentNonReturnedLines = getConsignmentNonReturnedLines;

        function getDeliveryNonInvoicesLines(number) {
            return mnWebSocket.call('stock.DeliveryForm.get_non_invoiced_lines', {delivery_number: number});
        }

        function getConsignmentNonReturnedLines(number) {
            var deferred = $q.defer();
            mnWebSocket.call('stock.Consignment.get_non_returned_lines', {consignment_number: number}).then(success, error);

            function success(data) {
                deferred.resolve(data);
            }

            function error(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

    }
})();