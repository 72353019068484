/**
 * Created by Hp on 10/03/2017.
 */


(function () {
    "use strict";

    let dialog = {
        controller: DialogCtrl,
        controllerAs: "vm",
        template: require("stock/views/inventory-serials-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        focusOnOpen: false,
        multiple: true
    };


    DialogCtrl.$inject = ["$mdDialog", "serialService", "$scope"];

    function DialogCtrl($mdDialog, serialService, $scope) {

        let vm = this;
        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.serialsSum = serialsSum;
        vm.saveSerial = saveSerial;
        vm.removeLine = removeLine;

        vm.getOutOrder = getOutOrder;
        vm.serialsSum = serialsSum;
        vm.overQte = overQte;
        vm.selectItem = selectItem;
        vm.isSelected = isSelected;
        vm.isAllSelected = isAllSelected;
        vm.applyFilter = applyFilter;
        vm.addSerial = addSerial;
        vm.checkExist = checkExist;

        function init() {
            vm.serialsByArticle=[];

            vm.searchKey1=null;
            vm.searchKey2=null;
            vm.filteredSerials1=[];
            vm.filteredSerials2=[];
            vm.newSerial=null;
            vm.promise=serialService.getAvailableSerials(_.get(vm.line,'warehouse.id'),_.get(vm.line,'article.id'),vm.mvt_type,vm.sourceDoc).then(success);

            function success(data) {
                vm.serialsByArticle=data;
            }
        }

        function saveSerial() {
            $mdDialog.hide(vm.serials);
        }

        function removeLine(idx) {
            return  vm.serials.splice(idx, 1);

        }

        function getOutOrder() {
            switch (vm.article.stock_valuation) {
                case 'PMP':
                case 'FEFO':
                case 'FIFO':
                    return 'created_at';
                    break;
                case 'LIFO':
                    return '-created_at';
                    break;
                default :
                    return 'created_at';
            }
        }

        function serialsSum() {
            return _.size(vm.serials);
        }

        function selectItem(serial) {
            if(!vm.overQte()){
                this.serials.push(_.pick(serial, 'code'));
            }


        }

        function isSelected(serial) {
            return _.find(vm.serials,{code:serial.code});
        }

        function isAllSelected() {
            return  _.every(vm.serialsByArticle,(l)=>{
                return _.find(vm.serials,{code:l.code});
            })
        }

        function overQte() {
            return vm.limit <= _.size(vm.serials );
        }

        function applyFilter(k1=true){
            if(k1){
                vm.filteredSerials1 = _.filter(vm.serialsByArticle, item => {
                    return _.isMatch(item['code'], vm.searchKey1);
                });
            }
            if(!k1){
                vm.filteredSerials2 = _.filter(vm.serials, item => {
                    return _.isMatch(item['code'], vm.searchKey2);
                });
            }

        }

        function addSerial() {
            if(!_.isEmpty(vm.newSerial)){
                vm.serials.push({code:vm.newSerial, is_new:true});
                vm.newSerial=null;
                vm.newSerialFocus=true;
            }
        }
        function checkExist() {
            return _.find(vm.serials,(s)=> {return s.code==vm.newSerial});

        }
    }

    module.exports = dialog;
})();