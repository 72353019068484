/**
 * Created by BETALOS on 04/12/2015.
 */
(function () {

    'use strict';

    module.exports = {
        formBlockField: require('./components/form-block-field'),
        interfacingService: require('./services/interfacing-service'),
    }

})();