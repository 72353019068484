/**
 * Created by Hp on 01/07/2017.
 */
/**
 * Created by Hp on 02/03/2017.
 */
(function () {
    'use strict';

    module.exports = PurchaseInvoiceFormCtrl;

    PurchaseInvoiceFormCtrl.$inject = ["purchaseService", "$transition$", "$scope", "stockService", "system", "$state", "$mdToast", "$translate", "$mdDialog", "$q", "configService"];

    function PurchaseInvoiceFormCtrl(purchaseService, $transition$, $scope, stockService, system, $state, $mdToast, $translate, $mdDialog, $q, configService) {

        var vm = this;

        var currentInvoice = $transition$.params('to')["invoiceId"];
        var dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.invalidateInvoice = invalidateInvoice;
        vm.validateInvoice = validateInvoice;
        vm.handleProviderChange = handleProviderChange;
        vm.save = save;

        function init() {
            $scope.$watch('vm.invoice.is_valid', readOnlyInvoice);
            vm.is_new = !_.isNil(currentInvoice) ? false : true;
            vm.docType = 'INVOICE';
            vm.modelName = 'PurchaseInvoice';

            vm.promises = [configService.get(["price_mode"], true)];

            if (!_.isNil(currentInvoice)) vm.promises.push(stockService.getStockDocument(currentInvoice, 'purchase-invoice'));
            else {
                vm.invoice = {
                    doc_date: moment().format(dateFormat),
                    lines: [],
                    type: 'PURCHASE'

                };
            }
            vm.promise = $q.all(vm.promises).then(success);

            function success(data) {
                if (!_.isNil(currentInvoice)) vm.invoice = data[1];
                else vm.invoice.price_mode = data[0];
            }
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = vm.save().then(success, error);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) window.history.back();
                else saveSuccess(data);
            }

            function error(err) {
                vm.prevent_save = false;
            }
        }

        function saveSuccess(data) {
            vm.invoice = data;
            if (_.isNil(currentInvoice)) $state.go('app.stock.invoice-form', {invoiceId: data.id}, {location: 'replace'});
        }

        function validateInvoice() {
            return stockService.updateStockDocument(vm.invoice.id, "purchase-invoice", {is_valid: true});

        }

        function invalidateInvoice() {
            return purchaseService.invalidateInvoice(vm.invoice.id);
        }

        function readOnlyInvoice() {
            vm.readOnly = (vm.invoice && vm.invoice.is_valid) ? vm.invoice.is_valid : false;
        }

        function save() {
            return stockService.saveStockDocument(vm.invoice, "purchase-invoice", 'PurchaseInvoice');
        }

        function handleProviderChange(data) {
            vm.invoice.payment_mode = data.payment_mode;
            vm.invoice.payment_condition = data.payment_condition;
        }

    }
})
();
