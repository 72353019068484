/**
 * Created by Hp on 09/05/2017.
 */

(function () {
    'use strict';

    module.exports = StockCtrl;

    StockCtrl.$inject = ["$state","$transitions","$scope"];

    function StockCtrl($state,$transitions,$scope) {
        var vm = this;
        var parameters = require('../json/menu.json');

        vm.$onInit = init;
        vm.newItem=newItem;

        function init() {
            vm.parameters = parameters;
            // var listener = $transitions.onStart({}, toList);
            // if (_.eq($state.$current['name'], "app.stock")) $state.go("app.stock.article-list", {});
            //
            // function toList(transition) {
            //     var toState = transition.to();
            //     if (!_.eq(toState.name, "app.stock.article-list") && _.eq(toState.name, "app.stock")) {
            //         $state.go("app.stock.article-list", {});
            //     }
            // }
            // $scope.$on("$destroy", onDestroy);
            //
            // function onDestroy() {
            //     listener();
            // }
        }

        function newItem(new_state) {
            $state.go(new_state);
        }
    }
})();
