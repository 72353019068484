/**
 * Created by Hp on 08/03/2017.
 */


(function () {

    'use strict';

    const mnMedicine = {
        controller: MnMedicineController,
        controllerAs: "vm",
        bindings: {
            medicine: "=ngModel",
            mnLabel: "=mnLabel",
            disabled: "=mnDisabled",
            changeMedicineCallback: "<mnChangeCallback"

        },
        template: tpl,
    };

    MnMedicineController.$inject = ["medicineService"];

    function MnMedicineController(medicineService) {
        let vm = this;

        vm.$onInit = init;
        vm.getMedicines = medicineService.getMedicines;
        vm.cancel = cancel;
        vm.changeMedicine = changeMedicine;

        function init() {

        }

        function changeMedicine(item) {
            vm.changeMedicineCallback(item);
        }

        function cancel() {
            vm.medicine = null;
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        let mndisabled = !_.isUndefined(attrs['mnDisabled']) ? ' ng-disabled="vm.disabled" ' : "";

        let label = attrs['mnLabel'];
        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]')) $elem.addClass("flex");

        return [
            '<md-autocomplete  md-selected-item="vm.medicine" md-search-text="vm.filters.searchText"' +
            ' md-items="item in vm.getMedicines(vm.filters)" md-item-text="item.full_commercial_name" md-selected-item-change="vm.changeMedicine(item)"' +
            'md-no-cache="true" md-autoselect="true" md-delay="60" md-floating-label="{{ \'' + label + '\' | translate }}" ' + mndisabled + '> ',
            '<md-item-template> ',
            '<span md-highlight-text="vm.searchText" md-highlight-flags="i">{{ item.full_commercial_name }}</span>',
            '  </md-item-template>',
            '<md-not-found>',
            '<span translate="medicine_not_found"></span>',
            '</md-not-found>',
            '</md-autocomplete>',
            '<div class="mn-option-buttons flex-nogrow layout-row">',
            '<md-button ng-if="vm.medicine" class="md-icon-button"  ng-click="vm.cancel()" aria-label="cancel"  ' + mndisabled + '>',
            '<md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '</div>'


        ].join('');
    }

    module.exports = mnMedicine;

})();
